<!-- {{_recordService.localSubmitters.length}} -->
<!-- {{_recordService.localProviders.length}} -->
<!-- {{_recordService.localInsurances.length}} -->
<!-- {{_recordService.localConfig.length}} -->
{{ subProv }}
{{ subIns }}

<div>
    <div *ngIf="showQparams">
        <app-qparams [engLang]="engLang" [fromDt]="lastUsedParam.fromDt" [toDt]="lastUsedParam.toDt" [provs]="provs"
            [lastUsedProvs]="lastUsedParam.provs" [inses]="inses" [lastUsedInses]="lastUsedParam.inses"
            [ckSubDt]="+lastUsedParam.ckSubDt" [subDt]="lastUsedParam.subDt" [userID]="userID" [engLang]="engLang"
            (closeQparamsDlg)="closeQparamsDlg($event)"></app-qparams>
    </div>

    <div *ngIf="_recordService.showSubmitter">
        <app-submitter [sub]="sub" [prov]="subProv" [ins]="subIns"></app-submitter>
    </div>

    <div class="card">
        <mat-paginator class="mat-elevation-z8" [length]="qClms?.length" [pageSize]="10"
            [pageSizeOptions]="[10, 20, 30]" (page)="onPageChange_q837($event)" #q837paginator>
        </mat-paginator>
        <table mat-table [dataSource]="dataSourceQ837" matSort (matSortChange)="sortea($event)" class="mat-elevation-z8"
            multiTemplateDataRows>

            <ng-container matColumnDef="chk" class="max-width-40">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="p-1 text-center"
                    matTooltip="{{engLang ? 'Mark for transmission' : 'Marque para transmitir'}}"
                    matTooltipPosition="after">
                    &#8730;
                </th>
                <td mat-cell *matCellDef="let clm" class="font11 p-1">
                    <mat-checkbox color="primary" [checked]="+clm.chk === 1" (change)="onChange_chk($event,clm)">
                    </mat-checkbox>
                <td mat-footer-cell *matFooterCellDef class="mouse-pointer font11 p-1 text-center max-width-40"
                    (click)="onClick_showSubmitter()" matTooltip="CH account" matTooltipPosition="right">
                    <div class="material-symbols-outlined">
                        settings
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="open" class="max-width-30">
                <th mat-header-cell *matHeaderCellDef matTooltip="{{engLang ? 'Open record': 'Abrir record'}}"
                    matTooltipPosition="above" class="p-1 text-center">
                    <i class="far fa-folder-open font11"></i>
                </th>
                <td mat-cell *matCellDef="let clm" class="p-1 text-center" (click)="onClick_openRecordCase(clm)">
                    <i class="far fa-folder-open font11"></i>
                </td>
                <td mat-footer-cell *matFooterCellDef>
                </td>
            </ng-container>

            <ng-container matColumnDef="patLst">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="p-1">{{engLang ? 'Last':
                    'Apellidos'}}
                </th>
                <td mat-cell *matCellDef="let clm" class="font11 p-1" [ngClass]="{'errors': clm.sErrs.length}"
                    (click)="onClick_expandErrs(clm)">
                    {{clm.patLst}} </td>
                <td mat-footer-cell *matFooterCellDef class="font11" [ngClass]="{ 'text-danger': +s837errCnt }">
                    {{+s837errCnt ? s837errCnt + ' errors' : ''}}
                </td>
            </ng-container>

            <ng-container matColumnDef="patFst">
                <th mat-header-cell *matHeaderCellDef class="p-1">{{engLang ? 'First': 'Nombre'}}</th>
                <td mat-cell *matCellDef="let clm" class="font11 p-1" [ngClass]="{'errors': clm.sErrs.length}"
                    (click)="onClick_expandErrs(clm)"> {{clm.patFst}}
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="casDt">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="p-1 max-width-75">{{engLang ? 'Posted':
                    'Posteado'}}
                </th>
                <td mat-cell *matCellDef="let clm" class="font11 max-width-75 p-1"
                    [ngClass]="{'errors': clm.sErrs.length}" (click)="onClick_expandErrs(clm)">
                    {{clm.casDt }}
                </td>
                <td mat-footer-cell *matFooterCellDef class="text-center">
                    <span *ngIf="spinner"><img src="app/assets/images/waiting4Response.gif" width="42" height="42"
                            alt="Waiting for response">
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="casContr">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="p-1">{{engLang ? 'Contract':
                    'Contrato'}}</th>
                <td mat-cell *matCellDef="let clm" class="font11 p-1" [ngClass]="{'errors': clm.sErrs.length}"
                    (click)="onClick_expandErrs(clm)">
                    {{clm.casContr}} </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="casNo">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="p-1">{{engLang ? 'Claim': 'Caso'}}</th>
                <td mat-cell *matCellDef="let clm" class="font11 p-1" [ngClass]="{'errors': clm.sErrs.length}"
                    (click)="onClick_expandErrs(clm)">
                    {{clm.casNo}}
                </td>
                <td mat-footer-cell *matFooterCellDef class="text-center"></td>
            </ng-container>

            <ng-container matColumnDef="usual">
                <th mat-header-cell *matHeaderCellDef class="max-width-75 rtalign p-1">Usual</th>
                <td mat-cell *matCellDef="let clm" class="max-width-75 font11 rtalign p-1"
                    [ngClass]="{'errors': clm.sErrs.length}" (click)="onClick_expandErrs(clm)">
                    {{clm.usual | currency:'USD':'':'1.2-2'}}
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="xpect">
                <th mat-header-cell *matHeaderCellDef class="max-width-75 rtalign p-1">{{engLang ? 'Expect': 'Espera'}}
                </th>
                <td mat-cell *matCellDef="let clm" class="max-width-75 font11 rtalign p-1"
                    [ngClass]="{'errors': clm.sErrs.length}" (click)="onClick_expandErrs(clm)">
                    {{clm.xpect | currency:'USD':'':'1.2-2'}}
                </td>
                <td mat-footer-cell *matFooterCellDef class="max-width-75 font11 rtalign p-1">
                    {{tot.xpect | currency:'USD':'$':'1.2-2'}}</td>
            </ng-container>

            <ng-container matColumnDef="prov">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="max-width-130 pl-2 pr-1 py-1">{{engLang ?
                    'Provider':'Proveedor'}}</th>
                <td mat-cell *matCellDef="let clm" class="font11 pl-2 pr-1 py-1"
                    [ngClass]="{'errors': clm.sErrs.length}" (click)="onClick_expandErrs(clm)">
                    <span class="font9">{{'(' + clm.provID + ')'}}</span> {{clm.prov}}
                </td>
                <td mat-footer-cell *matFooterCellDef class="rtalign pl-2 pr-1 py-1 mouse-pointer"
                    matTooltip="{{engLang ? 'Save file': 'Grabar archivo'}}" matTooltipPosition="below"
                    (click)="onClick_transmit837(true)">
                    <div class="d-flex justify-content-end" *ngIf="+_recordService.s837ckedCnt">
                        <div class="font10">
                            {{engLang ? 'Save':'Grabar'}}
                        </div>
                        <div>
                            &nbsp;
                        </div>
                        <div>
                            <img src="app/assets/images/save.png" with="20px" height="20px">
                        </div>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="plan">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="max-width-130 p-1">Plan</th>
                <td mat-cell *matCellDef="let clm" class="font11 p-1" [ngClass]="{'errors': clm.sErrs.length}"
                    (click)="onClick_expandErrs(clm)">
                    <span class="font9">{{'(' + clm.insID + ')'}}</span> {{clm.plan}}
                </td>
                <td mat-footer-cell *matFooterCellDef class="rtalign p-1 mouse-pointer"
                    matTooltip="{{engLang ? 'Send claims': 'Enviar casos'}}" matTooltipPosition="below"
                    (click)="onClick_transmit837(false)">
                    <div class="d-flex justify-content-end" *ngIf="+_recordService.s837ckedCnt">
                        <div class="font10">
                            {{engLang ? 'Transmit':'Transmitir'}}
                        </div>
                        <div>
                            &nbsp;
                        </div>
                        <div class="material-symbols-outlined">
                            send
                        </div>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="ps">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="max-width-30 p-1 text-center"
                    matTooltip="1 = prim, 2 = sec" matTooltipPosition="before">½</th> <!-- &frac12;-->
                <td mat-cell *matCellDef="let clm" class="max-width-30 font11 p-1 text-center"
                    [ngClass]="{'errors': clm.sErrs.length}" (click)="onClick_expandErrs(clm)">
                    {{clm.ps==='P'?'1':'2'}}
                </td>
                <td mat-footer-cell *matFooterCellDef class="max-width-30 rtalign p-1"></td>
            </ng-container>

            <ng-container matColumnDef="stat">
                <th mat-header-cell *matHeaderCellDef class="max-width-75">Status</th>
                <td mat-cell *matCellDef="let clm" class="font11 p-1 max-width-75">
                    <div class="d-flex justify-content-around">
                        <div *ngIf="clm.status && +clm?.status.txOk" matTooltip="{{ clm?.status.txMsg }}"
                            matTooltipPosition="above">
                            <img *ngIf="+clm.status.txOk > 0" src="app/assets/images/ckmark_incircle.png" with="30px"
                                height="30px">
                            <img *ngIf="+clm.status.txOk < 0" src="app/assets/images/xmark_incircle.png" with="30px"
                                height="30px">
                        </div>
                        <div *ngIf="clm.status && +clm?.status.txOk > 0 && +clm?.status.chOk"
                            matTooltip="{{ 'clm?.status.chMsg' }}" matTooltipPosition="above">
                            <img *ngIf="+clm.status.chOk > 0" src="app/assets/images/ckmark_incircle.png" with="30px"
                                height="30px">
                            <img *ngIf="+clm.status.chOk < 0" src="app/assets/images/xmark_incircle.png" with="30px"
                                height="30px">
                        </div>
                        <div *ngIf="clm.status && +clm?.status.txOk > 0 && +clm?.status.chOk > 0 && +clm?.status.planOk"
                            matTooltip="{{ 'clm?.status.planMsg' }}" matTooltipPosition="above">
                            <img *ngIf="+clm.status.planOk > 0" src="app/assets/images/ckmark_incircle.png" with="30px"
                                height="30px">
                            <img *ngIf="+clm.status.planOk < 0" src="app/assets/images/xmark_incircle.png" with="30px"
                                height="30px">
                        </div>
                    </div>
                </td>
                <td mat-footer-cell *matFooterCellDef class="max-width-75 pt-1"></td>
            </ng-container>

            <ng-container matColumnDef="delete" class="max-width-30 p-1">
                <th mat-header-cell *matHeaderCellDef class="font11 rtalign p-1 text-center"
                    matTooltip="{{engLang ? 'Clear all': 'Borrar fila'}}" matTooltipPosition="before">
                    <i class="fa fa-trash" (click)="onClick_delete(null)"></i>
                </th>
                <td mat-cell *matCellDef="let clm" class="font11 rtalign p-1 text-center"
                    matTooltip="{{engLang ? 'Delete claim': 'Eliminar caso'}}" matTooltipPosition="before">
                    <i *ngIf="!clm.stat && !clm.stat.txOk && !clm.stat.chOk && !clm.stat.planOk" class="fa fa-trash"
                        (click)="onClick_delete(clm)"></i>
                </td>
                <td mat-footer-cell *matFooterCellDef class="mouse-pointer rtalign p-1 text-center mouse-pointer"
                    matTooltip="{{engLang ? 'Queue create parameters': 'Parámetros para crear fila'}}"
                    matTooltipPosition="above" (click)="onClick_showQparams()">
                    <div class="d-flex justify-content-end">
                        <div class="font10">
                            {{engLang ? 'Parameters':'Parámetros'}}
                        </div>
                        <div>
                            &nbsp;
                        </div>
                        <div class="material-symbols-outlined">
                            menu
                        </div>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="expandCol">
                <td mat-cell *matCellDef="let clm" colspan="13">
                    <div class="data-bkg" *ngIf="!clm.sErrs.length && clm.expandCol">
                        <pre>{{clm.s837}}</pre>
                    </div>
                    <div *ngIf="clm.sErrs.length && clm.expandCol">
                        <ul>
                            <li *ngFor="let err of clm.errArr; let e = even; let o = odd; let i = index;">
                                {{err}}
                            </li>
                        </ul>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="ch">
                <th mat-header-cell *matHeaderCellDef class="mouse-pointer font11 p-1 text-center max-width-40"
                    (click)="onClick_showSubmitter()" matTooltip="CH account" matTooltipPosition="right">
                    <div class="material-symbols-outlined">
                        settings
                    </div>
                </th>
            </ng-container>

            <ng-container matColumnDef="colSpan7">
                <th mat-header-cell *matHeaderCellDef colspan="7" class="text-center">
                    <span *ngIf="spinner"><img src="app/assets/images/waiting4Response.gif" width="42" height="42"
                            alt="Waiting for response">
                    </span>
                </th>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="totXpect">
                <th mat-header-cell *matHeaderCellDef class="max-width-75 font11 rtalign p-1">
                    {{tot.xpect | currency:'USD':'$':'1.2-2'}}
                </th>
            </ng-container>

            <ng-container matColumnDef="save">
                <th mat-header-cell *matHeaderCellDef class="rtalign pl-2 pr-1 py-1 mouse-pointer"
                    matTooltip="{{engLang ? 'Save file': 'Grabar archivo'}}" matTooltipPosition="below"
                    (click)="onClick_transmit837(true)">
                    <div class="d-flex justify-content-end" *ngIf="+_recordService.s837ckedCnt">
                        <div class="font10">
                            {{engLang ? 'Save':'Grabar'}}
                        </div>
                        <div>
                            &nbsp;
                        </div>
                        <div>
                            <img src="app/assets/images/save.png" with="20px" height="20px">
                        </div>
                    </div>
                </th>
            </ng-container>

            <ng-container matColumnDef="txmit">
                <th mat-header-cell *matHeaderCellDef class="rtalign p-1 mouse-pointer"
                    matTooltip="{{engLang ? 'Send claims': 'Enviar casos'}}" matTooltipPosition="below"
                    (click)="onClick_transmit837(false)">
                    <div class="d-flex justify-content-end" *ngIf="+_recordService.s837ckedCnt">
                        <div class="font10">
                            {{engLang ? 'Transmit':'Transmitir'}}
                        </div>
                        <div>
                            &nbsp;
                        </div>
                        <div class="material-symbols-outlined">
                            send
                        </div>
                    </div>
                </th>
            </ng-container>

            <ng-container matColumnDef="colSpan2">
                <th mat-header-cell *matHeaderCellDef colspan="2">
                </th>
            </ng-container>

            <ng-container matColumnDef="params">
                <td mat-header-cell *matHeaderCellDef class="mouse-pointer rtalign p-1 text-center mouse-pointer"
                    matTooltip="{{engLang ? 'Queue create parameters': 'Parámetros para crear fila'}}"
                    matTooltipPosition="below" (click)="onClick_showQparams()">
                    <div class="d-flex justify-content-end">
                        <div class="font10">
                            {{engLang ? 'Parameters':'Parámetros'}}
                        </div>
                        <div>
                            &nbsp;
                        </div>
                        <div class="material-symbols-outlined">
                            menu
                        </div>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="['ch','colSpan7','totXpect','save','txmit','colSpan2','params']">
            <tr mat-header-row *matHeaderRowDef="displydCols; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displydCols;" class="clmRow"
                [ngStyle]="{'font-style': currCasID === row.casID ? 'italic' : 'normal', 'background-color': deleCasID === row.casID && delePS === row.ps ? '#DAA520' : (currCasID === row.casID ? '#F1E5AC' : null), 'color': currCasID === row.casID ? 'black' : null }">
            </tr>
            <tr mat-row *matRowDef="let row; columns: ['expandCol'];" [class.collapsedRow]="row.expandCol == ''"
                [class.error-bkg]="row.sErrs" [class.data-bkg]="!row.sErrs"></tr>
            <tr mat-footer-row *matFooterRowDef="displydCols"></tr>

        </table>
        <!-- <pre>{{ qClms | json2 }}</pre> -->
    </div>

    <!-- <pre>{{ clms | json2 }}</pre> -->
</div>

<!-- modal record/case .............................................................................. -->
<ng-template #recordCaseModal let-modal>
    <div class="modal-header p-8" style="background-color: #ecead6;">
        <div>
            Record: <strong>{{ patNmModalHd }}</strong> <br />
            {{ engLang ? 'Claim: ': 'Caso: ' }}{{ casNoModalHd }}
        </div>
        <div>
            <button type="button" class="btn btn-sm btn-close" style="margin-top: -30px;"
                (click)="modal.dismiss('recordCaseModal')"></button>
        </div>
    </div>

    <div class="modal-body" #modalBody>
        <app-record [activeTab]="2" [xtPID]="patID" [xtCID]="casID" [modalBody]="modalBody"
            (setNewRecordNoEvent)="setNewRecordNo($event)">
        </app-record>
    </div>

    <div class="modal-footer d-sm-inline-flex justify-content-between p-8" style="background-color: #ecead6;">
        <div>
            {{ engLang ? 'Esc - Close': 'Esc - Cerrar'}}
        </div>
        <div>
            <button type="button" class="btn btn-sm btn-outline-dark" *ngIf="!waiting4Response"
                (click)="modal.close('close_recordCaseModal')">{{ engLang ? 'Close': 'Cerrar' }}</button>
        </div>
    </div>

</ng-template>