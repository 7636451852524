/* src/app/shared/submitter/submitter.dialog.css */
.ucase {
  text-transform: uppercase;
}
.select-width {
  width: 100%;
}
.select-style {
  background-color: red;
}
.label-div {
  padding-top: 16px;
  width: 225px;
  height: 1px;
}
mat-form-field {
  width: 245px;
}
.new {
  font-size: 17px;
  cursor: pointer;
}
.font7 {
  font-size: 7px;
  cursor: pointer;
}
.font8 {
  font-size: 8px;
  cursor: pointer;
}
.font9 {
  font-size: 9px;
  cursor: pointer;
}
.font10 {
  font-size: 10px;
  cursor: pointer;
}
.font12 {
  font-size: 12px;
  cursor: pointer;
}
.font15 {
  font-size: 15px;
  cursor: pointer;
}
/*# sourceMappingURL=submitter.dialog.css.map */
