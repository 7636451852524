<app-toasts></app-toasts>

<!-- MAIN MENU -->
<div class="container-fluid">
    <nav class="navbar navbar-fixed-sm navbar-expand-md navbar-light">
        <!-- style="height: 58px" prevents navbar from jumping/resizing when activating with mouse initially inactive navbar ; moved to CSS with @media() query-->
        <div class="navbar-header d-xs-inline col-xs-1 col-md-1 col-lg-1 mx-0 px-0">

            <!-- Hamburguer Menu for small screens -->
            <button type="button" class="navbar-toggler" data-toggle="collapse" data-target="#navbar">
                <span class="navbar-toggler-icon"></span>
            </button>
        </div>

        <div class="navbar-collapse collapse noselect" id="navbar">
            <ul ngbNav class="col-sm-12">
                <li ngbNavItem class="nav-item col-md-1 mx-1 px-0">
                    <a ngbNavLink class="mx-0 px-0" [routerLink]="['/agenda',stylesCfgJson]"
                        routerLinkActive="activeLink"><span>Agenda</span></a>
                </li>

                <li ngbNavItem class="nav-item col-md-2">
                    <div class="row">
                        <div class="col">
                            <div ngbDropdown class="d-inline-block">
                                <a type="button" class="nav-link dropdown-toggle" i ngbDropdownToggle><span>{{ engLang ?
                                        "Billing": "Facturación"
                                        }}</span></a>
                                <ul ngbDropdownMenu>
                                    <li><a ngbDropdownItem [routerLink]="['/record',stylesCfgJson]"
                                            routerLinkActive="activeLink" style="margin-left: 10px;"><span>{{
                                                engLang ? "Records & Claims (entry / edits)": "Records &
                                                Casos (entrada / éditos / 837)"
                                                }}</span></a></li>
                                    <li><a ngbDropdownItem [routerLink]="['/ck-register',stylesCfgJson]"
                                            routerLinkActive="activeLink" style="margin-left: 10px;"><span>{{
                                                engLang ? "Received Checks & Payments Register": "Registro de Cheques y
                                                Pagos
                                                Recibidos" }}</span></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li>

                <li ngbNavItem class="nav-item col-md-2">
                    <a type="button" [routerLink]="['/reports',stylesCfgJson]" class="nav-link"><span>{{ engLang ?
                            "Reports":
                            "Reportes" }}</span></a>
                </li>

                <li ngbNavItem class="nav-item col-md-2">
                    <div class="row">
                        <div class="col">
                            <div ngbDropdown class="d-inline-block">
                                <a type="button" class="nav-link dropdown-toggle" ngbDropdownToggle><span>{{ engLang ?
                                        "Revsions":
                                        "Revisiones" }}</span></a>
                                <ul ngbDropdownMenu style="width: 300px;">
                                    <li *ngFor="let rev of login.revs; let e = even; let o = odd;" class="text-center"
                                        [ngClass]="{ evenRev : e, oddRev : o }">
                                        <!-- /mb/revisions/{{rev.rev}}/{{rev.rev}}.html -->
                                        <a href="{{urlDom}}{{'/mb/revisions/'}}{{rev.rev}}/{{rev.rev}}.html"
                                            target="_blank">
                                            <span class="px-0" [ngClass]="{ evenRev : e, oddRev : o }">
                                                {{ rev.rev + ' - ' + rev.dt + ' - ' + rev.descr }}
                                            </span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li>

                <li class="nav-item col-sm-1 ml-3 pl-3">
                    <a href="#" class="nav-link pb-0" role="button" aria-haspopup="true" aria-expanded="false"><span>{{
                            engLang ? "Logout": "Salir" }}</span></a>
                </li>

                <li class="nav-item col-xs-1 col-md-2 mr-1 pr-0">
                    <div class="row">
                        <div class="col">
                            <div ngbDropdown class="d-inline-block">
                                <a style="color: rgb(63, 7, 92);" type="button" class="nav-link dropdown-toggle"
                                    ngbDropdownToggle><span>{{engLang ? "Services": "Servicios"}}</span>
                                    <span style="color: rgba(220, 243, 12, 0.966);">
                                        {{ (totalItems ? totalItems: ' ') }} </span>
                                    <i class="fas fa-shopping-cart"></i></a>
                                <ul ngbDropdownMenu style="width: 300px;">
                                    <li *ngFor="let item of serviceItemPrices; let idx = index"
                                        [ngStyle]="setMenuServiceItemsStyle(item, idx, totalItems)"
                                        (click)="buySomething($event);" [attr.data-modulenm]="item.module"
                                        style="margin-left: 5px; margin-right: 5px;">
                                        <a *ngIf="item.mnuShow" style="font-size: 80%;"
                                            [ngStyle]="setMenuServiceItemsStyle(item, idx, totalItems)"
                                            [attr.data-modulenm]="item.module">{{
                                            engLang ?
                                            item.mnuEng: item.mnuSpan }}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li>

                <li class="nav-item col-xs-1 ml-2 pl-1">
                    <a class="nav-link" (click)='toggleLanguage()' style="cursor: pointer;">
                        <span>{{ engLang ? "Español": "English" }}</span>
                    </a>
                </li>
            </ul>
        </div>
    </nav>

</div>

<div *ngIf="app_userValidLogin" class="text-center"
    style="display: flex; flex-direction: column; justify-content: center;">
    <div class="mt-1" style="height: 10px; color: maroon;">{{ _recordService.site.nm }}</div>
    <div class="mt-1" style="height: 10px; font-size: 70%;">S/N: {{ app_sn }}</div>
    <div class="my-1" style="height: 10px; font-size: 60%;"> {{ userLastNmStr }}, {{ userFirstNmStr }} ({{ userIdStr }})
    </div>
</div>

<mb-login [cust]="login.cust" [(userValidLogin)]="app_userValidLogin" [(sn)]="app_sn    "></mb-login>

<!-- ================ PAYMENT FORM ============================================================== -->

<!-- modal payments credited to claims per check ......................................................................................... -->
<ng-template #paymentDlgShowModal let-modal>
    <div class="modal-header" style="background-color: #A2C4C0;">

        <div class="rowBox">
            <div>
                <span *ngIf="!engLang" style="font-size: 95%;">Forma de Pago
                </span>
                <span *ngIf="engLang" style="font-size: 95%;">Payment Form </span>
            </div>

            <span *ngIf="waiting4Response">
                <img src="../app/assets/images/waiting4Response.gif" width="50" height="25" alt="Waiting">
            </span>

            <div class="btn-group">
                <button class="btn btn-primary active" id="credCard" (click)="onCcAchClick($event.target)"
                    [disabled]="ccDisabled">{{engLang ? "Credit Card": "Tarjeta Crédito"}}</button>
                <button class="btn btn-warning" id="achTxfer" (click)="onCcAchClick($event.target)"
                    [disabled]="achDisabled">{{engLang ? "Bank Debit": "Débito Banco"}}</button>
            </div>

            <div>
                <span *ngIf="type == 'cc'">
                    <img src="../app/assets/images/allowedcards.jpg" width="125" height="30" alt="Credit Cards">
                </span>
                <span *ngIf="type == 'cc'">{{engLang ? "Credit Card Charge": "Tarjeta de Crédito"}}</span>

                <span *ngIf="type == 'ach'">
                    <img src="../app/assets/images/ach_check.png" width="55" height="30" alt="Check">
                </span>
                <span *ngIf="type == 'ach'">{{engLang ? "ACH Bank Debit": "Directo de Cuenta de Banco"}}
                </span>
            </div>

            <!-- <div>
                <pre>{{ totalItems }}</pre>
            </div> -->

        </div>


        <button type="button" class="btn btn-sm btn-close" aria-label="Close"
            (click)="modal.dismiss('close_paymentDlgShowModal')"></button>
    </div>

    <div class="modal-body">

        <payment-form [customer]="cust" [serviceItemPrices]="serviceItemPrices" [total]="total"
            [totalItems]="totalItems" [type]="type" [engLang]="engLang" [userValidLogin]="app_userValidLogin">
        </payment-form>

    </div>

    <div class="modal-footer" style="display: flex; justify-content: space-between;">
        <div style="font-size: 70%; flex-basis: 50%;">
            {{ engLang ? 'Esc - Close': 'Esc - Cerrar' }}
        </div>
        <div>
            <button type="button" class="btn btn-outline-dark" style="font-size: 65%;"
                (click)="modal.close('oK_paymentDlgShowModal')">{{ engLang ? " Select more services": "Seleccionar más
                servicios" }}</button>
        </div>
    </div>
</ng-template>

<!-- <pre>cust.sn</pre>
<pre>{{ cust.sn }}</pre> -->