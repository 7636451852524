import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatSortModule } from '@angular/material/sort';
import { Subscription, take } from 'rxjs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { Json2Pipe } from '../json2.pipe';
import { CommonModule } from '@angular/common';
import { IQ837 } from '../interfaces/q837';
import { X12UtilsService } from '../x12-utils.service';
import { WebsocketService } from '../websocket.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ModalService } from '../modal.service';
import { MatPaginator, MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { ILocalProviders } from '../interfaces/localProviders';
import { ILocalInsurances } from '../interfaces/localInsurances';
import { QparamsComponent } from '../qparams/qparams.component';
import moment from 'moment';
import { HelperRtnsComponent } from '../helper-rtns.component';
import { RecordService } from '../../record/record.service';
import { SubmitterComponent } from '../submitter/submitter.component';
import { ILocalSubmitters } from '../interfaces/submitter';
import { AppToastsService } from '../app-toasts/app-toasts.service';
import { ILocalFormConfig } from '../interfaces/localFormConfig';

@Component({
  selector: 'app-q837',
  templateUrl: './q837.component.html',
  styleUrls: ['./q837.component.css'],
  imports: [
    CommonModule,
    MatTableModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatPaginatorModule,
    MatSortModule,
    QparamsComponent,
    SubmitterComponent,
    Json2Pipe
  ]
})
export class Q837Component {
  @Input() engLang: boolean;
  @Input() userID: string;
  @Input() currCasID: string; // To distinguish current case on screen if applicable
  @Input() changeQ837: boolean;  // A boolean just to trigger ngOnChanges(...) as needed
  @Input() localProviders: ILocalProviders[];
  @Input() localInsurances: ILocalInsurances[];
  @Output() changeQ837ToggelEvEm: EventEmitter<boolean> = new EventEmitter();
  @Output() setS837cnts: EventEmitter<any> = new EventEmitter();
  @Output() showSubmitterClick: EventEmitter<boolean> = new EventEmitter();
  @Output() openRecordCaseClick: EventEmitter<any> = new EventEmitter();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('q837paginator') q837paginator: MatPaginator;

  sioSubscrpt: Subscription;
  spinner: boolean = false; // Turns on spinner while io is active
  s837errCnt: number = 0;
  s837ErrArr: string[];
  qClms: IQ837[];
  delm: string = '>~>';
  patID: string;
  casID: string;
  pgIndex = 0; // Default
  pgSize = 10; // Default
  dataSourceQ837: MatTableDataSource<IQ837, MatPaginator>;
  displydCols: string[] = [
    'chk',
    'open',
    'patLst',
    'patFst',
    'casDt',
    'casContr',
    'casNo',
    'usual',
    'xpect',
    'prov',
    'plan',
    'ps',
    'stat',
    'delete'
  ];
  tot = {
    'cked': '',
    'usual': '0',
    'xpect': '0',
    'errs': '0'
  }
  subProv: string = '';  // Only used as input to submitter component
  subIns: string = '';
  sub: ILocalSubmitters;
  showQparams: boolean;

  clms: IQ837[] = [];
  lastUsedParam: IQ837LastUsedParams = {
    fromDt: null,
    toDt: null,
    provs: '',
    inses: '',
    ckSubDt: '',
    subDt: null
  }

  provs: IProvs[] = []; // Abridged copy for selects elsewhere
  inses: IInses[] = [];  // Abridged copy for selects elsewhere

  deleCasID: string;
  delePS: string;
  showInitPg: boolean = true; // To show the page where the current case is on initially if it is in this.qClms array
  allowQ837ReadsOnlyWhenZero: number = 0; // Prevents [spMB_Sio_GetQ837] to be called for every [spMB_Sio_Save2Q837] called when creating the Q
  createdQ837cnt: number = 0;  // Number of cases encoded in 837 file after invoking spMB_Sio_CreateQ837

  constructor(
    private _x12Service: X12UtilsService,
    private _websocketService: WebsocketService,
    private _modalService: ModalService,
    private _help: HelperRtnsComponent,
    private _recordService: RecordService,
    private _toastService: AppToastsService
  ) { }

  ngOnInit(): void {
    this.spinner = true;
    this.sioSubscrpt = this._websocketService.getMessages().subscribe((dataSet) => { // Sets listenning events

      console.log('%c' + 'dataSet Q837Component', 'color: green; background: yellow; font-size: 14px');
      console.log(dataSet[0]?.sqlProcNm, dataSet);

      if (dataSet[0]?.sqlProcNm === 'spMB_Sio_GetQ837') {
        this._recordService.localQ837Arr(dataSet);
        this.prepareDataSource();

      } else if (dataSet[0]?.sqlProcNm === 'spMB_Sio_Q837ChkUnchk') {
        const clm = this.qClms.find(c => +c.pKey === +dataSet[0].pKey);
        if (clm) {
          if (+dataSet[0].chk) {
            this._recordService.s837ckedCnt = (+this._recordService.s837ckedCnt + 1).toString();
            clm.chk = '1';
          } else {
            if (+this._recordService.s837ckedCnt) {
              this._recordService.s837ckedCnt = (+this._recordService.s837ckedCnt - 1).toString();
            }
            clm.chk = '0';
          }
        }
        this.spinner = false;

      } else if (dataSet[0]?.sqlProcNm === 'spMB_Sio_DeleteFromQ837') {
        if (+dataSet[0].rowCnt) {
          if (dataSet[0].casID === '-1') {
            if (this.qClms) {
              const newArr = this.qClms.filter(c => { return c.stat });
              this._recordService.localQ837Arr(newArr);
            } else {
              this._recordService.localQ837Arr([]);
            }
          } else {
            if (+dataSet[0].rowCnt === 1) {
              let i = this.qClms.findIndex(ci => +ci.casID === +dataSet[0].casID && +ci.ps === +dataSet[0].ps);
              this.qClms.splice(i, 1);
              this._recordService.localQ837Arr(this.qClms); // Updates totals
              this.spinner = false;
            }
          }
          this.prepareDataSource();
        }

      } else if (dataSet[0]?.sqlProcNm === 'spMB_Sio_CreateQ837') { // This block is just to get the errors in s837 and save them to [Q837]
        let q: string = '';
        dataSet.forEach(d => {
          if (d.clm837) { // Filter out empty dataSet indicator
            d.sErrs = this._x12Service.x837errors(d.clm837, d.provID, d.insID).map(s => s).reduce((acc, s) => acc + s + this.delm, '');  // Add property & any errors

            q += "Exec spMB_Sio_Save2Q837 @sn = '" + this._recordService.sn
              + "', @casID = '" + d.casID
              + "', @casDt = '" + d.casDt
              + "', @patID = '" + d.patID
              + "', @ps = '" + (d.cps === '1' ? 'P' : 'S')
              + "', @uID = '" + this.userID
              // + "', @s837 = '" + d.clm837    // Not needed, I just want to save sErrs
              + "', @sErrs = '" + d.sErrs
              // + "', @provID = '" + d.provID  // Already in table [Q837]
              // + "', @insID = '" + d.insID
              + "';";

            this.createdQ837cnt++;  // Will be used to no when saving errors sErrs is finished
          }
        });

        if (q) {
          console.log('%c' + 'q @ ngOnInit():' + q, 'color: black; background: #90EE90; font-size: 12px');
          this._websocketService.sendChat('query', this._recordService.sn, q);
        }

      } else if (dataSet[0]?.sqlProcNm === 'spMB_Sio_GetQ837LastUsedParams') {
        if (+dataSet[0].pKey) {
          this.lastUsedParam.fromDt = moment(dataSet[0].q837fromDt, 'MM/DD/YYYY').isValid() ? moment(dataSet[0].q837fromDt, 'MM/DD/YYYY').format('YYYY-MM-DD') : null;
          this.lastUsedParam.toDt = moment(dataSet[0].q837toDt, 'MM/DD/YYYY').isValid() ? moment(dataSet[0].q837toDt, 'MM/DD/YYYY').format('YYYY-MM-DD') : null;
          this.lastUsedParam.provs = dataSet[0].q837provs;
          this.lastUsedParam.inses = dataSet[0].q837inses;
          this.lastUsedParam.ckSubDt = (+dataSet[0].q837ckSubDt).toString();
          this.lastUsedParam.subDt = moment(dataSet[0].q837subDt, 'MM/DD/YYYY').isValid() ? moment(dataSet[0].q837subDt, 'MM/DD/YYYY').format('YYYY-MM-DD') : null;

          this.prepareDataSource();
          this.spinner = false;
        }

      } else if (dataSet[0]?.sqlProcNm === 'spMB_Sio_Save2Q837') {
        if (this.allowQ837ReadsOnlyWhenZero === 0) {
          let q = 'Exec spMB_Sio_GetQ837 @sn = "' + this._recordService.sn
            + '", @uID = "' + this.userID
            + '";'
          console.log('%c' + 'q @ ngOnInit():' + q, 'color: black; background: #90EE90; font-size: 12px');
          this._websocketService.sendChat('query', this._recordService.sn, q);

          q = "Exec spMB_Sio_SaveQ837LastUsedParams @sn = '" + this._recordService.sn
            + "', @uID = '" + this.userID
            + "', @q837fromDt = '" + moment(this.lastUsedParam.fromDt, 'YYYY-MM-DD').format('YYYY-MM-DD')
            + "', @q837toDt = '" + moment(this.lastUsedParam.toDt, 'YYYY-MM-DD').format('YYYY-MM-DD')
            + "', @q837provs = '" + (this.lastUsedParam.provs ? this.lastUsedParam.provs : '')
            + "', @q837inses = '" + (this.lastUsedParam.inses ? this.lastUsedParam.inses : '')
            + "', @q837ckSubDt = '" + (+this.lastUsedParam.ckSubDt ? '1' : '0')
            + "" + (moment(this.lastUsedParam.subDt, 'YYYY-MM-DD').isValid() ? ', @q837subDt = ' + moment(this.lastUsedParam.subDt).format('YYYY-MM-DD') : '')
            + "';";
          console.log('%c' + 'q @ ngOnInit():' + q, 'color: black; background: #90EE90; font-size: 12px');
          this._websocketService.sendChat('query', this._recordService.sn, q);
        }

        this.allowQ837ReadsOnlyWhenZero++;  // Blocks subsequent calls to spMB_Sio_Save2Q837 that are just calculating & saving sErrs
        if (this.createdQ837cnt >= this.allowQ837ReadsOnlyWhenZero) {
          this.spinner = false;
        }
      }

      this.changeQ837ToggelEvEm.emit(true);
    });

    const q = 'Exec spMB_Sio_GetQ837LastUsedParams @sn = "' + this._recordService.sn + '", @uID = "' + this.userID + '";';
    console.log('%c' + 'q @ ngOnInit():' + q, 'color: black; background: #90EE90; font-size: 12px');
    this._websocketService.sendChat('query', this._recordService.sn, q);

    this.prepareDataSource()
  }

  ngAfterViewInit() {
    this.dataSourceQ837.sort = this.sort; // Connect the sort to the data source
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.qClms && changes.changeQ837) {
      console.log('changes.qClms', this.qClms);
      this.prepareDataSource()
    }
  }

  ngOnDestroy() {
    this.sioSubscrpt.unsubscribe();
    this.qClms?.forEach(c => c.expandCol = '');
    this.provs = undefined;
    this.inses = undefined;
  }

  prepareDataSource() {
    this.qClms = this._recordService.localQ837.slice();
    if (this.qClms) {
      this.qClms.sort((a, b) => { // Move all errors to the top
        if (a.sErrs && !b.sErrs) {
          return -1; // a comes before b
        } else if (!a.sErrs && b.sErrs) {
          return 1; // b comes before a
        } else {
          return 0; // maintain original order
        }
      });

      // let arr: IQ837[] = this.qClms.slice();
      const regex = new RegExp(this.delm + '$', "g");
      let tusual: number = 0;
      let txpect: number = 0;
      let tcked: number = 0;
      let terrs: number = 0;
      this.qClms.forEach(e => {  // Prepare errors for display & totals
        e['errArr'] = e.sErrs.replace(regex, '').split(this.delm);
        if (e.sErrs) {
          terrs += 1;
        }
        tusual += +e.usual;
        txpect += +e.xpect;
        tcked += +e.chk;
      });
      this.tot.usual = tusual.toFixed(2);
      this.tot.xpect = txpect.toFixed(2);
      this.tot.cked = tcked.toString();
      this.tot.errs = terrs.toString();

      if (this.showInitPg && +this.currCasID >= 0) { // See if the case opened if any is in this.qClms array
        const currIndx = this.qClms.findIndex(i => +i.casID === +this.currCasID);
        if (currIndx > -1) {  // If so always show the page it is on first
          this.pgIndex = Math.floor(currIndx / (this.q837paginator?.pageSize ?? 10));
          this.showInitPg = false; // Otherwise it will keep scrolling to the same claim & stuck to the same page index
        }
      }

      if (this.q837paginator) {
        if (!Number.isInteger(this.pgSize)) {
          this.pgSize = this.q837paginator?.pageSize ?? 10;
        } else {
          this.q837paginator.pageSize = this.pgSize;
        }
        if (!Number.isInteger(this.pgIndex)) {
          this.pgIndex = this.q837paginator?.pageIndex ?? 0;
        } else {
          this.q837paginator.pageIndex = this.pgIndex;
        }
      }

      const indxFrom: number = this.pgIndex * this.pgSize;
      const indxTo: number = indxFrom + this.pgSize;

      this.dataSourceQ837 = new MatTableDataSource(this.qClms.slice(indxFrom, indxTo));
      this.dataSourceQ837.sort = this.sort; // Always follow this.dataSourceQ837 = ... with this to maintain header sort arrow synced
    }
    this.spinner = false;
  }

  onPageChange_q837(event: PageEvent) {
    this.pgIndex = event.pageIndex;
    this.pgSize = event.pageSize;
    this.prepareDataSource();
  }

  sortea(sort) {
    if (!sort.active || sort.direction === '') {  // No active sort or sort disabled, reset your data source & "sort" (see below)
      this.pgIndex = 0;
      this.prepareDataSource();
      return;
    }

    if (sort.direction === 'asc') {

      if (sort.active === 'casDt') {
        this._recordService.localQ837.sort(this.sortBy_casDt_Asc);
      }
      if (sort.active === 'casContr') {
        this._recordService.localQ837.sort(this.sortBy_casContr_Asc);
      }
      if (sort.active === 'patLst') {
        this._recordService.localQ837.sort(this.sortBy_name_Asc);
      }
      if (sort.active === 'prov') {
        this._recordService.localQ837.sort(this.sortBy_prov_Asc);
      }
      if (sort.active === 'plan') {
        this._recordService.localQ837.sort(this.sortBy_plan_Asc);
      }
      if (sort.active === 'ps') {
        this._recordService.localQ837.sort(this.sortBy_ps_Asc);
      }
      if (sort.active === 'chk') {
        this._recordService.localQ837.sort(this.sortBy_chk_Asc);
      }

    } else {

      if (sort.active === 'casDt') {
        this._recordService.localQ837.sort(this.sortBy_casDt_Dsc);
      }
      if (sort.active === 'casContr') {
        this._recordService.localQ837.sort(this.sortBy_casContr_Dsc);
      }
      if (sort.active === 'patLst') {
        this._recordService.localQ837.sort(this.sortBy_name_Dsc);
      }
      if (sort.active === 'prov') {
        this._recordService.localQ837.sort(this.sortBy_prov_Dsc);
      }
      if (sort.active === 'plan') {
        this._recordService.localQ837.sort(this.sortBy_plan_Dsc);
      }
      if (sort.active === 'ps') {
        this._recordService.localQ837.sort(this.sortBy_ps_Dsc);
      }
      if (sort.active === 'chk') {
        this._recordService.localQ837.sort(this.sortBy_chk_Dsc);
      }
    }

    if (this.currCasID) {
      this.showInitPg = true;
    }
    this.prepareDataSource();
  }

  sortBy_name_Asc(a, b): number {
    if (a.patLst + ' ' + a.patFst > b.patLst + ' ' + b.patFst) {
      return 1;
    }
    if (a.patLst + ' ' + a.patFst < b.patLst + ' ' + b.patFst) {
      return -1;
    }
    return 0;
  }

  sortBy_name_Dsc(a, b): number {
    if (a.patLst + ' ' + a.patFst < b.patLst + ' ' + b.patFst) {
      return 1;
    }
    if (a.patLst + ' ' + a.patFst > b.patLst + ' ' + b.patFst) {
      return -1;
    }
    return 0;
  }

  sortBy_casDt_Asc(a, b): number {
    const casDtA = moment(a.casDt, 'MM/DD/YYYY').format('YYYYMMDD');
    const casDtB = moment(b.casDt, 'MM/DD/YYYY').format('YYYYMMDD');
    if (casDtA > casDtB) {
      return 1;
    }
    if (casDtA < casDtB) {
      return -1;
    }
    return 0;
  }

  sortBy_casDt_Dsc(a, b): number {
    const casDtA = moment(a.casDt, 'MM/DD/YYYY').format('YYYYMMDD');
    const casDtB = moment(b.casDt, 'MM/DD/YYYY').format('YYYYMMDD');
    if (casDtA < casDtB) {
      return 1;
    }
    if (casDtA > casDtB) {
      return -1;
    }
    return 0;
  }

  sortBy_prov_Asc(a, b): number {
    if (a.prov + ' ' + a.plan > b.prov + ' ' + b.plan) {
      return 1;
    }
    if (a.prov + ' ' + a.plan < b.prov + ' ' + b.plan) {
      return -1;
    }
    return 0;
  }

  sortBy_prov_Dsc(a, b): number {
    if (a.prov + ' ' + a.plan < b.prov + ' ' + b.plan) {
      return 1;
    }
    if (a.prov + ' ' + a.plan > b.prov + ' ' + b.plan) {
      return -1;
    }
    return 0;
  }

  sortBy_plan_Asc(a, b): number {
    if (a.plan + ' ' + a.prov > b.plan + ' ' + b.prov) {
      return 1;
    }
    if (a.plan + ' ' + a.prov < b.plan + ' ' + b.prov) {
      return -1;
    }
    return 0;
  }

  sortBy_plan_Dsc(a, b): number {
    if (a.plan + ' ' + a.prov < b.plan + ' ' + b.prov) {
      return 1;
    }
    if (a.plan + ' ' + a.prov > b.plan + ' ' + b.prov) {
      return -1;
    }
    return 0;
  }

  sortBy_casContr_Asc(a, b): number {
    if (a.casContr > b.casContr) {
      return 1;
    }
    if (a.casContr < b.casContr) {
      return -1;
    }
    return 0;
  }

  sortBy_casContr_Dsc(a, b): number {
    if (a.casContr < b.casContr) {
      return 1;
    }
    if (a.casContr > b.casContr) {
      return -1;
    }
    return 0;
  }

  sortBy_ps_Asc(a, b): number {
    if (a.ps > b.ps) {
      return 1;
    }
    if (a.ps < b.ps) {
      return -1;
    }
    return 0;
  }

  sortBy_ps_Dsc(a, b): number {
    if (a.ps < b.ps) {
      return 1;
    }
    if (a.ps > b.ps) {
      return -1;
    }
    return 0;
  }

  sortBy_chk_Asc(a, b): number {
    if (a.chk > b.chk) {
      return 1;
    }
    if (a.chk < b.chk) {
      return -1;
    }
    return 0;
  }

  sortBy_chk_Dsc(a, b): number {
    if (a.chk < b.chk) {
      return 1;
    }
    if (a.chk > b.chk) {
      return -1;
    }
    return 0;
  }

  onClick_expandErrs(clm: IQ837) {
    if (clm.sErrs) {
      if (!clm.expandCol) {
        clm.expandCol = 'true';
      } else {
        clm.expandCol = '';
      }
      console.log('clm.s837', clm.s837);
    } else if (clm.s837) {
      if (!clm.expandCol) {
        clm.expandCol = 'true';
      } else {
        clm.expandCol = '';
      }
    }
  }

  onClick_delete(clm) {
    if (clm) {
      this.deleCasID = clm.casID;
      this.delePS = clm.ps;
    }
    const prompt = this.engLang ? (clm ? 'Delete claim from queue ?' : 'Delete entire queue ?') : (clm ? '¿ Eliminar caso de la fila ?' : '¿ Eliminar toda la fila ?');
    const title = this.engLang ? 'Confirm' : 'Confirme';
    const btn_primary = true;
    const btn_default = true;
    let ok: string = this.engLang ? 'Yes' : 'Sí';
    let cancel = 'No';

    this._modalService.confirm(prompt, title, btn_primary, btn_default, ok, cancel)
      .pipe(
        take(1) // take() manages unsubscription for us
      ).subscribe(response => {
        if (response) {
          this.spinner = true;
          const cid = clm ? clm.casID : '-1';
          const ps = clm ? clm.ps : '';
          this._x12Service.delete837(this._recordService.sn, ps, cid, this.userID);
        } else {
          // Nada
        }
        this.deleCasID = undefined;
        this.delePS = undefined;
      });
  }

  onChange_chk(event, clm) {
    if (clm.sErrs === '') {
      this._x12Service.chkUnchk837(this._recordService.sn, clm.pKey);
      this.spinner = true;
    } else {
      event.source.checked = !event.checked;  // Revert chkbox state
    }
  }

  onClick_showSubmitter() {
    this.subProv = '';  // Heading for submitter component
    this.subIns = '';   // Heading for submitter component
    this._recordService.showSubmitter = true;
  }

  onClick_showQparams() { // Get parameters for 837 file creation
    this.provs = this.localProviders.map(p => ({ pKey: p.pKey, alias: p.alias }));
    this.provs.unshift({
      pKey: '0',
      alias: '(All/Todos)'
    });
    this.provs.push({
      pKey: '-1',
      alias: '(Clear)'
    });

    this.inses = this.localInsurances.map(i => ({ pKey: i.pKey, alias: i.alias }));
    const idx = this.inses.findIndex(i => +i.pKey === 0);
    this.inses.splice(idx, 1);
    this.inses.unshift({
      pKey: '0',
      alias: '(All/Todos)'
    });
    this.inses.push({
      pKey: '-1',
      alias: '(Clear)'
    });
    this.showQparams = true;
  }

  open(content, opts: any, modalNm: string) {
    this._modalService.open(content, opts)
      .pipe(
        take(1) // take() manages unsubscription for us
      ).subscribe(response => {
        if (response) {
          console.log(response);
          return true;
        } else {
          return false;
        }
      });
  }

  closeQparamsDlg(event) {
    console.log('event', event);
    if (event) {
      moment(event.form.fromDt).isValid() ? this.lastUsedParam.fromDt = moment(event.form.fromDt).format('YYYY-MM-DD') : '';
      moment(event.form.toDt).isValid() ? this.lastUsedParam.toDt = moment(event.form.toDt).format('YYYY-MM-DD') : '';
      this.lastUsedParam.provs = event.form.provsSelectCtrl.join(',');
      this.lastUsedParam.inses = event.form.insesSelectCtrl.join(',');
      +event.form.ckSubDt ? this.lastUsedParam.ckSubDt = '1' : this.lastUsedParam.ckSubDt = '0';

      if (moment(event.form.toDt).isValid()) {
        this.lastUsedParam.subDt = moment(event.form.subDt).format('YYYY-MM-DD');
      } else {
        this.lastUsedParam.subDt = null;
      }
      this.spinner = true;
      this.allowQ837ReadsOnlyWhenZero = 0;
    }
    this.showQparams = false; // Hides the dialog
  }

  onClick_transmit837(save2disk: boolean) {
    const prompt = save2disk ? (this.engLang ? 'Save 837 file to local system ?' : '¿ Grabar archivo 837 al sistema local ?') : (this.engLang ? 'Transmit to Clearing House(CH) or Insurance ?' : '¿ Transmitir al Clearing House(CH) o Seguro ?');
    const title = this.engLang ? 'Confirm' : 'Confirme';
    const btn_primary = true;
    const btn_default = true;
    let ok: string = this.engLang ? 'Yes' : 'Sí';
    let cancel = 'No';

    this._modalService.confirm(prompt, title, btn_primary, btn_default, ok, cancel)
      .pipe(
        take(1) // take() manages unsubscription for us
      ).subscribe(response => {
        if (response) {
          this.tx837(save2disk);
        } else {
          // Nada
        }
      });
  }

  ckSubmittersBeforTx(): boolean {
    this.subProv = '0';
    this.subIns = '0';
    const uniqueBatches = this._recordService.localQ837.reduce((accumulator, current) => {
      const key = `${current.provID}-${current.insID}`;

      if (!accumulator.set.has(key) && +current.chk) {
        accumulator.set.add(key);
        accumulator.result.push({ provID: current.provID, prov: current.prov, ins: current.plan, insID: current.insID });
      }
      return accumulator;
    }, { set: new Set(), result: [] }).result;

    for (let b = 0; b < uniqueBatches.length; b++) {
      let conf: ILocalFormConfig = this._recordService.localConfig.find(fc => fc.provID === uniqueBatches[b].provID && fc.insID === uniqueBatches[b].insID);
      if (!conf || !+conf.subID) {  // Submitter not linked to batch(provID & insID) in FormConf table
        this.subProv = `${uniqueBatches[b].provID}`; // provID select box in submitter component for linking
        this.subIns = `${uniqueBatches[b].insID}`;  // insID select box in submitter component for linking
        this._recordService.showSubmitter = true;
        return false;
      }
    };
    return true;
  }

  tx837(save2disk: boolean): void {
    if (!this.ckSubmittersBeforTx()) {
      return;
    }

    const isaTestFlag: string = 'P'; // Make sure it is 'P' for Production
    let processed: { casIdPs: string, filNm: string, txStat: string, filNo: string }[] = []; // Holds processed claims
    let rxStat: { message: string, errCnt: string, filNo: string }[] = [];  // To keep track of all statuses received with first file on the bottom & most recent on top - assumes they will com in order of transmitted.
    let rxStatFilCnt: number = 0;
    let provID: string = undefined;
    let prov: string;
    let insID: string = undefined;
    let ins: string;
    let isa13: string;
    let sub: ILocalSubmitters;
    let filCnt: number = 0;
    let casCnt: number = 0;
    let tdtMom: any;
    let tdt: string;
    let filNm: string = '';
    let filNmToasted: string[] = []; // Use to filter c.filNm displayed in toasts and not repeat

    for (let i = 0; i < this._recordService.localQ837.length; i++) {  // Outer loop - looking for a new batch, provID/insID
      let x12Data: string = ''; // File data
      let startBatch: boolean = true; // Start of a new batch. A batch is a particular provID/insID combination
      let hlCnt: number = 2;  // Increment for each claim in batch
      provID = undefined; // New batch when undefined
      insID = undefined;
      this.subProv = '';  // Only used as input to submitter component when needed. Heading for submitter component
      this.subIns = ''; // Heading for submitter component
      tdtMom = moment(new Date);  // Today's date as moment object
      tdt = tdtMom.format('(M-D h:mm:ss)');

      for (let c = 0; c < this._recordService.localQ837.length; c++) {  // Inner loop - looking for claims belonging to batch
        let clm: IQ837 = this._recordService.localQ837[c];  // Claim to process
        if (+clm.chk) { // Do only checked items
          if (!processed.length || !processed.some(e => e.casIdPs === clm.casID + clm.ps)) {  // Initiallly empty or claim never processed before
            if (!provID) {  // New batch to prepare
              provID = clm.provID;  // Set for new current batch
              prov = clm.prov;  // Provider name
              this.subProv = `(${provID}) ${prov}`; // Heading for submitter component
              insID = clm.insID;  // Set for new current batch
              ins = clm.plan;   // Insurance name. Heading for submitter component
              this.subIns = `(${insID}) ${ins}`;
              filNm = '';
              if (isaTestFlag === 'T') {
                filNm = 'TEST-MedicalBiller-test ';
              }
              filNm += '(' + provID + ')' + prov.substring(0, 15) + ' - ' + '(' + insID + ')' + ins.substring(0, 15) + tdt + '.txt';
            }

            if (clm.provID === provID && clm.insID === insID) { // Current batch

              if (startBatch) {
                startBatch = false;  // So as not to repeat ISA,GS,...
                const conf: ILocalFormConfig = this._recordService.localConfig.find(fc => fc.provID === clm.provID && fc.insID === clm.insID);
                if (conf && +conf.subID) {  // If a submitter record has already been assigned to the submitter account...
                  sub = this._recordService.localSubmitters.find(s => s.SubID === conf.subID);  // Find submitter
                  if (sub && sub.SubIDrem && sub.SubIsa07 && sub.SubIsa08 && sub.SubGs02 && sub.SubGs03) {  // Is submitter minimum complete ?
                    isa13 = (tdtMom.year() % 10).toString() + moment(tdtMom).format('DDHHmmss'); // ISA13 - Interchange Control Number - A unique control number for this interchange, which should match the IEA02 control number in the IEA segment (Interchange Control Trailer).
                    x12Data += this._x12Service.isaGsEnvelope(sub, tdtMom, isa13, clm.plan, clm.insPayID, '837P', 'P');
                    const prov: ILocalProviders = this._recordService.localProviders.find(p => p.pKey === provID);

                    // TODO: Ck Errs in NM1*85...
                    x12Data += this._x12Service.billingProvider(prov, this.engLang);

                  } else {  // Prompt for submitter/clearing house account data
                    this.sub = sub;
                    this._recordService.showSubmitter = true;
                    return; // Should not happen
                  }
                } else {  // Batch not linked in submitter table so prompt for submitter/clearing house account data
                  this._recordService.showSubmitter = true;
                  return; // Should not happen
                }
              }
              x12Data += `\nHL*${hlCnt}*1*22*0~`
              hlCnt += 1;
              x12Data += clm.s837;  // Claim data added to file

              processed.push({ casIdPs: clm.casID + clm.ps, filNm: filNm, txStat: clm.status.txMsg, filNo: filCnt.toString() });  // Preserve claim as processed
              casCnt += 1;
              this._recordService.s837ckedCnt = (+this._recordService.s837ckedCnt - 1).toString();  // Decrement total cked claims since the check goes off when processed
            }
          }
        }
      } // Inner loop next claim

      if (x12Data) {  // If there is file data ?

        const segCnt: number = x12Data.match(/~/g).length - 1;
        let qSubDt: string = '';  // Query to set submitted date in cases

        x12Data += `\nSE*${segCnt}*${isa13}~`
          + '\nGE*1*1~'
          + `\nIEA*1*${isa13}~`;

        // File 837 is ready for txmission
        filCnt += 1;
        console.log('x12_File_Data', x12Data);

        if (save2disk) {
          const blob = new Blob([x12Data], { type: 'text/plain' }); // Create the large binary object (blob) del text en x12Data
          const url = window.URL.createObjectURL(blob); // This line generates a temporary URL that references the Blob object created earlier
          const a = document.createElement('a');  // This line creates a new anchor (<a>) element in the DOM that will be used to facilitate the download by simulating a click event
          a.href = url; // This line sets the href attribute of the anchor element to the Blob URL created earlier
          a.download = filNm; // This line sets the download attribute of the anchor element to the desired filename (filNm)
          a.click();  // This initiates the download process without requiring user interaction
          window.URL.revokeObjectURL(url);  // Clean up. This line releases the memory associated with the Blob URL

          processed.forEach(cl => {
            // Set submitted date in case multi query
            qSubDt += "Exec spMB_Web_Save_SubmittedDt @sn = '" + this._recordService.sn
              + "', @subDtCasId = '" + cl.casIdPs.substring(0, cl.casIdPs.length - 1)
              + "', @subDtPSP = '" + (cl.casIdPs.substring(cl.casIdPs.length - 1, cl.casIdPs.length) === 'P' ? '1' : '2')
              + "', @subDtDateTime = '" + tdtMom.format('YYYY-MM-DD HH:mm:ss')
              + "', @subDtUserId = '" + this.userID
              + "', @subDtType = '" + '5010_File'
              + "';";
          });

          console.log('%c' + 'q @ tx837:' + qSubDt, 'color: black; background: #90EE90; font-size: 12px');
          this._websocketService.sendChat('query', this._recordService.sn, qSubDt);

        } else {

          this._x12Service.sendInmeX837(this._recordService.sn, sub.SubUser, sub.SubPw, filNm, x12Data)
            .subscribe({
              next: (data: any) => {
                console.log('response-from-clearinghouse', data);
                let q: string = ''; // Query to spMB_Sio_Save2Q837 to save stat, txDt, filNm
                let via = '?';
                if (data.includes('inmediata.com')) {
                  via = 'Inmediata';
                  const parser = new DOMParser();
                  const xmlDoc = parser.parseFromString(data, "text/xml");
                  console.log('xmlDoc-from-clearinghouse', xmlDoc);
                  const errCnt: string = this.extractXmlNodeTxtContent(xmlDoc, 'ErrorCount');
                  console.log('errCnt-in-response-from-clearinghouse', errCnt);
                  const message: string = this.extractXmlNodeTxtContent(xmlDoc, 'Message');
                  console.log('message-from-clearinghouse', message);
                  rxStat.push({ message: message, errCnt: errCnt, filNo: rxStatFilCnt.toString() });
                  rxStatFilCnt++;

                  console.log('rxStat', rxStat);
                  console.log('rxStatFilCnt', rxStatFilCnt);
                  console.log('processed', processed);

                  processed.forEach(clmSnt => {
                    let sentClm: IQ837 = this._recordService.localQ837.find(q => q.casID + q.ps === clmSnt.casIdPs);
                    if (sentClm) {
                      sentClm.chk = '0';  // Unchk selected to send ckbox
                      const i = rxStat.findIndex(rx => rx.filNo === processed.find(p => p.casIdPs === (sentClm.casID + sentClm.ps)).filNo);
                      if (i > -1) { // Update status
                        sentClm.status.txOk = +rxStat[i].errCnt === 0 ? '1' : '-1';
                        sentClm.status.txMsg = `${rxStat[i].message}`;
                      }

                      q += "Exec spMB_Sio_Save2Q837 @sn = '" + this._recordService.sn
                        + "', @casID = '" + clmSnt.casIdPs.substring(0, clmSnt.casIdPs.length - 1)
                        + "', @ps = '" + clmSnt.casIdPs.substring(clmSnt.casIdPs.length - 1, clmSnt.casIdPs.length)
                        + "', @stat = '" + JSON.stringify(sentClm.status)
                        + "', @txDt = '" + tdtMom.format('YYYY-MM-DD HH:mm:ss')
                        + "', @filNm = '" + clmSnt.filNm
                        + "', @uID = '" + this.userID
                        + "';"

                      // Set submitted date in case multi query
                      qSubDt += "Exec spMB_Web_Save_SubmittedDt @sn = '" + this._recordService.sn
                        + "', @subDtCasId = '" + sentClm.casID
                        + "', @subDtPSP = '" + sentClm.ps
                        + "', @subDtDateTime = '" + tdtMom.format('YYYY-MM-DD HH:mm:ss')
                        + "', @subDtUserId = '" + this.userID
                        + "', @subDtType = '" + '5010_MBWeb'
                        + "';";

                      if (!filNmToasted.includes(clmSnt.filNm)) {  // To not repeat toast with same filNm
                        this._toastService.updateDeadCenter(true);
                        const hdr = errCnt ? `${errCnt} Errors` : 'Ok';
                        if (+errCnt) {
                          this._toastService.show(
                            `${clmSnt.filNm}\n${sentClm.status.txMsg}`,
                            {
                              header: (
                                hdr
                              ), autohide: false, error: true
                            }
                          );
                        } else {
                          this._toastService.show(
                            (clmSnt.filNm),
                            {
                              header: (
                                hdr
                              ), autohide: false, success: true
                            }
                          );
                        }
                        filNmToasted.push(clmSnt.filNm);
                      }
                    }
                  });
                  // Maybe other clearinghouses here!
                }

                if (q) {  // spMB_Sio_Save2Q837
                  console.log('%c' + 'q @ tx837:' + q, 'color: black; background: #90EE90; font-size: 12px');
                  this._websocketService.sendChat('query', this._recordService.sn, q);

                  console.log('%c' + 'q @ tx837:' + qSubDt, 'color: black; background: #90EE90; font-size: 12px');
                  this._websocketService.sendChat('query', this._recordService.sn, qSubDt);
                }
              },
              error: (err: any) => {
                this._toastService.updateDeadCenter(true);
                this._toastService.show(
                  (this.engLang ? "x837 File response to request not received."
                    : 'No se obtuvo respuesta al someter archivo x837.'),
                  {
                    header: (
                      err.displayMsg
                    ), autohide: true, error: true
                  }
                );
              }
            });
        }
        this.saveX12toX12Register(filNm, isa13, x12Data, tdtMom);
      }
    } // Look for next batch

    let msg: string;
    if (filCnt) {
      msg = this.engLang ? `${filCnt} Files transmitted, ${casCnt} claims.` : `${filCnt} Archivos transmitido, ${casCnt} casos.`;
    } else {
      msg = this.engLang ? 'Nothing transmitted.' : 'Nada transmitido.';
    }
    this._toastService.show(
      (msg),
      {
        header: (
          this.engLang ? 'Finished!' : '¡Terminado!'
        ), autohide: false, warning: true
      }
    );
    this._recordService.showSubmitter = false;
  }

  saveX12toX12Register(filNm: string, isa13: string, x12Data: string, tdtMom: any) {
    const q = "Exec spMB_Sio_SaveX12Register @sn = '" + this._recordService.sn
      + "', @fileType = '837P"
      + "', @fileDt = '" + tdtMom.format('YYYY-MM-DD HH:mm:ss')
      + "', @fileNm = '" + filNm
      + "', @iSA13_ControlNo = '" + isa13
      + "', @iSA10 = '" + x12Data.substring(77, 81)
      + "', @iSA09 = '" + x12Data.substring(70, 76)
      + "',@fileContent = '" + this._help.escApos(x12Data) + "';"

    console.log('%c' + 'q @ tx837:' + q, 'color: black; background: #90EE90; font-size: 12px');
    this._websocketService.sendChat('query', this._recordService.sn, q);
  }

  extractXmlNodeTxtContent(node, name): string {
    let textContent = '';
    if (node.childNodes && node.childNodes.length > 0) {
      for (let child of node.childNodes) {
        if (child.tagName === name) {
          textContent = child.textContent.trim();
          return textContent;
        }
      }
      for (let child of node.childNodes) {
        textContent = this.extractXmlNodeTxtContent(child, name);
        if (textContent) {
          return textContent;
        }
      }
    }
  }

  onClick_openRecordCase(clm: IQ837) {  // Load into record component for editing
    const event: any = { patID: clm.patID, casID: clm.casID, ps: clm.ps };
    this.openRecordCaseClick.emit(event);
  }

}
