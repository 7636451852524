import { Component, inject, Input, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import moment from 'moment';
import { CkRegisterService } from '../../ck-register/ck-register.service';
import { CkRegister835DetailService } from '../../ck-register/ck-register835-detail/ck-register835-detail.service';
import { AppToastsService } from '../../shared/app-toasts/app-toasts.service';
import { DataMessageService } from '../../shared/data-message.service';
import { ExportExcelService } from '../../shared/export-excel.service';
import { IRptAging } from '../../shared/interfaces/rptAging';
import { IRptAgingClm } from '../../shared/interfaces/rptAgingClm';
import { IRptAgingDet } from '../../shared/interfaces/rptAgingDet';
import { IRptAgingIns } from '../../shared/interfaces/rptAgingIns';
import { IRptAgingPad } from '../../shared/interfaces/rptAgingPad';
import { ISite } from '../../shared/interfaces/site';
import { RecordService } from '../../record/record.service';

interface ICarcNotes {
  code: string,
  descr: string
};

@Component({
  selector: 'app-rpt-aging',
  templateUrl: './rpt-aging.component.html',
  styleUrls: ['./rpt-aging.component.css'],
  standalone: false
})
export class RptAgingComponent {
  _recordService = inject(RecordService);

  @Input() rptAgingData: IRptAging;
  @Input() rptFromDt: string;
  @Input() rptToDt: string;
  @Input() rptOptions: any;
  @Input() seldProvsHead: string[];
  @Input() seldInses1: string[];
  @Input() seldInses2: string[];
  @Input() seldInses1Head: string[];
  @Input() seldInses2Head: string[];
  @Input() seldFacsHead: string[];
  @Input() seldLocalesHead: string[];

  @ViewChild(MatSort) sort: MatSort;

  sn: string;
  engLang: boolean;
  userID: string;
  userLastNm: string;
  userFirstNm: string;

  dataSource: MatTableDataSource<any>;
  rptAgingDisplydCols: string[] = [
    'InLNm',
    'InID',
    'ag30',
    'ag60',
    'ag90',
    'ag120',
    'ag150',
    'ag6m',
    'ag1y',
    'ag2y',
    'ag3y',
    'balance',
    'printr'
  ];
  ins: IRptAgingIns[] = [];
  clm: IRptAgingClm[] = [];
  det: IRptAgingDet[] = [];
  pad: IRptAgingPad[] = [];
  tot: IRptAgingIns;
  ajusteStr: string = 'AJUSTE';
  clmExpanded: IRptAgingClm[] = [];
  detExpanded: IRptAgingDet[] = []; // Includes payments
  padExpanded: IRptAgingPad[] = [];
  expandedIns: string[] = []; // Use to highlight ins clicked to expand
  carcNotes: ICarcNotes[] = [];
  insPrintAll: string = '';

  constructor(private _dataMessageService: DataMessageService,
    private _ckRegisterService: CkRegisterService,
    private _appToastsService: AppToastsService,
    private _ckRegister835DetailService: CkRegister835DetailService,
    private _xcel: ExportExcelService,
    public _dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    // this._dataMessageService.currentSnStr.subscribe(snStr => this.snChange(snStr)); // Subscription looking for changes in sn
    this._dataMessageService.currentEngLangStr.subscribe(engLangStr => this.engLangChange(engLangStr)); // Subscription looking for changes in engLang

    this.ins = this.rptAgingData.ins.slice(0, this.rptAgingData.ins.length - 1);
    this.clm = this.rptAgingData.clm.slice(); // Reset to initial state
    this.det = this.rptAgingData.det.slice(); // Reset to initial state
    this.tot = this.rptAgingData.ins[this.rptAgingData.ins.length - 1];

    let arr: any[] = this.ins.slice();
    this.dataSource = new MatTableDataSource(arr);
    this.dataSource.sort = this.sort; // Always follow this.dataSource = ... with this to maintain header sort arrow synced
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.rptAgingData) { // Report parameters changed
      this.ins = this.rptAgingData.ins.slice(0, this.rptAgingData.ins.length - 1);  // Reset to initial state minus totals bottom row
      this.clm = this.rptAgingData.clm.slice(); // Reset to initial state
      this.det = this.rptAgingData.det.slice(); // Reset to initial state

      if (this.tot) {
        Object.keys(this.tot).forEach(key => {  // Clear balances
          console.log(`${key}: ${this.tot[key]}`);
          if (key.match(/^ag\d/g) || key.match(/^c\d/g) || key === 'balance' || key === 'tCount') {
            this.tot[key] = 0;
          }
        });
      }

      let arr: any[] = this.ins.slice();
      this.dataSource = new MatTableDataSource(arr);
      this.dataSource.sort = this.sort; // Always follow this.dataSource = ... with this to maintain header sort arrow synced
    }
  }

  isExpanded(row: any): boolean {
    return this.expandedIns.includes(row.i2cKey);
  }

  sortea(sort) {
    if (!sort.active || sort.direction === '') {  // No active sort or sort disabled, reset your data source & "sort" (see below)
      let arr: any[] = this.ins.slice();
      this.dataSource = new MatTableDataSource(arr);
      this.dataSource.sort = this.sort; // Always follow this.dataSource = ... with this to maintain header sort arrow synced
      return;
    }

    let arr2sort: any[] = this.ins.slice();
    if (sort.direction === 'asc') {
      if (sort.active === 'InLNm') {
        arr2sort.sort(this.sortBy_InsLNm_Asc);
      } else {
        arr2sort.sort(this.sortBy_balance_Asc);
      }
    } else {
      if (sort.active === 'InLNm') {
        arr2sort.sort(this.sortBy_InsLNm_Dsc);
      } else {
        arr2sort.sort(this.sortBy_balance_Dsc);
      }
    }
    this.ins = arr2sort.slice();
    this.dataSource = new MatTableDataSource(this.ins);
    this.dataSource.sort = this.sort; // Always follow this.dataSource = ... with this to maintain header sort arrow synced
  }

  sortBy_InsLNm_Asc(a, b): number {
    if (a.InLNm < b.InLNm) {
      return 1;
    }
    if (a.InLNm > b.InLNm) {
      return -1;
    }
    return 0;
  }

  sortBy_InsLNm_Dsc(a, b): number {
    if (a.InLNm > b.InLNm) {
      return 1;
    }
    if (a.InLNm < b.InLNm) {
      return -1;
    }
    return 0;
  }

  sortBy_balance_Asc(a, b): number {
    if (+a.balance < +b.balance) {
      return 1;
    }
    if (+a.balance > +b.balance) {
      return -1;
    }
    return 0;
  }

  sortBy_balance_Dsc(a, b): number {
    if (+a.balance.replace(/[,]*/g, '') > +b.balance.replace(/[,]*/g, '')) {
      return 1;
    }
    if (+a.balance.replace(/[,]*/g, '') < +b.balance.replace(/[,]*/g, '')) {
      return -1;
    }
    return 0;
  }

  snChange(snStr: string) { // for when sn changes
    this.sn = snStr;
  }

  engLangChange(engLangStr: string) { // for when language changes
    engLangStr === 'true' ? this.engLang = true : this.engLang = false;
  }

  onClick_expandIns(row): void { // row = insurance row
    // console.log('row.expandCol', row.expandCol);
    if (row.expandCol == '') {
      // console.log('row', row);
      // console.log('this.clm', this.clm);
      this.clmExpanded = this.clm.slice().filter(c => c.c2iKey === row.i2cKey); // Claims from selected insurance
      // console.log('this.clmExpanded', this.clmExpanded);

      // this.detExpanded = [];
      // this.padExpanded = [];
      let dets: IRptAgingDet[];
      this.clmExpanded.forEach(c => {
        dets = (this.det.filter(d => (d.DetCasID === c.CasID && ((+c.c2iKey && d.DetPS === c.ps) || !+c.c2iKey)))).slice();  // Make a shallow copy
        if (dets?.length) {
          this.detExpanded.push(...dets); // Details for next det component if needed (which contains payments)
        }
      });

      let pads: IRptAgingPad[];
      this.clmExpanded.forEach(c => {
        pads = (this.rptAgingData.pad.filter(a => a.a2cKey === c.c2dKey)).slice();  // Make a shallow copy
        // a2cKey = DetPS + '-' + LTRIM(STR(DetCasID)) 
        // c2dKey = ps + '-' + LTRIM(STR(CasID))
        if (pads?.length) {
          this.padExpanded.push(...pads); // Details for next pad component if needed (which contains CARC/RARC adjustments )
        }
      });

      row.expandCol = 'true';
      this.expandedIns.push(row.i2cKey);
    } else {
      // this.padExpanded = [];
      // this.detExpanded = [];
      // this.clmExpanded = [];

      this.clm.forEach(c => {
        if (c.c2iKey === row.i2cKey) {
          c['expandCol'] = '';
        }
      });

      row.expandCol = '';
      this.expandedIns = this.expandedIns.filter((e) => { return e !== row.i2cKey });
      // console.log('expandedIns', this.expandedIns);
    }
  }

  printRpt(pIns: any, excel: boolean): void {
    let ins2Prt: IRptAgingIns[];
    let tot2Prt: any;
    let clm2Prt: IRptAgingClm[];
    let lc = 14; // Line Count initial value accounting for report heading
    let lpp = 170; // Lines per page
    let pgCnt = 1;  // Page number
    let cNewPg = 0;
    let dNewPg = 0;
    let pNewPg = 0;
    let aNewPg = 0;
    let last_CasID = '';
    let last_DetCasID = '';
    let last_payDetCasID = '';
    let last_a2cKey = '';
    let carcCodes = '';
    let carcNewPg = 0;  // If 1 start a new page
    let subTitPrinted: boolean = false;

    const payDupProps2Delete = ['PayID', 'PayDetID', 'PayPS', 'PayDt', 'PayAmnt', 'PayCkNo', 'PayApCode', 'PayMode', 'PayMemo', 'sqlProcNm', 'expandCol'];
    const detDupProps2Delete = ['DetCasID', 'DetPS', 'oDetPS', 'dtFrom', 'DetQty', 'DetPos', 'DetPcode', 'mods', 'DetUsual', 'DetXpect', 'DetPvdo', 'prod', 'DetDesc', 'DetDx', 'sqlProcNm', 'expandCol'];

    if (pIns) { // Printing one insurance and details
      pIns.printing = 'true';
      ins2Prt = this.ins.slice().filter(i => i.i2cKey === pIns.i2cKey);
      ins2Prt[0]['expanded'] = 1;

      tot2Prt = { ...pIns };
      tot2Prt.InLNm = this.tot.InLNm;
      tot2Prt.InID = this.tot.InID;

      subTitPrinted = false;
      clm2Prt = this.rptAgingData.clm.slice().filter(c => c.c2iKey === pIns.i2cKey);
      clm2Prt.forEach((c, index) => {
        lc += 3;
        // This block is for paging
        // if (last_CasID != c.CasID) {
        //   lc += 1;  // Add 1 for case subtitles on case change
        //   last_CasID = c.CasID
        // }

        if (lc >= lpp) {
          c['break'] = 1; // Sets a page break
          c['pgCnt'] = pgCnt;
          pgCnt++;
          c['newPg'] = cNewPg;
          cNewPg = 1; // Signal for a header
          lc = 7; // Next page header height in lines 
        } else {
          c['break'] = 0;
          c['newPg'] = aNewPg || pNewPg || dNewPg || cNewPg;
          aNewPg = 0;
          pNewPg = 0;
          dNewPg = 0;
          cNewPg = 0;
        }

        c['det'] = this.rptAgingData.det.slice().filter(d => d.d2cKey === c.c2dKey && !d.PayAmnt && !d.PayDt);
        subTitPrinted = false;
        c['det'].forEach((d, index) => {
          lc += 2;  // 1 for data + 1 for extra blank line at the end
          if (!subTitPrinted) {
            lc += 2;
            subTitPrinted = true;
          }
          if (last_DetCasID != d.DetCasID) {
            lc += 1;  // Add 1 for det subtitles on case change
            last_DetCasID = d.DetCasID;
          }

          if (lc >= lpp) {
            d['break'] = 1;  // Sets a page break
            d['pgCnt'] = pgCnt;
            pgCnt++;
            d['newPg'] = dNewPg;
            dNewPg = 1; // Signal for a header
            lc = 7; // Next page header height in lines
          } else {
            d['break'] = 0;
            d['newPg'] = aNewPg || pNewPg || dNewPg || cNewPg;
            aNewPg = 0;
            pNewPg = 0;
            dNewPg = 0;
            cNewPg = 0;
          }

          d['index'] = index;
          payDupProps2Delete.forEach(prop => { delete d[prop]; });  // Get rid of uneeded properties
        });

        c['pay'] = this.rptAgingData.det.slice().filter(d => d.d2cKey === c.c2dKey && d.PayAmnt && d.PayDt);
        subTitPrinted = false;
        c['pay'].forEach((p, index) => {
          lc += 2;
          if (!subTitPrinted) {
            lc += 1;
            subTitPrinted = true;
          }

          if (lc >= lpp) {
            p['break'] = 1;  // Sets a page break
            p['pgCnt'] = pgCnt;
            pgCnt++;
            p['newPg'] = pNewPg;
            pNewPg = 1; // Signal for a header
            lc = 7; // Next page header height in lines
          } else {
            p['break'] = 0;
            p['newPg'] = aNewPg || pNewPg || dNewPg || cNewPg;
            aNewPg = 0;
            pNewPg = 0;
            dNewPg = 0;
            cNewPg = 0;
          }

          p['index'] = index;
          detDupProps2Delete.forEach(prop => { delete p[prop]; });  // Get rid of unneeded properties
        });

        c['pad'] = this.rptAgingData.pad.slice().filter(d => d.a2cKey === c.c2dKey);
        subTitPrinted = false;
        c['pad'].forEach((a, index) => {
          lc += 2;  // 1 for case data + 1 for extra blank line at the end
          if (!subTitPrinted) {
            lc += 1;
            subTitPrinted = true;
          }

          if (last_a2cKey != a.a2cKey) {
            lc += 1;  // Add 1 for det subtitles on case change
            last_a2cKey = a.a2cKey;
          }

          if (lc >= lpp) {
            a['break'] = 1;  // Sets a page break
            a['pgCnt'] = pgCnt;
            pgCnt++;
            a['newPg'] = aNewPg;
            aNewPg = 1; // Signal for a header
            lc = 7; // Next page header height in lines
          } else {
            a['break'] = 0;
            a['newPg'] = aNewPg || pNewPg || dNewPg || cNewPg;
            aNewPg = 0;
            pNewPg = 0;
            dNewPg = 0;
            cNewPg = 0;
          }

          a['index'] = index;

          // Prepare carcCodes to contain all comma separated values of PayAdjGrp & PayAdjReason to include definitions at the end of the printout
          let st = ' ' + a.PayAdjGrp + ',';
          if (!carcCodes.includes(st)) {
            carcCodes += st;
          }
          st = ' ' + a.PayAdjReason + ',';
          if (!carcCodes.includes(st)) {
            carcCodes += st;
          }
        });

        c['index'] = index; // Tag an index property for odd/even styling in html
      });
    } else {
      ins2Prt = this.ins.slice();
      ins2Prt.forEach(i => {

        lc += 1;
        if (!subTitPrinted) {
          lc += 1;
          subTitPrinted = true;
        }

        if (lc >= lpp) {
          i['break'] = 1;
          lc = 0;
        } else {
          i['break'] = 0;
        }
      });
      tot2Prt = this.tot;

      // Prepare carcCodes to contain all comma separated values of PayAdjGrp & PayAdjReason to include definitions at the end of the printout      
      this.rptAgingData.pad.forEach(a => {
        let st = ' ' + a.PayAdjGrp + ',';
        if (!carcCodes.includes(st)) {
          carcCodes += st;
        }
        st = ' ' + a.PayAdjReason + ',';
        if (!carcCodes.includes(st)) {
          carcCodes += st;
        }
      });
    }

    ins2Prt.forEach((itm, index) => itm['index'] = index);  // Tag an index property for odd/even styling in html

    let data: any = {
      "head": {
        "site": {
          "nm": this._recordService.site.nm,
          "ad1": this._recordService.site.ad1,
          "ad2": this._recordService.site.ad2,
          "ct": this._recordService.site.ct,
          "st": this._recordService.site.st,
          "zp": this._recordService.site.zp,
          "tl1": this._recordService.site.tl1,
          "xt1": this._recordService.site.xt1,
          "tl2": this._recordService.site.tl2,
          "xt2": this._recordService.site.xt2
        },
        "sn": this._recordService.sn,
        "dt": moment(new Date()).format('MM/DD/YY h:mm a'),
        "param": {
          "fromDt": this.rptFromDt,
          "toDt": this.rptToDt,
          "provs": this.seldProvsHead,
          "inses1": this.seldInses1Head,
          "inses2": this.seldInses2Head,
          "facs": this.seldFacsHead,
          "locales": this.seldLocalesHead
        },
        "opt": this.rptOptions
      },
      "ins": ins2Prt,
      "tot": tot2Prt,
      "clm": clm2Prt,
      "carcs": [],
      "carcsLen": 0,
      "carcsNewPg": carcNewPg,
      "lastPg": pgCnt
    };

    carcCodes = carcCodes.replace(/ */g, '');
    this.getCarcDescrFromReasonCodeAndPrint(carcCodes, data, lc, lpp, pgCnt, excel);
  }

  // This method renders a blank page when data is past a large size - probably FileReader() max data is reached!
  // backFromPrintRpt(blob: Blob): void {
  //   let reader = new FileReader();
  //   reader.readAsDataURL(blob);
  //   reader.onloadend = function () {
  //     let iframe = "<iframe width='100%' height='100%' src='" + reader.result.toString() + "'></iframe>"
  //     let win = window.open();
  //     win.document.open();
  //     win.document.write(iframe);
  //     win.document.close();
  //   };
  // }

  renderBlob(blob: Blob) {
    const url = window.URL.createObjectURL(blob);
    const newTab = window.open(url);

    if (newTab) {
      newTab.onload = () => {
        window.URL.revokeObjectURL(url);
      };

      this.rptAgingData.ins.forEach(i => i.printing = ''); // Clear all spinners - could not find an easy way to identify the insurance being printed to reset only that spinner
      this.insPrintAll = '';
    } else {
      this._appToastsService.updateDeadCenter(false); // Show toast in dead center of viewport - property sent back to app.component via @ViewChild in app.component
      this._appToastsService.show('Failed to open new tab in browser.', { header: 'Error preparing report * Error preparando reporte', autohide: false, error: true });
    }
  }

  backFromServerError(error): void {
    this._appToastsService.updateDeadCenter(false); // Show toast in dead center of viewport - property sent back to app.component via @ViewChild in app.component
    this._appToastsService.show(error.message, { header: 'Error preparing report * Error preparando reporte', autohide: false, error: true });
  }

  getCarcDescrFromReasonCodeAndPrint(codes: string, agingData: any, lc: number, lpp: number, pgCnt: number, excel: boolean): void {
    this.carcNotes = [];
    if (codes) {
      this._ckRegister835DetailService.getCarcDescrFromReasonCode(codes ? codes : '')
        .subscribe({
          next: data => {
            data.forEach(itm => this.carcNotes.push(itm));

            agingData.carcs = this.carcNotes;
            agingData.carcsLen = this.carcNotes.length;
            if (lpp - lc - this.carcNotes.length - 5 <= 0) {
              agingData.carcsNewPg = 1;
              agingData['finalPg'] = pgCnt + 1;
            } else {
              agingData['finalPg'] = pgCnt;
            }

            if (excel) {
              this.prepareExcelRpt(agingData);

            } else {  // Print/PDF
              if (!agingData.clm || agingData.clm.length === 0) { // Printing all summary aging only
                agingData.carcs = []; // So don't print
                agingData.carcsLen = 0;
              }
              this.buildRpt(agingData);
            }
          },
          error: (err: any) => {
            this._appToastsService.updateDeadCenter(false);
            this._appToastsService.show(err.displayMsg, { header: 'ERROR', autohide: false, error: true });
            return;
          }
        });
    } else {
      agingData['finalPg'] = pgCnt;
      if (excel) {

        this.prepareExcelRpt(agingData);

      } else {  // Print/PDF
        this.buildRpt(agingData);
      }
    }
  }

  buildRpt(data: any) {
    const bdy = {
      "template":
      {
        "name": "/rpts/aging/aging-html"
      }, "data": data,
      "options": { "reports": { "save": true } }
    };

    // console.log('printData', data);

    this._ckRegisterService.postRenderJsRpt(bdy).subscribe({
      next: blob => { this.renderBlob(blob) },
      error: (error: any) => { this.backFromServerError(error) }
    });
  }

  // agingExportToExcel() {
  //   const data = JSON.parse(this.jsonResponse);
  //   const columns = this.getColumns(data);
  //   const worksheet = XLSX.utils.json_to_sheet(data, { header: columns });
  //   const workbook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  //   XLSX.writeFile(workbook, 'data.xlsx');
  // }

  prepareExcelRpt(agingData: any): void {
    // console.log('agingData', agingData);
    let excelData: any = { rows: Array<{ [key: string]: any }> };
    excelData = {};
    excelData.rows = [];
    let allInsRpt: string;

    agingData.ins.forEach(i => {
      let ins = { ...i };  // Shallow copy; ins is a new independent object from i
      ins.i2cKey = undefined;  // Properties to delete in excell sheet
      ins.oInLNm = undefined;
      ins.reclTyp = undefined;
      ins.printing = undefined;
      ins.sqlProcNm = undefined;
      ins.expanded = undefined;
      ins.index = undefined;
      ins.break = undefined;
      ins.sqlProcNm = undefined;
      ins.expandCol = undefined;

      this.renameProperty(ins, 'InLNm', 'Plan');
      this.renameProperty(ins, 'InID', 'Local ID');
      this.renameProperty(ins, 'ag30', '0-30 Bal');
      this.renameProperty(ins, 'ag60', '31-60 Bal');
      this.renameProperty(ins, 'ag90', '61-90 Bal');
      this.renameProperty(ins, 'ag120', '91-120 Bal');
      this.renameProperty(ins, 'ag150', '121-150 Bal');
      this.renameProperty(ins, 'ag6m', '151-6m Bal');
      this.renameProperty(ins, 'ag1y', '6m-1y Bal');
      this.renameProperty(ins, 'ag2y', '1y-2y Bal');
      this.renameProperty(ins, 'ag3y', '2y-3y Bal');
      this.renameProperty(ins, 'balance', 'Total Bal');

      this.renameProperty(ins, 'c30', '0-30 Clms');
      this.renameProperty(ins, 'c60', '31-60 Clms');
      this.renameProperty(ins, 'c90', '61-90 Clms');
      this.renameProperty(ins, 'c120', '91-120 Clms');
      this.renameProperty(ins, 'c150', '121-150 Clms');
      this.renameProperty(ins, 'c6m', '151-6m Clms');
      this.renameProperty(ins, 'c1y', '6m-1y Clms');
      this.renameProperty(ins, 'c2y', '1y-2y Clms');
      this.renameProperty(ins, 'c3y', '2y-3y Clms');
      this.renameProperty(ins, 'tCount', 'Total Clms');

      if (!agingData.clm) { // Reporting all data
        allInsRpt = '(All/Todos)';
        agingData.clm = this.rptAgingData.clm;  // // Reconstruct clm
        agingData.clm.forEach(c => {
          c.det = this.rptAgingData.det.filter(d => { // Reconstruct c.det
            return d.DetCasID === c.CasID && !d.PayAmnt && !d.PayDt;
          });
          c.det.forEach(c => {
            delete c.PayID;
            delete c.PayDetID;
            delete c.PayPS;
            delete c.PayDt;
            delete c.PayAmnt;
            delete c.PayCkNo;
            delete c.PayApCode;
            delete c.PayMode;
            delete c.PayMemo;
          });
          c.pay = this.rptAgingData.det.filter(d => { // Reconstruct c.pay
            return d.DetCasID === c.CasID && d.PayAmnt && d.PayDt;
          });
          c.pay.forEach(p => {
            delete p.DetCasID;
            delete p.DetPS;
            delete p.oDetPS;
            delete p.dtFrom;
            delete p.DetQty;
            delete p.DetPos;
            delete p.DetPcode;
            delete p.mods;
            delete p.DetUsual;
            delete p.DetXpect;
            delete p.DetPvdo;
            delete p.prod;
            delete p.DetDesc;
            delete p.DetDx;
          });
          c.pad = this.rptAgingData.pad.filter(a => { // Reconstruct c.pad
            return a.a2cKey === c.c2dKey;
          });
        });
      }

      let clm: any[] = agingData.clm.slice().filter(c => {  // Claims belonging to insurance i
        return c.c2iKey === i.i2cKey;
      });
      clm.forEach(c => {
        // console.log('c', c);
        // console.log('c.det', c.det);
        // console.log('c.pay', c.pay);
        if (!c.pay.length) {  // Must have at least 1 empty pay object
          c.pay.push({
            "pkId": "",
            "d2cKey": "",
            "PayID": "",
            "PayDetID": "",
            "PayPS": "",
            "PayDt": "",
            "PayAmnt": "",
            "PayCkNo": "",
            "PayApCode": "",
            "PayMode": "",
            "PayMemo": "",
            "break": 0,
            "newPg": 0,
            "index": 0
          });
        }
        // console.log('c.pad', c.pad);
        if (!c.pad.length) {  // Must have at least 1 empty pad object
          c.pad.push({
            "PayAdjID": "",
            "PayAdjPayId": "",
            "a2cKey": "",
            "PayAdjGrp": "",
            "PayAdjReason": "",
            "PayAdjAmnt": "",
            "PayAdjHeRem": "",
            "PayDt": "",
            "cptMod": "",
            "expandCol": "",
            "sqlProcNm": ""
          });
        }
      });

      let row: any;
      this.cleanUpClm4excel(clm).forEach(c => {
        let insArr = Object.entries(ins); // Array of ins properties & values
        let reducedInsArr = insArr.filter(([key, value]) => value !== undefined);  // An array without undefined property values

        this.cleanUpDet4excel(Object.fromEntries(c).det).forEach(d => {

          this.cleanUpPay4excel(Object.fromEntries(c).pay).forEach(p => {

            this.cleanUpPad4excel(Object.fromEntries(c).pad).forEach(a => {
              row = Object.assign({}, Object.fromEntries(reducedInsArr), Object.fromEntries(c), Object.fromEntries(d), Object.fromEntries(p), Object.fromEntries(a)); // Revert reduced arrays into an object merged with claim, det, pay, pad objects
              // Following properties arrays not needed anymore
              delete row.det;
              delete row.pay;
              delete row.pad;
            });
          });
        });

        excelData.rows.push({ ...row }); // { ...row } is a new independent object from row
      });
    });

    console.log('excelData', excelData);
    console.log('carcNotes', this.carcNotes)
    const fil: string = 'Aging ' + this.rptFromDt.replace(/\//g, '-') + ' ' + this.rptToDt.replace(/\//g, '-') + ' Prov:_' + this.seldProvsHead + ' Plan:_' + (allInsRpt ? allInsRpt : agingData.ins[0].InLNm + agingData.ins[0].InID);
    const tit: string = 'Aging ' + this.rptFromDt + ' - ' + this.rptToDt + ' Prov: ' + this.seldProvsHead + ' Plan: ' + (allInsRpt ? allInsRpt : agingData.ins[0].InLNm + agingData.ins[0].InID) + ' * ' + this._recordService.site.nm + ' * ' + this._recordService.site.ad1 + ' * ' + this._recordService.site.ct + ' * ' + this._recordService.site.st + ' * ' + this._recordService.site.zp + ' * ' + this._recordService.site.tl1 + ' * SN:' + this.sn;
    this._xcel.agingExportToExcel(excelData.rows, this.carcNotes, fil, tit, this.engLang);

    this.rptAgingData.ins.forEach(i => i.printing = '');  // Turn spinners off
    this.insPrintAll = '';
  }

  renameProperty(obj, oldProp, newProp) {
    if (obj.hasOwnProperty(oldProp)) {
      obj[newProp] = obj[oldProp]; // Add new property preserving old property value
      delete obj[oldProp]; // Delete old property & value
    }
  }

  cleanUpClm4excel(clmObjArr: any[]): any[] {
    let reducedClmArr: any[] = [];
    clmObjArr.forEach(c => {
      let clm = { ...c };  // Shallow copy; clm is a new independent object from c
      // set undefined props to delete later
      clm.c2dKey = undefined;
      clm.c2iKey = undefined;
      clm.CasID = undefined;
      clm.PatID = undefined;
      clm.hasNote = undefined;
      clm.NoteText = undefined;
      clm.oDys = undefined;
      clm.expandCol = undefined;
      clm.sqlProcNm = undefined;
      clm.break = undefined;
      clm.newPg = undefined;
      clm.index = undefined;
      // co.det = undefined;  To be deleted later
      // co.pay = undefined;  ""
      // co.pad = undefined;  ""

      this.renameProperty(clm, 'CasDt', 'Posted');
      this.renameProperty(clm, 'CasNo', 'Claim No');
      this.renameProperty(clm, 'CasContNo', 'Contract No');
      this.renameProperty(clm, 'PatNm', 'Patient');
      this.renameProperty(clm, 'ps', 'Claim Prim/Sec');
      this.renameProperty(clm, 'cBalance', 'Balance');
      this.renameProperty(clm, 'dxs', 'Diags.');
      this.renameProperty(clm, 'otherIns', 'Other Insurance');
      this.renameProperty(clm, 'dys', 'Days old');
      this.renameProperty(clm, 'reclaimed', 'Reclaimed');
      this.renameProperty(clm, 'adjust', 'Adjusted');

      let clmArr = Object.entries(clm); // Array of clm properties & values
      let reducedArr = clmArr.filter(([key, value]) => value !== undefined);  // An array without undefined property values

      reducedClmArr.push(reducedArr);
    });
    return reducedClmArr;
  }

  cleanUpDet4excel(detObjArr: any[]): any[] {
    let reducedDetArr: any[] = [];
    detObjArr.forEach(d => {
      let det: any = { ...d };  // Shallow copy; det is a new independent object from d
      /// set undefined props to delete later
      det.d2cKey = undefined;
      det.DetCasID = undefined;
      det.oDetPS = undefined;
      det.break = undefined;
      det.newPg = undefined;
      det.index = undefined;
      det.pkId = undefined;
      det.sqlProcNm = undefined;

      // Rename props
      this.renameProperty(det, 'DetPS', 'Bill to Prim/Sec');
      this.renameProperty(det, 'dtFrom', 'From');
      this.renameProperty(det, 'DetQty', 'Qty');
      this.renameProperty(det, 'DetPos', 'POS');
      this.renameProperty(det, 'DetPcode', 'CPT');
      this.renameProperty(det, 'mods', 'Modifs');
      this.renameProperty(det, 'DetUsual', 'Usual');
      this.renameProperty(det, 'DetXpect', 'Esperado');
      this.renameProperty(det, 'DetPvdo', 'Coins');
      this.renameProperty(det, 'prod', 'Rendering');
      this.renameProperty(det, 'DetDesc', 'Descr');
      this.renameProperty(det, 'DetDx', 'Dx pointr');

      let detArr = Object.entries(det); // Array of det properties & values
      let reducedArr = detArr.filter(([key, value]) => value !== undefined);  // An array without undefined property values

      reducedDetArr.push(reducedArr);
    });
    return reducedDetArr;
  }

  cleanUpPay4excel(payObjArr: any[]): any[] {
    let reducedPayArr: any[] = [];
    payObjArr.forEach(p => {
      let pay: any = { ...p };  // Shallow copy; pay is a new independent object from p
      // set undefined props to delete later
      pay.PayID = undefined;
      pay.PayDetID = undefined;
      pay.break = undefined;
      pay.newPg = undefined;
      pay.index = undefined;
      pay.d2cKey = undefined;
      pay.pkId = undefined;
      pay.sqlProcNm = undefined;

      // Rename props
      this.renameProperty(pay, 'PayPS', 'Pay from Prim/Sec');
      this.renameProperty(pay, 'PayDt', 'Payed Dt');
      this.renameProperty(pay, 'PayAmnt', 'Payed Amnt');
      this.renameProperty(pay, 'PayCkNo', 'Ck No.');
      this.renameProperty(pay, 'PayApCode', 'ICN');
      this.renameProperty(pay, 'PayMode', 'Pay Mode');
      this.renameProperty(pay, 'PayMemo', 'Memo');

      // console.log('pay', pay);
      let payArr = Object.entries(pay); // Array of pay properties & values
      let reducedArr = payArr.filter(([key, value]) => value !== undefined);  // An array without undefined property values

      reducedPayArr.push(reducedArr);
    });
    return reducedPayArr;
  }

  cleanUpPad4excel(padObjArr: any[]): any[] {
    let reducedPadArr: any[] = [];
    padObjArr.forEach(p => {
      let pad: any = { ...p };  // Shallow copy; pad is a new independent object from p
      // set undefined props to delete later
      pad.a2cKey = undefined;
      pad.a2cKey = undefined;
      pad.sqlProcNm = undefined;
      pad.PayAdjID = undefined;
      pad.PayAdjPayId = undefined;
      pad.break = undefined;
      pad.newPg = undefined;
      pad.index = undefined;

      // Rename props
      this.renameProperty(pad, 'PayDt', 'Pay Dt');
      this.renameProperty(pad, 'cptMod', 'CPT-Mod');
      this.renameProperty(pad, 'PayAdjHeRem', 'Adj Remark');
      this.renameProperty(pad, 'PayAdjAmnt', 'Adj Amount');
      this.renameProperty(pad, 'PayAdjReason', 'Adj Reason Code');
      this.renameProperty(pad, 'PayAdjGrp', 'Adj Group Code');

      // console.log('pad', pad);
      let padArr = Object.entries(pad); // Array of pad properties & values
      let reducedArr = padArr.filter(([key, value]) => value !== undefined);  // An array without undefined property values

      reducedPadArr.push(reducedArr);
    });
    return reducedPadArr;
  }

}

