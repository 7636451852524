import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';

import { HelperRtnsComponent } from '../shared/helper-rtns.component';
import { ILocales } from '../shared/interfaces/locales';
import { ILocalInsurances } from '../shared/interfaces/localInsurances';
import { ILocalProviders } from '../shared/interfaces/localProviders';
import { ILocalProductors } from '../shared/interfaces/localProductors';
import { ILocalReferring } from '../shared/interfaces/localReferring';
import { ILocalFacilities } from '../shared/interfaces/localFacilities';
import { ILocalICD10 } from '../shared/interfaces/localICD10';
import { ILocalZipCodes } from '../shared/interfaces/localZipCodes';
import { IPcode } from '../shared/interfaces/pcodeObj';
import { IDedcode } from '../shared/interfaces/dedcodeObj';
import { IXcecode } from '../shared/interfaces/xcecodeObj';
import { IRecordsLastUsedParams } from '../shared/interfaces/recordsLastUsedParams';
import { IQ837 } from '../shared/interfaces/q837';
import { ILocalSubmitters } from '../shared/interfaces/submitter';
import { ILocalFormConfig } from '../shared/interfaces/localFormConfig';
import { IPatients } from '../shared/interfaces/patients';
import { ICase } from '../shared/interfaces/case';
import { ICms1500 } from '../shared/interfaces/cms1500';
import { AppToastsService } from '../shared/app-toasts/app-toasts.service';
import { ModalService } from '../shared/modal.service';
import { ISite } from '../shared/interfaces/site';
import moment from 'moment';
import { WebsocketService } from '../shared/websocket.service';
import { ICommonElementStyles } from '../shared/interfaces/common-element-styles';

@Injectable({
  providedIn: 'root'
})
export class RecordService {
  sn: string;
  userID: string;
  engLang: boolean = false;
  site: ISite = {
    pk: '',
    nm: '',
    ad1: '',
    ad2: '',
    ct: '',
    st: '',
    zp: '',
    tl1: '',
    xt1: '',
    tl2: '',
    xt2: '',
    em1: '',
    em2: ''
  };
  ajusteStr: string = 'AJUSTE';
  spinnerPat: boolean = false;
  spinner4Print2: boolean = false;

  proc: string;
  postHeaders: any = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
  getHeaders: any = { headers: new HttpHeaders({ 'Accept': 'application/json' }) };
  locales: ILocales[] = [];
  localProviders: ILocalProviders[] = [];
  localInsurances: ILocalInsurances[] = [];
  localProductors: ILocalProductors[] = [];
  localReferring: ILocalReferring[] = [];
  localFacilities: ILocalFacilities[] = [];
  localICD10: ILocalICD10[];
  localZipCodes: ILocalZipCodes[];
  localQ837: IQ837[];
  localSubmitters: ILocalSubmitters[];
  paymntModes: [{ value: string, name: string, pKey: string }];
  localPcodes: IPcode[] = [];
  localDedcodes: IDedcode[] = [];
  localXcecodes: IXcecode[] = [];
  localConfig: ILocalFormConfig[] = [];
  s837errCnt: string = '0';
  s837ckedCnt: string = '0';

  stylesCfg: ICommonElementStyles;
  showSubmitter: boolean = false;

  spinner4Print1: boolean = false;
  private blobQueue: Blob[] = [];
  lastPrintPg: number = 0;
  iFrameHt: string = '350px';
  casePrinting: { casID: string, ps: string, typ: 'CMS-1500-MBWeb' };
  cms: ICms1500 = {
    top_insName: '',
    top_insAddr1: '',
    top_insAddr2: '',
    top_insCtZpSt: '',
    top_pgCnt: '',
    s1_medicare: '',
    s1_medicaid: '',
    s1_tricare: '',
    s1_champva: '',
    s1_grouphealth: '',
    s1_feca: '',
    s1_other: '',
    s1a_insuredIDno: '',
    s2_patientName: '',
    s3_mm: '',
    s3_dd: '',
    s3_yy: '',
    s3_m: '',
    s3_f: '',
    s4_insuredName: '',
    s5_patientAddr1: '',
    s5_patientAddr2: '',
    s5_city: '',
    s5_acode: '',
    s5_tel: '',
    s5_state: '',
    s5_zip: '',
    s6_self: '',
    s6_spouse: '',
    s6_child: '',
    s6_other: '',
    s7_insuredAddr1: '',
    s7_insuredAddr2: '',
    s7_city: '',
    s7_state: '',
    s7_zip: '',
    s7_acode: '',
    s7_tel: '',
    s9_otherInsuredName: '',
    s9a_OtherInsuredPolicyOrGroup: '',
    s9d_insurancePayerID: '',
    s9d_insurancePlanName: '',
    s10a_yes: '',
    s10a_no: '',
    s10b_yes: '',
    s10b_no: '',
    s10_place: '',
    s10c_yes: '',
    s10c_no: '',
    s11_InsuredPolicyGroup: 'INSUREDs POLICY GROUP',
    s11a_mm: '12',
    s11a_dd: '31',
    s11a_yy: '2020',
    s11a_m: 'X',
    s11a_f: 'X',
    s11b_OtherClaimID: 'OTHER CLAIM ID',
    s11c_insurancePayerID: 'INSURANCE PLAN NAME - PayerID',
    s11c_insurancePlanName: 'INSURANCE PLAN NAME',
    s11d_yes: 'X',
    s11d_no: 'X',
    s12_sof: 'Signature on File',
    s12_date: '',
    s13_sof: 'Signature on File',
    s14_mm: '',
    s14_dd: '',
    s14_yy: '',
    s17_refQual: '',
    s17_refName: '',
    s17b_npi: '',
    s18from_mm: '',
    s18from_dd: '',
    s18from_yy: '',
    s18to_mm: '',
    s18to_dd: '',
    s18to_yy: '',
    s19: '',
    s20_no: 'X',
    s21_icdInd: '0',
    s21a: '',
    s21b: '',
    s21c: '',
    s21d: '',
    s21e: '',
    s21f: '',
    s21g: '',
    s21h: '',
    s21i: '',
    s21j: '',
    s21k: '',
    s21l: '',
    s22_resubmissionCode: '',
    s22_originalRefNo: '',
    s23_priorAuth: '',
    s23_refNo: '',
    s23_cliaNo: '',
    s24: [{
      s24from_mm: '',
      s24from_dd: '',
      s24from_yy: '',
      s24to_mm: '',
      s24to_dd: '',
      s24to_yy: '',
      s24pos: '',
      s24cpt: '',
      s24m1: '',
      s24m2: '',
      s24m3: '',
      s24m4: '',
      s24ptr: '',
      s24chg: '',
      s24units: '',
      s24npi: ''
    },
    {
      s24from_mm: '',
      s24from_dd: '',
      s24from_yy: '',
      s24to_mm: '',
      s24to_dd: '',
      s24to_yy: '',
      s24pos: '',
      s24cpt: '',
      s24m1: '',
      s24m2: '',
      s24m3: '',
      s24m4: '',
      s24ptr: '',
      s24chg: '',
      s24units: '',
      s24npi: ''
    },
    {
      s24from_mm: '',
      s24from_dd: '',
      s24from_yy: '',
      s24to_mm: '',
      s24to_dd: '',
      s24to_yy: '',
      s24pos: '',
      s24cpt: '',
      s24m1: '',
      s24m2: '',
      s24m3: '',
      s24m4: '',
      s24ptr: '',
      s24chg: '',
      s24units: '',
      s24npi: ''
    },
    {
      s24from_mm: '',
      s24from_dd: '',
      s24from_yy: '',
      s24to_mm: '',
      s24to_dd: '',
      s24to_yy: '',
      s24pos: '',
      s24cpt: '',
      s24m1: '',
      s24m2: '',
      s24m3: '',
      s24m4: '',
      s24ptr: '',
      s24chg: '',
      s24units: '',
      s24npi: ''
    },
    {
      s24from_mm: '',
      s24from_dd: '',
      s24from_yy: '',
      s24to_mm: '',
      s24to_dd: '',
      s24to_yy: '',
      s24pos: '',
      s24cpt: '',
      s24m1: '',
      s24m2: '',
      s24m3: '',
      s24m4: '',
      s24ptr: '',
      s24chg: '',
      s24units: '',
      s24npi: ''
    },
    {
      s24from_mm: '',
      s24from_dd: '',
      s24from_yy: '',
      s24to_mm: '',
      s24to_dd: '',
      s24to_yy: '',
      s24pos: '',
      s24cpt: '',
      s24m1: '',
      s24m2: '',
      s24m3: '',
      s24m4: '',
      s24ptr: '',
      s24chg: '',
      s24units: '',
      s24npi: ''
    }],
    s25: '',
    s25_ssn: '',
    s25_ein: '',
    s26: '',
    s27_yes: '',
    s27_no: '',
    s28: '',
    s29: '',
    s30: '',
    s31: '',
    s32_1: '',
    s32_2: '',
    s32_3: '',
    s32_4: '',
    s32_5: '',
    s32a: '',
    s33_acode: '',
    s33_tel: '',
    s33_1: '',
    s33_2: '',
    s33_3: '',
    s33_4: '',
    s33a: '',
    s33b: '',
    bot_RecNo: 'REC: 12345-1-20 User 1'
  }

  constructor(
    private _http: HttpClient,
    private _help: HelperRtnsComponent,
    private _toastService: AppToastsService,
    private _modalService: ModalService,
    private _websocketService: WebsocketService) { }

  localesArr(dataSet): void {
    this.locales = dataSet;
  }

  localProvidersArr(dataSet): void {
    this.localProviders = dataSet;
  }

  localInsurancesArr(dataSet): void {
    this.localInsurances = dataSet;
  }

  localProductorsArr(dataSet): void {
    this.localProductors = dataSet;
  }

  localReferringArr(dataSet): void {
    this.localReferring = dataSet;
  }

  localFacilitiesArr(dataSet): void {
    this.localFacilities = dataSet;
  }

  localICD10Arr(dataSet): void {
    this.localICD10 = dataSet;
  }

  localZipCodesArr(dataSet): void {
    this.localZipCodes = dataSet;
  }

  localQ837Arr(dataSet): void {
    let tcked: number = 0;  // Total cked clms
    let terrs: number = 0;  // Total 837 errors in queue
    this.localQ837 = dataSet.filter(q => +q.casID > 0);  // Filter a casID = 0 included just to get a blank result back
    this.localQ837.forEach(q => {
      if (q.stat) {
        q.status = JSON.parse(q.stat);
      } else {
        q.status = JSON.parse('{ "txOk": "", "txMsg": "", "chOk": "", "chMsg": "", "planOk": "", "planMsg": "" }');
      }

      +q.chk ? tcked++ : tcked += 0;
      q.sErrs ? terrs += 1 : terrs += 0;
    });
    this.s837errCnt = terrs.toString();
    this.s837ckedCnt = tcked.toString();
  }

  localSubmittersArr(dataSet): void {
    this.localSubmitters = dataSet.filter(d => +d.SubID);
  }

  localFormConfigArr(dataSet): void {
    this.localConfig = dataSet;
  }

  paymntModesArr(data): void {
    this.paymntModes = data;
  }

  private extractData(res: Response) {
    let returnedData = res;
    return returnedData || {};
  }

  lookUpIcd10CodeOrDescr(code: string, descr: string): Observable<any> {
    return this._http.get(this._help.urlDB + '/api/get-lookUpIcd10CodeOrDescr/' + encodeURIComponent(code) + '/' + encodeURIComponent(descr))
      .pipe(map(this.extractData));
  }

  getRecordCasesProcsPay(sn: string, id: string): Observable<any> {
    return this._http.get(this._help.urlDB + '/api/get-RecordCasesProcsPay/' + sn + '/' + id)
      .pipe(map(this.extractData));
  }

  postRenderJsRpt(bdy: any): Observable<any> {
    //let body = JSON.stringify({ "template": { "name": "/demographics/demographics-html" }, "data": { "lastNm": "Deniro", "firstNm": "Robert" }, "options": { "reports": { "save": true } } });
    let body = JSON.stringify(bdy);
    let unm = 'medbiler@gmail.com';
    let pw = 'XaxispjEQZ9r@w7';
    let b64 = 'Basic ' + btoa(unm + ':' + pw);
    let headers = { 'Content-Type': 'application/json', 'Accept': 'text/plain', 'Authorization': b64 };

    return this._http.post<any>('https://medbiller.jsreportonline.net/api/report', body, { headers, 'responseType': 'blob' as 'json' })
      .pipe(map(this.extractData));
  }

  postHl7Data2ApiServerXXX(bdy: any): Observable<any> {
    let body = JSON.stringify(bdy);
    let headers = { 'Content-Type': 'application/json' };

    return this._http.post(this._help.urlDB + 'Xapi/post-sendHl7File', body, { headers })
      .pipe(map(this.extractData));
  }

  postHl7Data2ApiServer(bdy: any): void { // This rtn doesn't work when coded as an Observable
    let body = JSON.stringify(bdy);
    let headers = { 'Content-Type': 'application/json' };

    this._http.post(this._help.urlDB + '/api/post-sendHl7File', body, { headers })
      .subscribe(response => response); // Response is null
  }

  postRecordsLastUsedParams(lastUsedParam: IRecordsLastUsedParams): Observable<any> {
    let body = JSON.stringify({ lastUsedParam });
    return this._http.post<any>(this._help.urlDB + '/api/post-recordsLastUsedParams', body, this.postHeaders);
  }

  getLastUsedParams(sn: string, userID: string): Observable<IRecordsLastUsedParams | any> {
    return this._http.get<IRecordsLastUsedParams>(this._help.urlDB + '/api/get-recordsLastUsedParams/' + sn + '/' + userID,
      this.getHeaders)
      .pipe(
        catchError(err => this.handlrHttpError(err))
      );
  }

  getQ837LastUsedParams(sn: string, userID: string): Observable<IRecordsLastUsedParams | any> {
    return this._http.get<IRecordsLastUsedParams>(this._help.urlDB + '/api/get-recordsLastUsedParams/' + sn + '/' + userID,
      this.getHeaders)
      .pipe(
        catchError(err => this.handlrHttpError(err))
      );
  }

  private handlrHttpError(error: HttpErrorResponse): Observable<any> {
    const errObj = {
      displayMsg: error.status.toString() + ' ' + error.statusText,
      msg: error.message
    }

    this.postApiErrorLog(this.sn, errObj, this.proc)
      .subscribe({
        next: (data: any) => console.log(data),
        error: (err: any) => console.error(err)
      });
    return throwError(() => errObj);
  }

  postApiErrorLog(SN: string, errObj: any, proc): Observable<any> {
    let errStr = JSON.stringify(errObj);
    let body = JSON.stringify({ SN, errStr, proc });

    return this._http.post<any>(this._help.urlDB + '/api/post-apiErrorLog', body, this.postHeaders);
  }

  errMsgSuggestion(): string {
    return this.engLang ? "' Try refreshing (Ctrl-F5) the page or check the Internet service."
      : ' Trate refresh (Ctrl-F5) en la página o verifique el servicio de Internet.'
  }

  printCms1500(ps: string, pat: IPatients, cas: ICase): void {
    this.casePrinting = { casID: cas.casID, ps: ps, typ: 'CMS-1500-MBWeb' };
    this.blobQueue = [];
    this.iFrameHt = '350px';

    let prvNdx = this.localProviders.findIndex(e => e.pKey === cas.casProvID);
    let pc2Print = [];
    let insNdx1 = 0;
    insNdx1 = this.localInsurances.findIndex(e => e.pKey == cas.casI1ID);
    let insNdx2 = 0;
    insNdx2 = this.localInsurances.findIndex(e => e.pKey == cas.casI2ID);
    let cnfNdx = -1;

    let dobMM = (new Date(pat.dob).getMonth() + 1).toString().padStart(2, '0');
    let dobDD = new Date(pat.dob).getDate().toString().padStart(2, '0');
    let dobYYYY = new Date(pat.dob).getFullYear().toString();
    let patSxM = pat.sex == 'M' ? 'X' : '';
    let patSxF = pat.sex == 'F' ? 'X' : '';

    if (ps == '1') {

      pc2Print = cas.procs1.filter(e => { return e.code != this.ajusteStr && e.print });
      if (pc2Print.length == 0) {
        this.spinner4Print1 = false;
        let msgBoxMessage = this.engLang ? 'There are no procedures to print for primary insurance' : 'No hay procedimientos para imprimir del seguro primario';
        let msgBoxTitle = this.engLang ? 'Warning' : 'Aviso'
        this.showMsg(msgBoxMessage, msgBoxTitle);
        return;
      }

      this.spinner4Print1 = true;
      this.cms.top_insName = this.localInsurances[insNdx1].name;
      this.cms.top_insAddr1 = this.localInsurances[insNdx1].add1;
      this.cms.top_insAddr2 = this.localInsurances[insNdx1].add2;
      this.cms.top_insCtZpSt = this.localInsurances[insNdx1].city + ', ' + this.localInsurances[insNdx1].st + ' ' + this.fmtZip(this.localInsurances[insNdx1].zip);
      this.set1500formType(this.localInsurances[insNdx1].s1type);

      this.cms.s1a_insuredIDno = cas.casCont1;

      if (cas.casILastIns1.length > 0) {
        this.cms.s4_insuredName = cas.casILastIns1 + ', ' + cas.casIFirstIns1 + ' ' + cas.casIMidIns1;
        this.cms.s2_patientName = this.cms.s4_insuredName
      } else {
        this.cms.s2_patientName = pat.lastNm + ', ' + pat.firstNm + ' ' + pat.midInit;
        this.cms.s4_insuredName = this.cms.s2_patientName;
      }

      if (+cas.casI2ID > 0) {
        if (this.cms.s1_medicare == 'X') {  // Medicare primary with complimentary secondary insurance
          this.cms.s4_insuredName = '';

          this.cms.s7_insuredAddr1 = '';
          this.cms.s7_insuredAddr2 = '';
          this.cms.s7_city = '';
          this.cms.s7_state = '';
          this.cms.s7_zip = '';
          this.cms.s7_acode = '';
          this.cms.s7_tel = '';

          this.cms.s9_otherInsuredName = 'SAME';
          this.cms.s9a_OtherInsuredPolicyOrGroup = 'MEDIGAP ' + cas.casCont2 + ' ' + cas.casGrp2;
          this.cms.s9d_insurancePayerID = this.localInsurances[insNdx2].payerId;
          this.cms.s9d_insurancePlanName = this.localInsurances[insNdx2].name;

          this.cms.s11_InsuredPolicyGroup = "NONE";
          this.cms.s11a_mm = '';
          this.cms.s11a_dd = '';
          this.cms.s11a_yy = '';
          this.cms.s11a_m = '';
          this.cms.s11a_f = '';
          this.cms.s11b_OtherClaimID = '';
          this.cms.s11c_insurancePayerID = '';
          this.cms.s11c_insurancePlanName = 'NONE';
          this.cms.s11d_yes = '';
          this.cms.s11d_no = '';
        }
      } else {
        this.cms.s11_InsuredPolicyGroup = cas.casGrp1;
        this.cms.s11a_mm = dobMM;
        this.cms.s11a_dd = dobDD;
        this.cms.s11a_yy = dobYYYY;
        this.cms.s11a_m = patSxM;
        this.cms.s11a_f = patSxF;
        this.cms.s11b_OtherClaimID = '';
        this.cms.s11c_insurancePayerID = '';
        this.cms.s11c_insurancePlanName = '';
        this.cms.s11d_yes = '';
        this.cms.s11d_no = 'X';
      }

      cnfNdx = this.localConfig.findIndex(e => e.provID == cas.casProvID && e.insID == cas.casI1ID);

      this.cms.s33b = '';
      if (this.localInsurances[insNdx1].s33bTaxonomy == '1') {
        this.cms.s33b = 'ZZ' + this.localProviders[prvNdx].provTaxonomy;
      }
    } else {  //  ps = '2'

      pc2Print = cas.procs2.filter(e => { return e.code != this.ajusteStr && e.print });
      if (pc2Print.length == 0) {
        this.spinner4Print1 = false;
        let msgBoxMessage = this.engLang ? 'There are no procedures to print for secondary insurance' : 'No hay procedimientos para imprimir del seguro secundario';
        let msgBoxTitle = this.engLang ? 'Warninng' : 'Aviso'
        this.showMsg(msgBoxMessage, msgBoxTitle);
        return;
      }

      this.spinner4Print2 = true;
      this.cms.top_insName = this.localInsurances[insNdx2].name;
      this.cms.top_insAddr1 = this.localInsurances[insNdx2].add1;
      this.cms.top_insAddr2 = this.localInsurances[insNdx2].add2;
      this.cms.top_insCtZpSt = this.localInsurances[insNdx2].city + ', ' + this.localInsurances[insNdx2].st + ' ' + this.localInsurances[insNdx2].zip;
      this.set1500formType(this.localInsurances[insNdx2].s1type);

      this.cms.s1a_insuredIDno = cas.casCont2;

      if (cas.casILastIns2.length > 0) {
        this.cms.s4_insuredName = cas.casILastIns2 + ', ' + cas.casIFirstIns2 + ' ' + cas.casIMidIns2;
        this.cms.s2_patientName = this.cms.s4_insuredName
      } else {
        this.cms.s2_patientName = pat.lastNm + ', ' + pat.firstNm + ' ' + pat.midInit;
        this.cms.s4_insuredName = this.cms.s2_patientName;
      }

      if (+cas.casI1ID > 0) {
        if (cas.casILastIns1.length > 0) {
          this.cms.s9_otherInsuredName = cas.casILastIns1 + ', ' + cas.casIFirstIns1 + ' ' + cas.casIMidIns1;
        } else {
          this.cms.s9_otherInsuredName = pat.lastNm + ', ' + pat.firstNm + ' ' + pat.midInit;
        }
        this.cms.s9a_OtherInsuredPolicyOrGroup = cas.casCont1 + '   ' + cas.casGrp1;
        this.cms.s9d_insurancePlanName = this.localInsurances[insNdx1].name;
        this.cms.s9d_insurancePayerID = this.localInsurances[insNdx1].payerId;

        // this.cms.s11_InsuredPolicyGroup = cas.casGrp1;
        // this.cms.s11b_OtherClaimID = pat.employer;
        // this.cms.s11c_insurancePayerID = this.localInsurances[insNdx1].payerId;
        // this.cms.s11c_insurancePlanName = this.localInsurances[insNdx1].name;
        // this.cms.s11d_yes = 'X';
        // this.cms.s11d_no = '';
      }

      if (cas.casILastIns2.length > 0) {
        this.cms.s4_insuredName = cas.casILastIns2 + ', ' + cas.casIFirstIns2 + ' ' + cas.casIMidIns2;
      } else {
        this.cms.s4_insuredName = pat.lastNm + ', ' + pat.firstNm + ' ' + pat.midInit;
      }

      this.cms.s11_InsuredPolicyGroup = cas.casGrp2;
      this.cms.s11a_mm = dobMM;
      this.cms.s11a_dd = dobDD;
      this.cms.s11a_yy = dobYYYY;
      this.cms.s11a_m = patSxM;
      this.cms.s11a_f = patSxF;
      this.cms.s11b_OtherClaimID = '';
      this.cms.s11c_insurancePayerID = this.localInsurances[insNdx2].payerId;
      this.cms.s11c_insurancePlanName = this.localInsurances[insNdx2].name;
      this.cms.s11d_yes = 'X';
      this.cms.s11d_no = '';

      cnfNdx = this.localConfig.findIndex(e => e.provID == cas.casProvID && e.insID == cas.casI2ID);

      if (this.localInsurances[insNdx2].s33bTaxonomy == '1') {
        this.cms.s33b = 'ZZ' + this.localProviders[prvNdx].provTaxonomy;
      }

      this.cms.s33b = '';
      if (this.localInsurances[insNdx2].s33bTaxonomy == '1') {
        this.cms.s33b = 'ZZ' + this.localProviders[prvNdx].provTaxonomy;
      }
    }

    this.cms.s29 = cas.pays.reduce((t: number, e) => {
      if (e.payPS == 'D1' && ps == '1' || e.payPS == 'D2' && ps == '2') {
        t += parseFloat(e.payAmnt);
      }
      return t;
    }, 0).toFixed(2);

    let pgs = 0;  // Total pages to print
    if (pc2Print.length % 6 > 0) {  //  Get total pages count depending on number of procedure codes entered/selected
      pgs = 1 + Math.floor(pc2Print.length / 6);
    } else {
      pgs = Math.floor(pc2Print.length / 6);
    }
    this.lastPrintPg = pgs;

    this.cms.s3_mm = dobMM;
    this.cms.s3_dd = dobDD;
    this.cms.s3_yy = dobYYYY;
    this.cms.s3_m = patSxM;
    this.cms.s3_f = patSxF;

    if (this.cms.s1_medicare != 'X') {
      this.cms.s7_insuredAddr1 = pat.add1;
      this.cms.s7_insuredAddr2 = pat.add2;
      this.cms.s7_city = pat.city;
      this.cms.s7_state = pat.st;
      this.cms.s7_zip = pat.zip;
      this.cms.s7_acode = /^\(\d{3}\)/g.test(pat.tel) ? pat.tel.match(/^\(\d{3}\)/g).toString().replace('(', '').replace(')', '') : '';
      this.cms.s7_tel = pat.tel.substr(-8);

      if (pat.relation = '1') {
        this.cms.s2_patientName = this.cms.s4_insuredName;
      } else {
        this.cms.s2_patientName = pat.lastNm + ', ' + pat.firstNm + ' ' + pat.midInit;
      }
    }

    switch (pat.relation) {
      case '1':
        this.cms.s6_self = 'X';
        break;
      case '2':
        this.cms.s6_spouse = 'X';
        break;
      case '3':
        this.cms.s6_child = 'X';
        break;
      case '4':
        this.cms.s6_other = 'X';
        break;
    }

    this.cms.s5_patientAddr1 = pat.add1;
    this.cms.s5_patientAddr2 = pat.add2;
    this.cms.s5_city = pat.city;
    this.cms.s5_state = pat.st;
    this.cms.s5_zip = this.fmtZip(pat.zip);
    this.cms.s5_acode = /\(\d{3}\)/g.test(pat.tel) ? pat.tel.match(/\(\d{3}\)/g).toString().replace('(', '').replace(')', '') : '';
    this.cms.s5_tel = pat.tel.substr(-8);

    this.cms.s10a_no = 'X';
    this.cms.s10a_yes = '';
    this.cms.s10b_no = 'X';
    this.cms.s10b_yes = '';
    this.cms.s10c_no = 'X';
    this.cms.s10c_yes = '';
    this.cms.s10_place = '';
    switch (cas.casAccTyp) {
      case '1': // Auto accident
        this.cms.s10b_no = '';
        this.cms.s10b_yes = 'X';
        this.cms.s10_place = 'PR';
        break;
      case '2': // Work accident
        this.cms.s10a_no = '';
        this.cms.s10a_yes = 'X';
        this.cms.s10_place = 'PR';
        break;
      case '3': // Other accident
        this.cms.s10c_no = '';
        this.cms.s10c_yes = 'X';
        this.cms.s10_place = 'PR';
        break;
      default:
        break;
    }

    this.cms.s12_date = cas.casDat;

    if (/^\d{2}\/\d{2}\/\d{4}$/g.test(cas.casAccDt)) {
      this.cms.s14_mm = cas.casAccDt.match(/^\d{2}/g)[0];
      this.cms.s14_dd = cas.casAccDt.match(/\/\d{2}/g)[0].replace(/\//g, '');
      this.cms.s14_yy = cas.casAccDt.match(/\d{2}$/g)[0];
    }

    if (/^\d{2}\/\d{2}\/\d{4}$/g.test(cas.casAdmDt)) {
      this.cms.s18from_mm = cas.casAdmDt.match(/^\d{2}/g)[0];
      this.cms.s18from_dd = cas.casAdmDt.match(/\/\d{2}/g)[0].replace(/\//g, '');
      this.cms.s18from_yy = cas.casAdmDt.match(/\d{2}$/g)[0];
    }

    if (cas.casRefNPI) {
      this.cms.s17_refQual = cas.casRefQual;
      this.cms.s17_refName = cas.casRefLastNm + ', ' + cas.casRefFirstNm;
      this.cms.s17b_npi = cas.casRefNPI;
    } else {
      this.cms.s17_refQual = '';
      this.cms.s17_refName = '';
      this.cms.s17b_npi = '';
    }

    if (/^\d{2}\/\d{2}\/\d{4}$/g.test(cas.casDiscDt)) {
      this.cms.s18to_mm = cas.casDiscDt.match(/^\d{2}/g)[0];
      this.cms.s18to_dd = cas.casDiscDt.match(/\/\d{2}/g)[0].replace(/\//g, '');
      this.cms.s18to_yy = cas.casDiscDt.match(/\d{2}$/g)[0];
    }

    this.cms.s19 = cas.cas19;

    let icn = cas.pays.find(e => e.payPS == 'S' + ps && e.payApCode > '');
    if (icn) {
      this.cms.s22_originalRefNo = icn.payApCode; // ICN
      this.cms.s22_resubmissionCode = '7' // Correction
    }

    this.cms.s21a = '';
    this.cms.s21b = '';
    this.cms.s21c = '';
    this.cms.s21d = '';
    this.cms.s21e = '';
    this.cms.s21f = '';
    this.cms.s21g = '';
    this.cms.s21h = '';
    this.cms.s21i = '';
    this.cms.s21j = '';
    this.cms.s21k = '';
    this.cms.s21l = '';
    cas.dxs.forEach(e => {
      switch (e.indx) {
        case '1':
          this.cms.s21a = e.code.replace('.', '');
          break;
        case '2':
          this.cms.s21b = e.code.replace('.', '');
          break;
        case '3':
          this.cms.s21c = e.code.replace('.', '');
          break;
        case '4':
          this.cms.s21d = e.code.replace('.', '');
          break;
        case '5':
          this.cms.s21e = e.code.replace('.', '');
          break;
        case '6':
          this.cms.s21f = e.code.replace('.', '');
          break;
        case '7':
          this.cms.s21g = e.code.replace('.', '');
          break;
        case '8':
          this.cms.s21h = e.code.replace('.', '');
          break;
        case '9':
          this.cms.s21i = e.code.replace('.', '');
          break;
        case 'a':
          this.cms.s21j = e.code.replace('.', '');
          break;
        case 'b':
          this.cms.s21k = e.code.replace('.', '');
          break;
        case 'c':
          this.cms.s21l = e.code.replace('.', '');
          break;
        default:
          break;
      }

    });

    if (cnfNdx >= 0) {
      if (this.cms.s1_medicare == 'X') {
        this.cms.s23_cliaNo = this.localConfig[cnfNdx].clia;  // Clinical labs & Urologists with urinalysis
      }
      this.cms.s23_priorAuth = cas.casAuthNo;
      this.cms.s23_refNo = cas.casRefNo;

      if (this.localConfig[cnfNdx].ssnId.length > 0) {
        this.cms.s25 = this.localConfig[cnfNdx].ssnId;
        this.cms.s25_ssn = 'X';
      } else if (this.localConfig[cnfNdx].einId.length > 0) {
        this.cms.s25 = this.localConfig[cnfNdx].einId;
        this.cms.s25_ein = 'X';
      }
      this.cms.s32_5 = this.localConfig[cnfNdx].mamoCert;
    }

    if (this.cms.s25.length == 0) {
      this.cms.s25 = this.localProviders[prvNdx].taxId;
      if (this.localProviders[prvNdx].ssn == '1') {
        this.cms.s25_ssn = 'X';
      } else if (this.localProviders[prvNdx].ein == '1') {
        this.cms.s25_ein = 'X';
      }
    }

    this.cms.s26 = cas.casNo + '-' + cas.casOfNo.replace(/^0*/g, '');

    this.cms.s27_yes = cas.casAcAss == '1' ? 'X' : '';
    this.cms.s27_no = cas.casAcAss == '0' ? 'X' : '';

    this.cms.s31 = cas.casDat;

    this.cms.s33_acode = this.localProviders[prvNdx].provAcode;
    this.cms.s33_tel = /^\d{7}$/g.test(this.localProviders[prvNdx].provTel) ? this.localProviders[prvNdx].provTel.substring(0, 3) + '-' + this.localProviders[prvNdx].provTel.substring(4, 8) : this.localProviders[prvNdx].provTel;
    this.cms.s33_1 = this.localProviders[prvNdx].provName;
    if (cnfNdx >= 0 && this.localConfig[cnfNdx].add1.length > 0) { // Slot 33 prov address 1st priority is from [FormConfig]
      this.cms.s33_1 = this.localConfig[cnfNdx].name;
      this.cms.s33_2 = this.localConfig[cnfNdx].add1;
      this.cms.s33_3 = this.localConfig[cnfNdx].add2;
      this.cms.s33_4 = this.localConfig[cnfNdx].city + ', ' + this.localConfig[cnfNdx].st + ' ' + this.fmtZip(this.localConfig[cnfNdx].zip);
      this.cms.s33_acode = this.localConfig[cnfNdx].acode;
      this.cms.s33_tel = /^\d{7}$/g.test(this.localConfig[cnfNdx].tel) ? this.localConfig[cnfNdx].tel.substr(0, 3) + '-' + this.localConfig[cnfNdx].tel.substr(3, 4) : this.localConfig[cnfNdx].tel;
    } else if (this.localProviders[prvNdx].poAd1) { // Else use postal address from [ProvidersMD]
      this.cms.s33_1 = this.localProviders[prvNdx].provName;
      this.cms.s33_2 = this.localProviders[prvNdx].poAd1;
      this.cms.s33_3 = this.localProviders[prvNdx].poAd2;
      this.cms.s33_4 = this.localProviders[prvNdx].poCity + ', ' + this.localProviders[prvNdx].poSt + ' ' + this.fmtZip(this.localProviders[prvNdx].poZip);
    } else {  // Else use physical address from [ProvidersMD]
      this.cms.s33_2 = this.localProviders[prvNdx].provAd1;
      this.cms.s33_3 = this.localProviders[prvNdx].provAd2;
      this.cms.s33_4 = this.localProviders[prvNdx].provCity + ', ' + this.localProviders[prvNdx].provSt + ' ' + this.fmtZip(this.localProviders[prvNdx].provZip);
    }
    if (cnfNdx >= 0 && this.localConfig[cnfNdx].npi.length == 10) {
      this.cms.s33a = this.localConfig[cnfNdx].npi;
    } else {
      this.cms.s33a = this.localProviders[prvNdx].npi;
    }

    if (pc2Print.findIndex(e => { return e.pos == '12' }) > -1) {  // Services at pat's home (pos=12) do not require s32
      this.cms.s32_1 = '';
      this.cms.s32_2 = '';
      this.cms.s32_3 = '';
      this.cms.s32_4 = '';
      this.cms.s32a = '';
    }
    let posNot_11_nor_12 = pc2Print.findIndex(e => { return e.pos != '11' && e.pos != '12' });
    if (!posNot_11_nor_12 && +cas.casFacID > 0) {
      let facNdx = this.localFacilities.findIndex(e => { return e.pKey == cas.casFacID });
      this.cms.s32_1 = this.localFacilities[facNdx].facName;
      this.cms.s32_2 = this.localFacilities[facNdx].facAd1;
      this.cms.s32_3 = this.localFacilities[facNdx].facAd2;
      this.cms.s32_4 = this.localFacilities[facNdx].facCity + ', ' + this.localFacilities[facNdx].facSt + ' ' + this.fmtZip(this.localFacilities[facNdx].facZip);
      this.cms.s32a = this.localFacilities[facNdx].facNpi;
    }
    if (pc2Print.findIndex(e => { return e.pos == '11' }) > -1 && posNot_11_nor_12 == -1) {  // Services at phys's office & no home (pos=12) service
      this.cms.s32_1 = '';
      this.cms.s32_2 = this.localProviders[prvNdx].provAd1;
      this.cms.s32_3 = this.localProviders[prvNdx].provAd2;
      this.cms.s32_4 = this.localProviders[prvNdx].provCity + ', ' + this.localProviders[prvNdx].provSt + ' ' + this.fmtZip(this.localProviders[prvNdx].provZip);
    }

    this.cms.bot_RecNo = 'Rec#:' + pat.recNo.replace(/^[0]*/g, '') + '-' + pat.recLocale.replace(/^[0]*/g, '') + '-' + pat.recYr + ' User ' + this.userID + '   {' + this.sn + '}';

    let pgTot: number = 0;
    let j = 0;  // Index to code lines
    for (let p = 1; p <= pgs; p++) {
      this.cms.top_pgCnt = p.toString() + ' of ' + pgs.toString();

      for (let i = 0; i < 6; i++) { // i = index to page lines
        if (j >= pc2Print.length) { // Unused lines
          this.cms.s24[i].s24from_mm = '';
          this.cms.s24[i].s24from_dd = '';
          this.cms.s24[i].s24from_yy = '';
          this.cms.s24[i].s24to_mm = '';
          this.cms.s24[i].s24to_dd = '';
          this.cms.s24[i].s24to_yy = '';
          this.cms.s24[i].s24pos = '';
          this.cms.s24[i].s24cpt = '';
          this.cms.s24[i].s24m1 = '';
          this.cms.s24[i].s24m2 = '';
          this.cms.s24[i].s24m3 = '';
          this.cms.s24[i].s24m4 = '';
          this.cms.s24[i].s24ptr = '';
          this.cms.s24[i].s24chg = '';
          this.cms.s24[i].s24units = '';
          this.cms.s24[i].s24npi = '';
        } else {

          this.cms.s24[i].s24from_mm = pc2Print[j].fromDt.match(/^\d{2}/g).toString();
          this.cms.s24[i].s24from_dd = pc2Print[j].fromDt.match(/\/\d{2}\//g).toString().substring(1, 3);
          this.cms.s24[i].s24from_yy = pc2Print[j].fromDt.match(/\d{2}$/g).toString();

          if (this._help.isDate(new Date(pc2Print[j].toDt))) {
            let dt = this._help.fmtDate(new Date(pc2Print[j].toDt), 'mm/dd/yyyy');
            this.cms.s24[i].s24to_mm = dt.match(/^\d{2}/g)[0].toString();
            this.cms.s24[i].s24to_dd = dt.match(/\/\d{2}\//g)[0].toString().substring(1, 3);
            this.cms.s24[i].s24to_yy = dt.match(/\d{2}$/g)[0].toString();
          } else {
            this.cms.s24[i].s24to_mm = '';
            this.cms.s24[i].s24to_dd = '';
            this.cms.s24[i].s24to_yy = '';
          }

          this.cms.s24[i].s24pos = pc2Print[j].pos;

          this.cms.s24[i].s24cpt = pc2Print[j].code;

          this.cms.s24[i].s24m1 = pc2Print[j].mod1;
          this.cms.s24[i].s24m2 = pc2Print[j].mod2;
          this.cms.s24[i].s24m3 = pc2Print[j].mod3;
          this.cms.s24[i].s24m4 = pc2Print[j].mod4;

          this.cms.s24[i].s24ptr = this.decodeDxPtr(pc2Print[j].dx);

          this.cms.s24[i].s24chg = pc2Print[j].usual;
          pgTot += Math.floor(100 * parseFloat(pc2Print[j].usual) * parseFloat(pc2Print[j].qty));

          this.cms.s24[i].s24units = pc2Print[j].qty;

          let prd = pc2Print[j].prod.toString().match(/^\(\d+/g);
          if (prd) {
            let prdPkey = prd.toString().replace('(', '');
            if (prdPkey) {
              let prdNdx = this.localProductors.findIndex(e => e.pKey == prdPkey);
              this.cms.s24[i].s24npi = this.localProductors[prdNdx].npi;
            }
          } else {
            this.cms.s24[i].s24npi = this.localProviders[prvNdx].npi; // If no rendering specified use provider
          }
        }
        j += 1;
        console.log('i', i);
        console.log('j', j);
      }

      this.cms.s28 = (pgTot / 100).toFixed(2);

      this.cms.s30 = '';
      if (ps === '1') {
        if (this.localInsurances[insNdx1].s30BalDue == '1') {
          this.cms.s30 = (parseFloat(this.cms.s28) - parseFloat(this.cms.s29)).toFixed(2);
        }
      } else {
        if (this.localInsurances[insNdx2].s30BalDue == '1') {
          this.cms.s30 = (parseFloat(this.cms.s28) - parseFloat(this.cms.s29)).toFixed(2);
        }
      }

      let bdy = {
        "template":
        {
          "name": "/cms1500/cms1500-html"
        }, "data": { "cms": this.cms },
        "options": { "reports": { "save": true } }
      };

      this.postRenderJsRpt(bdy).subscribe({
        next: (blob: Blob) => this.backFromPrintJsRpt(blob),
        error: (err: any) => this.backFromServerError(err)
      });
    }
  }

  backFromServerError(error): void {
    this.showToast((this.engLang ? 'Error receiving data' : 'Error recibiendo data'), error.message, true, true, false, false, false);
  }

  backFromPrintJsRpt(blob: Blob): void {  // Succesfully back from jsReports
    // Add the Blob to the queue
    // console.log('blob', blob);
    this.blobQueue.push(blob);
    console.log('blobQueue.length', this.blobQueue.length);

    if (this.blobQueue.length === this.lastPrintPg) {
      this.processQueue();  // Go print another page
    }
    // Set submitted date in case
    let q = "Exec spMB_Web_Save_SubmittedDt @sn = '" + this.sn
      + "', @subDtCasId = '" + this.casePrinting.casID
      + "', @subDtPSP = '" + this.casePrinting.ps
      + "', @subDtDateTime = '" + moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
      + "', @subDtUserId = '" + this.userID
      + "', @subDtType = '" + this.casePrinting.typ
      + "';";
    console.log('%c' + 'q @ backFromPrintJsRpt:' + q, 'color: black; background: #90EE90; font-size: 12px');
    this._websocketService.sendChat('query', this.sn, q);
  }

  set1500formType(type): void {
    this.cms.s1_medicare = '';
    this.cms.s1_medicaid = '';
    this.cms.s1_tricare = '';
    this.cms.s1_champva = '';
    this.cms.s1_grouphealth = '';
    this.cms.s1_feca = '';
    this.cms.s1_other = '';

    switch (type) {
      case '1':
        this.cms.s1_medicare = 'X';
        break;
      case '2':
        this.cms.s1_medicaid = 'X';
        break;
      case '3':
        this.cms.s1_tricare = 'X';
        break;
      case '4':
        this.cms.s1_champva = 'X';
        break;
      case '5':
        this.cms.s1_grouphealth = 'X';
        break;
      case '6':
        this.cms.s1_feca = 'X';
        break;
      case '7':
        this.cms.s1_other = 'X';
        break;
      default:
        this.cms.s1_other = 'X';
        break;
    }
  }

  private async processQueue(): Promise<void> {
    const win = window.open('', '_blank');
    win.document.open();
    win.document.write('<html><head><title>CMS 1500</title></head><body>');

    this.blobQueue.forEach((blob, index) => {
      const url = URL.createObjectURL(blob);
      // win.document.write(`
      //   <iframe 
      //     src="${url}" 
      //     width="100%" 
      //     height="350px" 
      //     frameborder="0" 
      //     style="margin-bottom: 20px;">
      //   </iframe>
      // `);
      // URL.revokeObjectURL(url); // Clean up after writing

      const iframe = win.document.createElement('iframe');
      iframe.src = url;
      iframe.width = '100%';
      iframe.height = this.iFrameHt;
      iframe.onload = () => {
        URL.revokeObjectURL(url); // Revoke only after loading
      };
      win.document.body.appendChild(iframe);
    });

    win.document.write('</body></html>');
    win.document.close();
    this.spinner4Print1 = false;
    this.spinnerPat = false;
    this.blobQueue = [];
  }

  fmtZip(z): string {
    return z.length > 5 ? z.substr(0, 5) + '-' + z.substr(5).replace('-', '') : z;
  }

  printDemographics(pat: IPatients, site: ISite, patImg: any) {
    this.blobQueue = [];
    this.lastPrintPg = 1; // Only 1 page for this report
    this.iFrameHt = '100%';
    let data: any = {
      "pat": pat,
      "siteNm": site.nm,
      "siteAd1": site.ad1,
      "siteAd2": site.ad2,
      "siteCt": site.ct,
      "siteSt": site.st,
      "siteZp": site.zp,
      "siteTl1": site.tl1,
      "siteXt1": site.xt1,
      "siteTl2": site.tl2,
      "siteXt2": site.xt2,
      "today": this._help.todaysDt(4)
    };
    data.pat.id1 = patImg.id1;
    data.pat.id2 = patImg.id2;

    let bdy = {
      "template":
      {
        "name": "/demographics/demographics-html"
      }, "data": data,
      "options": { "reports": { "save": true } }
    };

    this.spinnerPat = true;
    this.postRenderJsRpt(bdy).subscribe(
      {
        next: (blob: Blob) => this.backFromPrintJsRpt(blob),
        error: (err: any) => this.backFromServerError(err)
      }
    );
  }

  printImg(imgId, s, w, h): void {
    this.blobQueue = [];
    this.lastPrintPg = 1; // Only 1 page for this report
    this.iFrameHt = '100%';
    if (imgId) {
      let e = <HTMLImageElement>document.getElementById(imgId);
      if (e) {
        s = e.src;
        w = e.width < 9 ? '100%' : e.width;
        h = (w == '100%' ? 'auto' : e.height);
      } else {
        return;
      }
    } else {
      w = (w < 9 ? '100%' : w);
      h = (w == '100%' ? 'auto' : h);
    }

    let data: any = {
      "src": s,
      "width": w,
      "height": h,
    };
    let bdy = {
      "template":
      {
        "name": "/image/image-html"
      }, "data": data,
      "options": { "reports": { "save": true } }
    };

    this.postRenderJsRpt(bdy).subscribe({
      next: (blob: Blob) => this.backFromPrintJsRpt(blob),
      error: (err: any) => this.backFromServerError(err)
    });
  }

  showToast(head: string, body: string, ctr: boolean, err: boolean, warn: boolean, succ: boolean, autohide: boolean): void {
    this._toastService.updateDeadCenter(ctr);
    this._toastService.show(
      body,
      {
        header: (head),
        autohide: autohide,
        warning: warn,
        success: succ,
        error: err
      }
    );
  }

  showMsg(msgBoxMessage, msgBoxTitle): void {
    this._modalService.message(msgBoxMessage
      , msgBoxTitle
      , undefined
      , undefined
      , undefined
      , undefined
      , this.engLang
      , undefined
      , undefined
      , undefined
      , undefined).pipe(
        take(1) // take() manages unsubscription for us
      ).subscribe(response => {
        if (response) { // response = undefined if Esc was pressed, False if closed with upper rt hand x
        }
      });
  }

  decodeDxPtr(ptr): string {
    if (ptr >= '1' && ptr <= '9') {
      return String.fromCharCode(64 + parseInt(ptr));
    }
    switch (ptr) {
      case 'a':
        return 'J';
      case 'b':
        return 'K';
      case 'c':
        return 'L';
      default:
        return ptr + '?';
    }
  }

}
