import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, inject, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ReactiveFormsModule, ValidationErrors, Validators } from '@angular/forms';
import { ValidatorFn } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { ILocalSubmitters } from '../interfaces/submitter';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ILocalProviders } from '../interfaces/localProviders';
import { ILocalInsurances } from '../interfaces/localInsurances';
import { X12UtilsService } from '../x12-utils.service';
import { WebsocketService } from '../websocket.service';
import { Subscription } from 'rxjs';
import { Json2Pipe } from '../json2.pipe';
import { RecordService } from '../../record/record.service';
import { ILocalFormConfig } from '../interfaces/localFormConfig';
import { DataViewDebugComponent } from '../data-view-debug/data-view-debug.component';
import { debug } from 'console';
import { AppToastsService } from '../app-toasts/app-toasts.service';

@Component({
  selector: 'app-submitter',
  imports: [
    CommonModule,
    MatDialogModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubmitterComponent implements OnInit {
  @Input() prov: string = '';
  @Input() ins: string = '';
  @Input() sub: ILocalSubmitters;

  readonly _dialog = inject(MatDialog);
  private _recordService = inject(RecordService)

  constructor() { }

  ngOnInit() {
    const dialogRef = this._dialog.open(SubmitterDialog, {
      data: {
        sn: this._recordService.sn,
        prov: this.prov,
        ins: this.ins,
        sub: this.sub,
      },
      disableClose: true,
      autoFocus: true
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Qparams Dialog result: ${result}`);
      this._recordService.showSubmitter = false;
      if (result) {
      }
    });
  }

}

export function isaIdQualifierValidator(control: AbstractControl): ValidationErrors | null {
  const validValues = ['01', '14', '20', '27', '28', '29', '30', '33', 'ZZ'];
  const value = control.value?.toUpperCase(); // Without .toUpperCase() a keyboard input of ZZ yields an error

  // Check if the value is in the validValues array
  if (validValues.includes(value)) {
    return null;  // Return null if the value is valid (no error)
  }

  return { invalidIsaQualifier: true, validValues: validValues.join(', ') };  // Return error if the value is not valid
}

export function optionalPhoneValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (!value) {
      // Allow null or empty values
      return null;
    }
    // Validate 10-digit phone numbers
    return /^\d{10}$/.test(value) ? null : { invalidPhone: true };
  };
}

@Component({
  selector: 'submitter-dialog',
  templateUrl: './submitter.dialog.html',
  styleUrls: ['./submitter.dialog.css'],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatTooltipModule,
    ReactiveFormsModule,
    Json2Pipe,
    DataViewDebugComponent
  ],
  providers: [X12UtilsService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubmitterDialog {

  private _x12Service = inject(X12UtilsService);
  private _recordService = inject(RecordService);
  private _websocketService = inject(WebsocketService);
  private _appToastsService = inject(AppToastsService);

  subIndx: number = -1;  // Selected subs[] index
  fcSubID: string; // Integer when provID & insID linked to sub
  evenBackgColrP = this._recordService.stylesCfg.provNpi_DDn_backgEvenColr;
  oddBackgColrP = this._recordService.stylesCfg.provNpi_DDn_backgOddColr;
  evenBackgColrI = this._recordService.stylesCfg.insPayerId_DDn_backgEvenColr;
  oddBackgColrI = this._recordService.stylesCfg.insPayerId_DDn_backgOddColr;
  provs: ILocalProviders[] = this._recordService.localProviders;
  plans: ILocalInsurances[] = this._recordService.localInsurances;
  subs: ILocalSubmitters[] = this._recordService.localSubmitters;
  subsUniq: any[] = [];
  fc: ILocalFormConfig[] = this._recordService.localConfig
  engLang: boolean = this._recordService.engLang;
  formEdited: boolean = false;
  formCopy: any = {};
  sioSubscrpt: Subscription;

  debugLabel: string;
  debugData: any;
  debugComp: boolean;

  emptyForm: any = {
    "SubID": null,
    "SubVia": null,
    "SubLastNm": null,
    "SubFstNm": null,
    "SubIDrem": null,
    "SubIsa05": null,
    "SubIsa08": null,
    "SubIsa07": null,
    "SubGs02": null,
    "SubGs03": null,
    "SubContact": null,
    "SubContTel": null,
    "SubContFax": null,
    "SubContEmail": null,
    "SubUser": null,
    "SubPw": null,
    "provSelVal": '0',
    "insSelVal": '0'
  }

  // SELECT box data
  provSelArr: any[] = [];
  insSelArr: any[] = [];

  form = this._fb.group({
    SubID: [this.data.sub?.SubID],
    SubVia: [this.data.sub?.SubVia, [
      Validators.required,
      Validators.maxLength(35)
    ]
    ],
    SubLastNm: [this.data.sub?.SubLastNm, [
      Validators.required,
      Validators.maxLength(35)]
    ],
    SubFstNm: [this.data.sub?.SubFstNm,
    [Validators.maxLength(25)]
    ],
    SubIDrem: [this.data.sub?.SubIDrem, [
      Validators.required,
      Validators.maxLength(15)]
    ],
    SubIsa05: [this.data.sub?.SubIsa05, [
      Validators.required,
      isaIdQualifierValidator]
    ],
    SubIsa08: [this.data.sub?.SubIsa08, [
      Validators.required,
      Validators.maxLength(15)]
    ],
    SubIsa07: [this.data.sub?.SubIsa07, [
      Validators.required,
      isaIdQualifierValidator]
    ],
    SubGs02: [this.data.sub?.SubGs02, [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(15)]
    ],
    SubGs03: [this.data.sub?.SubGs03, [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(15)]
    ],
    SubContact: [this.data.sub?.SubContact, [
      Validators.required,
      Validators.maxLength(34)]
    ],
    SubContTel: [this.data.sub?.SubContTel.trim(), [
      Validators.required,
      optionalPhoneValidator()]
    ],
    SubContFax: [this.data.sub?.SubContFax.trim(), [
      optionalPhoneValidator()]
    ],
    SubContEmail: [this.data.sub?.SubContEmail, [
      Validators.maxLength(50),
      Validators.email]
    ],
    SubUser: [this.data.sub?.SubUser,
    [Validators.maxLength(20)]
    ],
    SubPw: [this.data.sub?.SubPw,
    [Validators.maxLength(20)]
    ],
    provSelVal: ['0'],  // Initially set at Providers
    insSelVal: ['0']    // Initially set at Plans
  }, { updateOn: 'blur' });

  get SubID() {
    return this.form.get('SubID');
  }

  get SubVia() {
    return this.form.get('SubVia');
  }

  get SubIsa05() {
    return this.form.get('SubIsa05');
  }

  get SubIsa07() {
    return this.form.get('SubIsa07');
  }

  get SubIsa08() {
    return this.form.get('SubIsa08');
  }

  get SubGs02() {
    return this.form.get('SubGs02');
  }

  get SubGs03() {
    return this.form.get('SubGs03');
  }

  get SubContTel() {
    return this.form.get('SubContTel');
  }

  get SubContFax() {
    return this.form.get('SubContFax');
  }

  get SubUser() {
    return this.form.get('SubUser');
  }

  get SubPw() {
    return this.form.get('SubPw');
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data:
      {
        sn: string,
        prov: string,
        ins: string,
        sub: ILocalSubmitters
      },
    private _fb: FormBuilder,
    private _cdRef: ChangeDetectorRef
  ) {

    this.matSelectData();

    this.sioSubscrpt = this._websocketService.getMessages().subscribe(dataSet => { // Sets listenning events

      if (dataSet[0]?.sqlProcNm === 'spMB_Sio_SaveSubmitter') {
        const sub = { ...dataSet[0] }
        // Delete props not in ILocalSubmitters
        delete sub.forID;
        delete sub.provID;
        delete sub.insID;

        const s: number = this._recordService.localSubmitters.findIndex(s => s.SubID === sub.SubID);
        if (s > -1) {
          this._recordService.localSubmitters[s] = sub;
        } else {
          this._recordService.localSubmitters.push(sub);
        }
        this.matSelectData();

        let fc: ILocalFormConfig;
        if (+dataSet[0].SubID) {
          fc = this._recordService.localConfig.find(f => {
            return +f.forID && +f.forID === +dataSet[0].forID;
          });

          const n: number = this._recordService.localConfig.findIndex(fc => fc.provID === sub.provSelVal && fc.insID === sub.insSelVal);
          if (fc) {
            fc.subID = dataSet[0].SubID;  // Update locally cached values
            fc.provID = dataSet[0].provID ? dataSet[0].provID : '0';
            fc.insID = dataSet[0].insID ? dataSet[0].insID : '0';
          } else {
            fc = {
              'forID': dataSet[0].forID,
              'provID': dataSet[0].provID ? dataSet[0].provID : '0',
              'insID': dataSet[0].insID ? dataSet[0].insID : '0',
              'name': '',
              'pin': '',
              'clia': '',
              'mamoCert': '',
              'x2': '',
              'npi': '',
              'ssnId': '',
              'einId': '',
              'add1': '',
              'add2': '',
              'city': '',
              'st': '',
              'zip': '',
              'acode': '',
              'tel': '',
              'omitNm182': '0',
              'altNpi': '',
              'subID': dataSet[0].SubID
            }
            this._recordService.localConfig.push(fc);
          }
          console.log('fc:', fc);

          const i: number = this._recordService.localSubmitters.findIndex(s => s.SubID === dataSet[0].SubID);
          if (i < 0) {  // Not found so insert
            this._recordService.localSubmitters.push(sub);
          } else {  // Equate
            this._recordService.localSubmitters[i] = sub;
          }

          this.form.controls.SubID.setValue(dataSet[0].SubID);
          this.form.controls.provSelVal.setValue(dataSet[0].provID ? dataSet[0].provID : '0');
          this.form.controls.insSelVal.setValue(dataSet[0].insID ? dataSet[0].insID : '0');
          this.formCopy = { ...this.form.value };
          this._cdRef.detectChanges(); // Force UI update or values in this.formCopy will not persist
          this.formEdited = !this.deepEqual(this.formCopy, this.form.value);
          this.showToast(this.engLang ? 'Saved' : 'Grabado', '', true, true, false, false);
        }

      } else if (dataSet[0]?.sqlProcNm === 'spMB_Sio_DeleteSubmitter') {
        if (+dataSet[0].SubID && +dataSet[0].rowsDeleted === 1) {
          const i: number = this._recordService.localSubmitters.findIndex(s => s.SubID === dataSet[0].SubID);
          if (i > -1) {
            this._recordService.localSubmitters.splice(i, 1);
            this.matSelectData();
            this.onClick_new();
            this.showToast(this.engLang ? 'Deleted' : 'Eliminado', '', true, true, false, false);

          }
        }
      }
    });

    this.provSelArr = this.provs.map(prov => ({
      alias: prov.alias,
      pKey: prov.pKey
    }));

    this.form.get('provSelVal')?.valueChanges.subscribe(value => {
      console.log('provSelVal:', value);
    });

    this.insSelArr = this.plans
      .filter(ins => {
        return Number(ins.pKey) > 0;
      })
      .map(ins => ({
        alias: ins.alias,
        pKey: ins.pKey
      }));

    this.form.get('insSelVal')?.valueChanges.subscribe(value => {
      console.log('insSelVal:', value);
    });

    this.form.markAllAsTouched();  // Marks all controls as touched to show validation messages
    this.form.updateValueAndValidity(); // Ensures the form updates its status and validators

    this.form.valueChanges.subscribe(value => {
      this.formEdited = !this.deepEqual(this.formCopy, this.form.value);
    });

    if (!this.data.sub && this.data.prov && this.data.ins) { // Preselect prov & ins select boxes
      this.form.controls.provSelVal.setValue(this.data.prov);
      this.form.controls.insSelVal.setValue(this.data.ins);
    }
  }

  deepEqual(obj1, obj2) {
    return JSON.stringify(obj1, Object.keys(obj1).sort()) === JSON.stringify(obj2, Object.keys(obj2).sort());
  }

  onClick_save() {
    const result = { form: this.form.value };
    this._x12Service.saveSubmitter(result);
  }

  onClick_delete() {
    this._x12Service.deleteSubmitter(this.form.value.SubID);
    this.onClick_new();
  }

  onClick_unlink(event) {
    this.form.controls.provSelVal.setValue('0');
    this.form.controls.insSelVal.setValue('0');
    this.showToast(this.engLang ? 'Unlinked' : 'Desligado', this.engLang ? 'Provider/Plan/Submitter unlinked. Finish by Saving.' : 'Proveedor/Plan/Submitter desligados. Finalize Grabando.', false, true, false, false);
  }

  onClick_new() {
    this.form.setValue(this.emptyForm);
    this.formCopy = { ...this.form.value };
  }

  onChange_subID(event) {
    if (event) {
      this.SubContTel.updateValueAndValidity(); // Needed or a false error may be displayed when changing data
      this.SubContFax.updateValueAndValidity();

      const i = this.subs.findIndex(e => e.SubID === event);
      if (Number(i) > -1) {
        this.form.controls.SubID.setValue(this.subs[i].SubID);
        this.form.controls.SubVia.setValue(this.subs[i].SubVia);
        this.form.controls.SubLastNm.setValue(this.subs[i].SubLastNm);
        this.form.controls.SubFstNm.setValue(this.subs[i].SubFstNm);
        this.form.controls.SubIsa05.setValue(this.subs[i].SubIsa05);
        this.form.controls.SubIDrem.setValue(this.subs[i].SubIDrem);
        this.form.controls.SubIsa07.setValue(this.subs[i].SubIsa07);
        this.form.controls.SubIsa08.setValue(this.subs[i].SubIsa08);
        this.form.controls.SubGs02.setValue(this.subs[i].SubGs02);
        this.form.controls.SubGs03.setValue(this.subs[i].SubGs03);
        this.form.controls.SubContact.setValue(this.subs[i].SubContact);
        this.form.controls.SubContTel.setValue(this.subs[i].SubContTel);
        this.form.controls.SubContFax.setValue(this.subs[i].SubContFax);
        this.form.controls.SubContEmail.setValue(this.subs[i].SubContEmail);
        this.form.controls.SubUser.setValue(this.subs[i].SubUser);
        this.form.controls.SubPw.setValue(this.subs[i].SubPw);
        if (!+this.form.value.provSelVal && !+this.form.value.insSelVal) {
          this.form.controls.provSelVal.setValue(this.subs[i].provSelVal ? this.subs[i].provSelVal : '0');
          this.form.controls.insSelVal.setValue(this.subs[i].insSelVal ? this.subs[i].insSelVal : '0');
        }
        this.subIndx = i;
      }
      // this.formCopy = { ...this.form.value };
      this.formEdited = !this.deepEqual(this.formCopy, this.form.value);

      // this.debugLabel = "Result from spMB_Web_SaveSubmitter";
      // this.debugData = { fc: this.fc, subs: this.subs };
      // this.debugComp = false;
    }
  }

  toUpperCase(event: any) {
    event.target.value = event.target.value.toUpperCase();
  }

  onClick_inmediata() {
    const isa06 = '660610220'
    this.form.controls.SubIsa05.setValue('30');
    this.form.controls.SubIsa07.setValue('30');
    this.form.controls.SubIsa08.setValue(isa06);
    this.form.controls.SubGs02.setValue(isa06);
    this.form.controls.SubGs03.setValue(isa06);
  }

  showLink(): boolean { // Shows/hides provID, insID, subID link_off icon
    const fc = this.fc.find(f => +f.provID && +f.provID === +this.form.value.provSelVal && +f.insID && +f.insID === +this.form.value.insSelVal);
    if (fc) {
      return +fc.subID === +this.form.value.SubID;
    }
    return false;
  }

  matSelectData() {
    this.subsUniq = this.subs // 🎯 Reduce and remove duplicates from subs for mat-select
      .map(({ SubID, SubVia }) => ({ SubID, SubVia })) // Reduce the object
      .filter((value, index, self) =>
        index === self.findIndex((obj) =>
          obj.SubID === value.SubID && obj.SubVia === value.SubVia
        )
      );
  }

  showToast(head: string, msg: string, hide: boolean, success: boolean, warn: boolean, error: boolean) {
    this._appToastsService.updateDeadCenter(true);
    this._appToastsService.show(
      msg
      , {
        header: head,
        autohide: hide,
        warning: warn,
        success: success,
        error: error
      }
    );
  }

  //Inmetiata credentials:
  // Maria mtnz: U= Clinicad, P= Dr40cl
  // Juarbe : U= charlesj, P= Kakiss12
}
