import { Injectable } from '@angular/core';
import { ICase } from './interfaces/case';
import moment from 'moment';
import { ILocalFormConfig } from './interfaces/localFormConfig';
import { ILocalInsurances } from './interfaces/localInsurances';
import { ILocalProviders } from './interfaces/localProviders';
import { ILocalFacilities } from './interfaces/localFacilities';
import { WebsocketService } from './websocket.service';
import { IPatients } from './interfaces/patients';
import { HelperRtnsComponent } from './helper-rtns.component';
import { RecordService } from '../record/record.service';
import { IProc } from './interfaces/proc';
import { ILocalProductors } from './interfaces/localProductors';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { ILocalSubmitters } from './interfaces/submitter';

@Injectable()
export class X12UtilsService {
  sn: string;
  proc: string;
  postHeaders: any = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
  endOfLine: string = "~";
  n: string = '\n'; // New line
  delm: string = '>~>';
  icn: string;  // PAYER CLAIM CONTROL NUMBER (ICN)
  claimPos: string; // CLM05-1
  insNm: string; // insurance name

  s270: string;  // Holds one patients eligibility request

  errors: string[] = [];

  constructor(
    private _ws: WebsocketService,
    private _help: HelperRtnsComponent,
    private _recordService: RecordService,
    private _http: HttpClient,
  ) {
    this.sn = this._recordService.sn;
  }

  x837Pro(sn: string, sbr01: string, uID: string, cas: ICase, pat: IPatients, fc1: ILocalFormConfig, fc2: ILocalFormConfig, prov: ILocalProviders,
    ins1: ILocalInsurances, ins2: ILocalInsurances, fac: ILocalFacilities, prod: ILocalProductors, engLang: boolean): void {
    this.errors = [];
    const s837 = this.sbrSeg(sbr01, cas, ins1, ins2)
      + this.insuredNM1(sbr01, cas, pat, true, true, engLang)
      + this.payerNm(sbr01, ins1, ins2, engLang)
      + this.claimInfo(sbr01, cas, engLang)
      + this.admitDt(cas)
      + this.dischDt(cas)
      + this.patDedAmntPd(sbr01, cas)
      + this.mammoCertNo(sbr01, fc1, fc2)
      + this.referalNo(sbr01, cas, engLang)
      + this.priorAuth(cas)
      + this.icn4correction()
      + this.cliaNo(sbr01, fc1, fc2, prov, engLang)
      + this.icd10Codes(cas, engLang)
      + this.referringMD(sbr01, cas, engLang)
      + this.renderingProv(sbr01, cas, prov, fc1, fc2, ins1, ins2, prod, engLang)
      + this.facility(sbr01, cas, fac, engLang)
      + this.otherSbrSeg(sbr01, cas, pat, ins1, ins2, engLang)
      + this.serviceLinesPro(sbr01, cas, ins1, ins2, engLang)

    this.save2q837(sn, s837, uID, cas.casID, pat.patID, sbr01, cas.casDat, cas.casProvID, (sbr01 === 'P' ? cas.casI1ID : cas.casI2ID));
    console.log('x837Pro', s837);
    console.log('errors', this.errors);
  }

  // ISA*00*          *00*          *30*584384448      *30*660610220      *240923*2105*^*00501*000000026*1*P*:~
  // GS*HS*660610220*660610220*20240923*2105*1*X*005010X279A1~
  // ST*270*000000026*005010X279A1~
  // BHT*0022*13*100000000*20240923*2105~
  // HL*1**20*1~
  // NM1*PR*2*MEDICARE*****PI*00973~
  // HL*2*1*21*1~
  // NM1*1P*1*SOBRINO CATONI*JOSE****XX*1174602064~
  // HL*3*2*22*0~
  // TRN*1*000000000000017372*1174602064~
  // NM1*IL*1*AYUSO BENITEZ*ROSALIA****MI*7U63G89ER78~
  // DMG*D8*19380118~
  // DTP*291*D8*20240923~
  // EQ*30~
  // SE*13*000000026~
  // GE*1*1~
  // IEA*1*000000026~
  x270Eligib(ps: string, prv: ILocalProviders, ins: ILocalInsurances, pat: IPatients, cas: ICase, engLang: boolean) {
    const tdt = new Date();
    const st02 = moment(tdt).format('YYMMDDHHmmss');  // ST02: A unique transaction set control number, which matches the SE02 (the corresponding segment at the end of the transaction).
    let cdt = moment(cas.casDat, 'MM/DD/YYYY').format('YYYYMMDD');
    const dt = tdt.getFullYear().toString() + (1 + tdt.getMonth()).toString().padStart(2, '0') + tdt.getDate().toString().padStart(2, '0');
    const tm = tdt.getHours().toString().padStart(2, '0') + tdt.getMinutes().toString().padStart(2, '0');

    this.s270 = 'ST*270*' + st02 + '*005010X279A1~'
      + 'BHT*0022*13*00000000*' + dt + '*' + tm + this.endOfLine
      + 'HL*1**20*1' + this.endOfLine
      + this.payerNm(ps, ins, null, engLang)
      + 'HL*2*1*21*1' + this.endOfLine
      + this.provNm(prv, '1P')
      + 'HL*3*2*22*0' + this.endOfLine
      + 'TRN*1*' + pat.patID.padStart(18, '0') + '*' + prv.npi + this.endOfLine
      + this.insuredNM1(ps, cas, pat, false, true, engLang)
      + 'DTP*291*D8*' + cdt + this.endOfLine
      + 'EQ*30' + this.endOfLine
      + 'SE*__*' + st02 + this.endOfLine;

    const cnt = this.s270.match(/~/gm).length;
    this.s270 = this.s270.replace(/__/gm, cnt.toString());
    this.s270 = this.s270.replace(/\n/gm, '');
  }

  sbrSeg(sbr01: string, cas: ICase, ins1: ILocalInsurances, ins2: ILocalInsurances) {
    const grp: string = (sbr01 === 'P' ? cas.casGrp1 : cas.casGrp2);
    const cfi: string = (sbr01 === 'P' ? (ins1.x837clmFilInd ? ins1.x837clmFilInd : undefined) : (ins2.x837clmFilInd ? ins2.x837clmFilInd : undefined));
    const ID: string = (sbr01 === 'P' ? cas.casI1ID : cas.casI2ID);

    if (!cfi) {
      this.logErr('Claim Filing Indicator Code plan (ID local = ' + ID + ') (Loop 2000B - SBR09)')
    }
    return this.n + 'SBR*' + sbr01 + '*18*' + grp + '******' + cfi + this.endOfLine;
  }

  insuredNM1(ps: string, cas: ICase, pat: IPatients, addr: boolean, dob: boolean, engLang: boolean): string { // Decides if using plan ID card name from cas to submit
    let p: {
      last: string,
      first: string,
      mid: string,
      contr: string
    } = { last: '', first: '', mid: '', contr: '' };
    if (ps === 'P' || ps === '1') { // Primary claim
      if (cas.casILastIns1.trim()) {
        p.last = cas.casILastIns1.substring(0, 60);
        p.first = cas.casIFirstIns1.substring(0, 35);
        p.mid = cas.casIMidIns1.substring(0, 25);
      }
      p.contr = cas.casCont1.replace(/\s/g, '');
    } else {  // Secondary claim
      if (cas.casILastIns2.trim()) {
        p.last = cas.casILastIns2.substring(0, 60);
        p.first = cas.casIFirstIns2.substring(0, 35);
        p.mid = cas.casIMidIns1.substring(0, 25);
      }
      p.contr = cas.casCont2;
    }
    if (!p.last) { // Not using cas insurance ID card names so use record's data
      p.last = pat.lastNm;
      p.first = pat.firstNm;
      p.mid = pat.midInit;
    }

    // const nm1il = this.n + 'NM1*IL*1*' + p.last + '*' + p.first + '*' + p.mid + '***MI*' + p.contr + this.endOfLine
    //   + (addr ? this.n + 'N3*' + pat.add1 + (pat.add2 ? '*' + pat.add2 : '') + this.endOfLine : '')
    //   + (addr ? this.n + 'N4*' + pat.city + '*' + pat.st + '*' + pat.zip + this.endOfLine : '')
    //   + (dob ? this.n + 'DMG*D8*' + this.yyyyMMdd(pat.dob, 'MM/DD/YYYY') + '*' + pat.sex + this.endOfLine : '');

    // if (this.nm1Error('IL', nm1il)) {
    //   this.logErr((engLang ? "Patient-Insured: last/first name, plan contract no." : "Paciente-Asegurado: nombre/apellidos, no. contrato plan") + ' (Loop 2010BA)');
    // }
    // if (this.n3Error(nm1il)) {
    //   this.logErr((engLang ? "Patient-Insured: street address" : "Paciente-Asegurado: dirección calle") + ' (Loop 2010BA)');
    // }
    // if (this.n4Error(nm1il)) {
    //   this.logErr((engLang ? "Patient-Insured: city, state, zip (must have 9 digits)" : "Paciente-Asegurado: ciudad, estado, zip (que tenga 9 dígitos)") + ' (Loop 2010BA)');
    // }
    // if (!moment(pat.dob, 'MM/DD/YYYY').isValid) {
    //   this.logErr((engLang ? 'Patient-Insured: birthdate' : 'Paciente-Asegurado: nacimiento')) + ' (Loop 2010BA)';
    // }
    // if (!pat.sex.match(/^[MF]$/g)) {
    //   this.logErr((engLang ? 'Patient-Insured: gender (M/F)' : 'Paciente-Asegurado: sexo (M/F)') + ' (Loop 2010BA)');
    // }

    return this.n + 'NM1*IL*1*' + p.last + '*' + p.first + '*' + p.mid + '***MI*' + p.contr + this.endOfLine
      + (addr ? this.n + 'N3*' + pat.add1 + (pat.add2 ? '*' + pat.add2 : '') + this.endOfLine : '')
      + (addr ? this.n + 'N4*' + pat.city + '*' + pat.st + '*' + pat.zip + this.endOfLine : '')
      + (dob ? this.n + 'DMG*D8*' + this.yyyyMMdd(pat.dob, 'MM/DD/YYYY') + '*' + pat.sex + this.endOfLine : '');
  }

  yyyyMMdd(dt: string, fmt: string): string {
    return moment(dt, fmt).format('YYYYMMDD');
  }

  payerNm(ps: string, ins1: ILocalInsurances, ins2: ILocalInsurances, engLang: boolean): string { // ps = 'P' for claims, or '1' for eligib when primary or 'S' / '2'
    let insNm: string;
    let insPayID: string;
    if (ps === 'P' || ps === '1') { // Primary plan
      insNm = ins1.name;
      insPayID = ins1.payerId;
    } else {  // Secondary plan
      insNm = ins2.name;
      insPayID = ins2.payerId;
    }
    const nm1pr = this.n + 'NM1*PR*2*' + insNm + '*****PI*' + insPayID + this.endOfLine;

    // if (this.nm1Error('PR', nm1pr)) {
    //   this.logErr((engLang ? 'Plan/payer: name, payer ID' : 'Plan/pagador: nombre, payer ID') + ' (Loop 2010BB - NM109)');
    // }
    return nm1pr;
  }

  claimInfo(ps: string, cas: ICase, engLang: boolean): string {
    let totUsual: number = 0;
    let freq: string = '1';  // Claim frequency - default to original claim submission

    if (ps === 'P') { // Primary plan
      cas.procs1.forEach(p => { totUsual += +p.usual * +p.qty });
      if (cas.procs1?.length) {
        this.claimPos = cas.procs1[0].pos;
      }
      if (cas.pays?.length) {
        this.icn = cas.pays.find(p => p.payPS === 'S1')?.payApCode;  // Should be the last icn received
      }
    } else {  // Secondary plan
      totUsual = 0;
      cas.procs2.forEach(p => { totUsual += +p.usual * +p.qty });
      if (cas.procs2?.length) {
        this.claimPos = cas.procs2[0].pos;
      }
      if (cas.pays?.length && cas.pays.some(p => p.payPS === 'S2')) {
        this.icn = cas.pays.find(p => p.payPS === 'S2').payApCode;  // Should be the last icn received
      }
    }
    if (this.icn) {  // If there is a payment then claim must be a correction, otherwise freq defaults to '1' (original claim)
      freq = '7';
    }
    // if (!this.claimPos) {
    //   this.logErr((engLang ? 'Claim Place of Service: CLM05-1 (comes from first service line)' : 'Lugar del Servicio del caso: CLM05-1 (derivado de la primera línea de servicio)') + ' (Loop 2300)');
    // }
    return this.n + "CLM*" + cas.casNo + cas.casOfNo + "*" + totUsual.toFixed(2) + "***" + this.claimPos + ":B:" + freq + "*Y*A*Y*Y*P" + this.endOfLine;
  }

  admitDt(cas: ICase): string {
    let momDt = moment(cas.casAdmDt, 'MM/DD/YYYY');
    if (momDt.isValid()) {
      return this.n + 'DTP*435*D8*' + momDt.format('YYYYMMDD') + this.endOfLine;
    }
    return '';
  }

  dischDt(cas: ICase): string {
    if (cas.casDiscDt) {
      let momDt = moment(cas.casDiscDt, 'MM/DD/YYYY');
      if (moment(cas.casAdmDt).isValid()) {
        return this.n + 'DTP*096*D8*' + momDt.format('YYYYMMDD') + this.endOfLine;
      }
    }
    return '';
  }

  patDedAmntPd(ps: string, cas: ICase): string {
    const dedPS = (ps === 'P' ? 'D1' : 'D2');
    let totDed: number = 0;
    cas.pays.forEach(p => {
      if (p.payPS === dedPS) {
        totDed += +p.payAmnt;
      }
    });
    if (+totDed > cas.casChgP) {  // Guard against over reporting pvdo amount paid
      totDed = +cas.casChgP;
    }
    if (totDed) {
      return this.n + 'AMT*F5*' + totDed.toFixed(2) + this.endOfLine;
    }
    return '';
  }

  mammoCertNo(ps: string, fc1: ILocalFormConfig, fc2: ILocalFormConfig): string {
    if (ps === 'P') {
      if (fc1?.mamoCert) {
        return this.n + 'REF*EW*' + fc1.mamoCert + this.endOfLine;
      }
    } else {
      if (fc2?.mamoCert) {
        return this.n + 'REF*EW*' + fc2.mamoCert + this.endOfLine
      }
    }
    return '';
  }

  referalNo(ps: string, cas: ICase, engLang: boolean): string {
    const req: boolean = this.referalReq(ps, cas);
    if (req || cas.casRefNo) {
      if (!cas.casRefNo) {
        this.logErr((engLang ? 'Referral no.' : 'No. de referencia') + ' (Loop 2300)');
      }
      return this.n + 'REF*9F*' + cas.casRefNo + this.endOfLine;
    } else {
      return '';
    }
  }

  referalReq(ps: string, cas: ICase): boolean {
    let procs: IProc[];
    if (ps === 'P') {
      procs = cas.procs1;
    } else {
      procs = cas.procs2;
    }
    for (let i = 0; i < procs.length; i++) {
      return this._recordService.localPcodes.some(p => p.ref === 'True' && cas.procs1[i].code === p.code && cas.casProvID === p.provId && cas.casI1ID === p.insId);
    }
  }

  priorAuth(cas: ICase): string {
    if (cas.casAuthNo) {
      return this.n + 'REF*G1*' + cas.casAuthNo + this.endOfLine
    }
    return '';
  }

  icn4correction(): string { // PAYER CLAIM CONTROL NUMBER (ICN)
    if (this.icn) {
      return this.n + 'REF*F8*' + this.icn + this.endOfLine
    }
    return '';
  }

  cliaNo(ps: string, fc1: ILocalFormConfig, fc2: ILocalFormConfig, prov: ILocalProviders, engLang: boolean): string {  // Clinical Laboratory Improvement Amendment (CLIA) Number
    let clia: string;
    if (ps === 'P') { // Primary claim
      if (fc1?.clia) {
        clia = fc1.clia ? fc1.clia : undefined;
      }
    } else {  // Secondary claim
      if (fc2?.clia) {
        clia = fc2.clia ? fc2.clia : undefined;
      }
    }
    if (!clia && +prov.lab) {
      this.logErr('Clinical Laboratory Improvement Amendment (CLIA) Number (Loop 2300)');
    } else {
      if (clia) {
        return this.n + 'REF*X4*' + clia + this.endOfLine;
      } else {
        return '';
      }
    }
  }

  icd10Codes(cas: ICase, engLang: boolean): string {
    let dx: string = '';
    cas.dxs.forEach(d => {
      if (!dx) {
        dx = 'HI*ABK:' + d.code.replace(/[^A-Z0-9]*/g, '');
      } else {
        dx += '*ABF:' + d.code.replace(/[^A-Z0-9]*/g, '');
      }
    });
    if (!dx) {
      this.logErr((engLang ? 'Missing ICD10 codes' : 'Faltan códigos ICD10') + ' (Loop 2300)');
    }
    return this.n + dx + this.endOfLine;
  }

  referringMD(ps: string, cas: ICase, engLang: boolean): string {
    let reqRef: boolean = this.referalReq(ps, cas);
    if (reqRef || +cas.casRefID) {
      let ref: string;
      let npi: string = cas.casRefNPI ? cas.casRefNPI : undefined;
      const last = cas.casRefLastNm ? cas.casRefLastNm : undefined;
      const first = cas.casRefFirstNm;
      if (first) {
        ref = 'NM1*DN*1*' + last + '*' + first  // Person
      } else {
        ref = 'NM1*DN*2*' + last + '**' // Non person
      }
      const nm1dn = this.n + ref + '****XX*' + npi + this.endOfLine;
      if (this.nm1Error('DN', nm1dn)) {
        this.logErr((engLang ? 'Referring: Name/NPI referring MD' : 'Médico referente: Nombre/NPI') + ' (Loop 2310A)');
      }
      return nm1dn;
    }
    return '';
  }

  renderingProv(ps: string, cas: ICase, prov: ILocalProviders, fc1: ILocalFormConfig, fc2: ILocalFormConfig, ins1: ILocalInsurances, ins2: ILocalInsurances, prod: ILocalProductors, engLang: boolean): string {
    let omitNm182: boolean = false; // To omit segment NM1*82...
    let usePrvNm185: boolean = false;
    let npi: string = prov.npi; // Use as default provider NPI
    let nm182: string = this.n;
    if (ps === 'P') { // Primary claim
      omitNm182 = +fc1?.omitNm182 ? true : false;
      usePrvNm185 = +ins1.x837usePrvAsRenderAt2310B ? true : false;
      npi = fc1?.altNpi ? fc1.altNpi : npi;
    } else {  // Secondary claim
      omitNm182 = +fc2?.omitNm182 ? true : false;
      usePrvNm185 = +ins2.x837usePrvAsRenderAt2310B ? true : false;
      npi = fc2?.altNpi ? fc2.altNpi : npi;
    }
    let last: string;
    if (prov.provName.includes(',')) {  // Person
      last = prov.provName.match(/^[\w\s]*,/g)[0].replace(',', '');
    } else {
      last = prov.provName;
    }
    let taxo: string = prov.provTaxonomy;

    if (!omitNm182 && !+prov.lab) {
      let ini: string;
      if (usePrvNm185) {
        nm182 += 'NM1*82*1*' + prov.provName.replace(/, /g, '*') + '*' + prov.provName.match(/ \w*$/g).toString()
          + this.n + 'PRV*PE*PXC*' + prov.provTaxonomy + this.endOfLine;
      } else {
        if (!prod && !+prov.provIsCo && !cas.procs1.some(p => +p.prod.replace(/\D*/g, '') > 0)) {
          ini = (prov.provName.match(/ \w$/g) ? prov.provName.match(/ \w$/g).toString() : '').trim();
          nm182 += 'NM1*82*1*' + (prov.provName.replace(/, /g, '*')).replace(/ \w{1}$/g, '') + '*' + ini + '***XX*' + (npi ? npi : undefined) + this.endOfLine
            + this.n + 'PRV*PE*PXC*' + prov.provTaxonomy + this.endOfLine;
        } else {
          ini = (prod.prodName.match(/ \w$/g) ? prod.prodName.match(/ \w$/g).toString() : '').trim();
          nm182 += 'NM1*82*1*' + (prod.prodName.replace(/, /g, '*')).replace(/ \w{1}$/g, '') + '*' + ini + '***XX*' + (prod.npi ? prod.npi : undefined) + this.endOfLine
            + this.n + 'PRV*PE*PXC*' + prod.taxo + this.endOfLine;
          taxo = prod.taxo;
        }
      }
      return nm182;
    } else {
      return '';
    }
  }

  billingProvider(prov: ILocalProviders, engLang: boolean): string {
    const c: number = prov.provName.indexOf(',') === -1 ? prov.provName.length : prov.provName.indexOf(',');
    const lname = prov.provName.substring(0, c);
    const fname = prov.provName.substring(c + 1).trim();
    let ad1: string = this.n + 'N3*' + prov.provAd1 + (prov.provAd2 ? '*' + prov.provAd2 : '') + this.endOfLine;

    const nm185 = this.n + 'NM1*85*' + (+prov.provIsCo ? '2' : '1') + '*' + lname + '*' + fname + '****XX*' + prov.npi + this.endOfLine
      + ad1
      + this.n + 'N4*' + prov.provCity + '*' + prov.provSt + '*' + prov.provZip.replace(/\D/g, '') + this.endOfLine
      + this.n + 'REF*' + (+prov.ssn ? 'SY' : 'EI') + '*' + prov.taxId.replace(/\D/g, '') + this.endOfLine;

    if (this.nm1Error('85', nm185)) {
      this.logErr((engLang ? 'Provider: first/last name, NPI' : 'Proveedor: nombre/apellidos, NPI') + ' (Loop 2010AA)');
    }

    if (prov.poAd1) {
      ad1 = '';
      return nm185 + this.n + 'NM1*87*' + (c ? '2' : '1') + this.endOfLine
        + this.n + 'N3*' + prov.poAd1 + (prov.poAd2 ? '*' + prov.poAd2 : '') + this.endOfLine
        + this.n + 'N4*' + prov.poCity + '*' + prov.poSt + '*' + prov.poZip + this.endOfLine
    }

    if (ad1 && /P(\.\s*|\s*)O(\.\s*|\s*)BOX/.test(ad1)) {
      this.logErr((engLang ? "Provider: 'PO BOX' not allowed in physical address" : "Proveedor: 'PO BOX' no permitido en direccón física") + ' (Loop 2010AA)');
    }
    if (this.n3Error(nm185)) {
      this.logErr((engLang ? 'Provider: street address' : 'Proveedor: dirección calle') + ' (Loop 2010AA)');
    }
    if (this.n4Error(nm185)) {
      this.logErr((engLang ? 'Provider: city, state, zip (must have 9 digits)' : 'Proveedor: ciudad, estado, zip (que tenga 9 dígitos)') + ' (Loop 2010AA)');
    }
    return nm185;
  }

  facility(ps: string, cas: ICase, fac: ILocalFacilities, engLang: boolean): string {
    let reqFac: boolean = false;
    if (ps === 'P') {
      if (cas.procs1.some(p => p.fac)) {
        reqFac = true;
      }
    } else {
      if (cas.procs2.some(p => p.fac)) {
        reqFac = true;
      }
    }

    const nm177 = this.n + 'NM1*77*2*' + fac.facName + '*****XX*' + fac.facNpi + this.endOfLine
      + this.n + 'N3*' + fac.facAd1 + (fac.facAd2 ? '*' + fac.facAd2 : '') + this.endOfLine
      + this.n + 'N4*' + fac.facCity + '*' + fac.facSt + '*' + fac.facZip + this.endOfLine

    if (reqFac || +cas.casFacID) {
      if (this.nm1Error('77', nm177)) {
        this.logErr((engLang ? 'Hospital/Facility: name, NPI' : 'Hospital/Facilidad: nombre, NPI') + ' (Loop 2010BB)');
      };
      if (this.n3Error(nm177)) {
        this.logErr((engLang ? 'Hospital/Facility: street address' : 'Hospital/Facilidad: dirección calle') + ' (Loop 2010BB)');
      }
      if (this.n4Error(nm177)) {
        this.logErr((engLang ? 'Hospital/Facility: city, state, zip (must have 9 digits)' : 'Hospital/Facilidad: ciudad, estado, zip (que tenga 9 dígitos)' + ' (Loop 2010BB)'));
      }
      return nm177;
    }
    return '';
  }

  otherSbrSeg(ps: string, cas: ICase, pat: IPatients, ins1: ILocalInsurances, ins2: ILocalInsurances, engLang: boolean): string {
    if (+cas.casI2ID && !+cas.casPrivacy) {
      let cfi: string;  // Claim Filing Indicator
      let grp: string;
      let oID: string;  // Other ins local ID
      let oInm: string; // Other ins name
      let oPid: string; // Other ins payer ID
      let priSec: string = (ps === 'P' ? 'secondary' : 'primary');
      let sbr: string = this.n;
      if (ps === 'P' && +cas.casI2ID) { // Submitting primary with a secondary
        cfi = ins2.x837clmFilInd ? ins2.x837clmFilInd : undefined;
        grp = cas.casGrp2;
        oID = cas.casI2ID;  // Use in case of error
        oInm = ins2.name;
        oPid = ins2.payerId;
        sbr += 'SBR*S*18*' + grp + '******' + cfi + this.endOfLine;
      } else if (ps === 'S' && +cas.casI1ID) {  // Submitting secondary with a primary & payment
        cfi = ins1.x837clmFilInd ? ins1.x837clmFilInd : undefined;
        grp = cas.casGrp1;
        oID = cas.casI1ID;  // Use in case of error
        oInm = ins2.name;
        oPid = ins2.payerId;
        sbr += 'SBR*P*18*' + grp + '******' + cfi + this.endOfLine;
        sbr += this.n + 'AMT*D*' + parseFloat(cas.casPay1.toString()).toFixed(2) + this.endOfLine;
      }
      sbr += this.n + 'OI***N*P**Y' + this.endOfLine
        + this.insuredNM1((ps === 'P' ? 'S' : 'P'), cas, pat, false, false, engLang)
        // + this.n + 'NM1*PR*2*' + oInm + '*****PI*' + oPid + this.endOfLine
        + this.payerNm((ps === 'P' ? 'S' : 'P'), ins1, ins2, engLang);

      if (!cfi) {
        this.logErr('Claim Filing Indicator Code other (' + priSec + ') insurance (ID local = ' + oID + ') (Loop 2000B)')
      }
      if (!oPid) {
        this.logErr('Payer ID other (' + priSec + ') insurance (ID local = ' + oID + ') (Loop 2000B)')
      }
      return sbr;
    }
    return '';
  }

  serviceLinesPro(ps: string, cas: ICase, ins1, ins2, engLang: boolean): string {
    let sv: string = '';
    let cnt: number = 1;
    let casPCodes: IProc[];
    let insID: string;
    let primPayDetAmnt: string;
    let primPayDetDt: string;

    if (ps === 'P') {
      casPCodes = cas.procs1;
      insID = cas.casI1ID;
    } else {
      casPCodes = cas.procs2;
      insID = cas.casI2ID;
    }

    casPCodes.forEach(p => {
      if (p.code && p.code !== 'AJUSTE' && !p.desc.startsWith('AJUSTE') && +p.ps === (ps === 'P' ? 1 : 2)) {
        sv += this.n + 'LX*' + cnt.toString() + this.endOfLine + this.n + 'SV1*HC:';
        let noDesc: boolean = true; // Default
        let momFrom = moment(p.fromDt, 'MM/DD/YYYY');
        let dtFrom: string = '';
        if (momFrom.isValid) {
          dtFrom = momFrom.format('YYYYMMDD');
        }
        let momTo = moment(p.toDt, 'MM/DD/YYYY');
        let dtTo: string = '';
        if (momTo.isValid) {
          dtTo = momTo.format('YYYYMMDD');
        }

        let modCnt: number = 0;

        sv += p.code;
        if (p.mod1) {
          sv += ':' + p.mod1;
          modCnt++;
        }
        if (p.mod2) {
          sv += ':' + p.mod2;
          modCnt++;
        }
        if (p.mod3) {
          sv += ':' + p.mod3;
          modCnt++;
        }
        if (p.mod4) {
          sv += ':' + p.mod4;
          modCnt++;
        }

        noDesc = !this._recordService.localPcodes.some(pc => pc.code === p.code && pc.insId === insID && pc.provId === cas.casProvID && !+pc.noDesc);
        if (!noDesc) {
          switch (modCnt) {
            case 0:
              sv += ':::::';
              break;
            case 1:
              sv += '::::';
              break;
            case 2:
              sv += ':::';
              break;
            case 3:
              sv += '::';
              break;
            default:
              break;
          }
          if (!p.desc) {
            this.logErr((engLang ? 'Procedure description required' : 'Descripción procedimineto requerida') + ' ' + p.code + ' (Loop 2400)');
          }
          sv += p.desc;
        }

        let amnt: string;
        if (+p.usual * +p.qty < +p.xpect * +p.qty) {
          amnt = (+p.xpect * +p.qty).toFixed(2);
        } else {
          amnt = (+p.usual * +p.qty).toFixed(2);
        }
        if (+amnt <= 0) {
          this.logErr((engLang ? 'Billed amount for ' + p.code + ' = 0.00' : 'Cantidad usual sometida para ' + p.code + ' = 0.00') + ' (Loop 2400)');
        }

        sv += '*' + (+p.usual * +p.qty).toFixed(2) + '*UN*' + parseFloat(p.qty) + '*';
        if (this.claimPos !== p.pos) {
          sv += p.pos;
        }
        sv += '**' + p.dx + this.endOfLine
          + this.n + 'DTP*472*RD8*' + dtFrom + '-';
        if (!dtTo) {
          sv += dtFrom;
        } else {
          if (momFrom.isAfter(momTo)) {
            this.logErr((engLang ? 'Service date From > To' : 'Fecha servicio Desde > Hasta') + ' (Loop 2400)');
          }
          sv += dtTo + this.endOfLine;
        }
        sv += this.n + 'REF*6R*' + p.detID + this.endOfLine;  // Line Item Control Number

        if (ps === 'S' && !+cas.casPrivacy) {
          const det = cas.procs1.find(proc => proc.code === p.code && proc.fromDtYy === p.fromDtYy && +proc.ps === 1);
          if (det) {  // Corresponding primary procedure row
            if (det.code !== p.code || det.fromDt !== p.fromDt || +det.qty !== +p.qty) {
              const em = (engLang ? 'CPT, from date and/or qty do not match those in primary plan.' : 'CPT, fecha desde y/o cantidad no concuerdan con plan primario.') + ' ' + p.code + ', ' + p.fromDtYy + ', ' + p.qty;
              this.logErr(em);
            }

            let acc: number = 0;  // Accumulate a total
            cas.pays.forEach(py => {
              if (+py.payDetID === +det.detID && py.payPS === 'S1') {
                acc += +py.payAmnt;  // Sum of payments if more than 1 for this procedure
                primPayDetAmnt = acc.toFixed(2);

                const momDt = moment(py.payDt, 'MM/DD/YYYY');
                if (momDt.isValid) {
                  primPayDetDt = momDt.format('YYYYMMDD');
                }
              }
            });
          };
          if (!primPayDetAmnt) {
            this.logErr((engLang ? 'No primary payment found for' : 'No existe pago primario para') + ' ' + p.code + ', ' + p.fromDtYy + ' (Loop 2400)');
          }
        }

        if (!+p.noNTE || p.noDesc || p.reclReason) {  // When p.noDesc inhibits proc description in SV... segment add it in NTE segment unless there's a reclaim reason
          let nte: string = p.reclReason ? p.reclReason : p.desc; // Prioritize reclaimReason
          if (!nte) {
            this.logErr('NTE segment (Loop 2400)');
          }
          if (p.reclReason) {
            sv += this.n + 'NTE*ADD*' + p.reclReason + this.endOfLine
          } else if (+p.noNTE) {
            sv += this.n + 'NTE*ADD*' + p.desc.trim() + this.endOfLine
          }
        }

        const ndcs = this._recordService.localPcodes.filter(pc => { return +pc.rx && p.code === pc.code && cas.casProvID === pc.provId && insID === pc.insId });
        ndcs.some(n => {
          let ndc: string = p.rxNDC.replace(/\D*/g, '');
          if (+n.rx && p.code === n.code && cas.casProvID === n.provId && insID === n.insId && (ndc.length === 10 || ndc.length === 11)) {
            sv += this.n + 'LIN**N4*' + ndc + this.endOfLine
              + this.n + 'CTP****' + p.rxQty + '*' + p.rxUM + this.endOfLine;
            if (p.rxNo) {
              this.n + 'REF*XZ*' + p.rxNo + this.endOfLine;
            }
            return true;  // To break out of array.some loop
          }
          this.logErr('National Drug Code (Loop 2410)');
        });

        if (ps === 'S' && !+cas.casPrivacy) { // Loop 2430 - LINE ADJUDICATION INFORMATION
          sv += this.n + 'SVD*' + ins1.payerId + '*' + primPayDetAmnt + '*HC:' + p.code;
          if (p.mod1) {
            sv += ':' + p.mod1;
          }
          if (p.mod2) {
            sv += ':' + p.mod2;
          }
          if (p.mod3) {
            sv += ':' + p.mod3;
          }
          if (p.mod4) {
            sv += ':' + p.mod4;
          }
          sv += '**' + parseFloat(p.qty) + this.endOfLine;

          let casPR3: number = +p.xpect * +p.qty;  // - LINE ADJUSTMENT
          if (casPR3 >= .01) {   // Include this segment only if casPR3 is non zero or clearing house will reject claim
            sv += this.n + 'CAS*PR*3*' + casPR3.toFixed(2) + this.endOfLine;  // Without this segment the claim goes through without errors but it is not payed
          }

          sv += this.n + 'CAS*CO*45*' + ((+p.usual * +p.qty) - +primPayDetAmnt - casPR3).toFixed(2) + this.endOfLine;
          sv += this.n + 'DTP*573*D8*' + primPayDetDt + this.endOfLine; // - LINE ADJUDICATION DATE
        }

        cnt++;
      }
    });
    if (!sv) {
      this.logErr(engLang ? 'No procedure codes found' : 'Ningún código de procedimientos encontrado');
    }
    return sv;
  }

  provNm(prv: ILocalProviders, qual: string) {
    if (prv.provIsCo) {
      return 'NM1*' + qual + '*2*' + prv.provName + '*****XX*' + prv.npi + this.endOfLine
    } else {
      return 'NM1*' + qual + '*1*' + prv.provName.match(/^\w+/g)[0] + '*' + prv.provName.match(/\w+$/g)[0] + '****XX*' + prv.npi + this.endOfLine
    }
  }

  isaGsEnvelope(sub: ILocalSubmitters, momDt: any, isa13: string, insNm: string, insPayID: string, typ: string, isaTestFlag: string): string {
    const yyyymody: string = moment(momDt).format('YYYYMMDD'); // ISA09 - Interchange Date
    const yrmody: string = moment(momDt).format('YYMMDD'); // ISA09 - Interchange Date
    const hrmi: string = moment(momDt).format('HHmm'); // ISA10 - Interchange Time
    const isa: string = `ISA*00*          *00*          *${sub.SubIsa05}*${(sub.SubIDrem + ' '.repeat(15)).substring(0, 15)}*${sub.SubIsa07}*${(sub.SubIsa08 + ' '.repeat(15)).substring(0, 15)}*${yrmody}*${hrmi}*^*00501*${isa13}*1*${isaTestFlag}*:~`
    let gs01: string;
    let gs08: string;
    let perCo: string = sub.SubFstNm ? '1' : '2';  // 1=person, 2=company
    const te: string = sub.SubContTel ? `*TE*${sub.SubContTel}` : '';
    const fx: string = sub.SubContFax ? `*FX*${sub.SubContFax}` : '';
    const em: string = sub.SubContEmail ? `*EM*${sub.SubContEmail}` : '';

    if (typ === '837P') {  // Professional claim
      gs01 = 'HC';
      gs08 = '005010X222A1';

      return isa
        + this.n + `GS*${gs01}*${sub.SubGs02}*${sub.SubGs03}*20${yrmody}*${hrmi}*1*X*${gs08}~`
        + this.n + `ST*837*${isa13}*${gs08}~`
        + this.n + `BHT*0019*00*${isa13}*${yyyymody}*${hrmi}*CH~`
        + this.n + `NM1*41*${perCo}*${sub.SubLastNm}*${sub.SubFstNm}****46*${sub.SubIDrem}~`
        + this.n + `PER*IC*${sub.SubContact}${te}${fx}${em}~`
        + this.n + `NM1*40*2*${insNm}*****46*${insPayID}~`
        + this.n + `HL*1**20*1~`
    }
    if (typ === '270') {  // Eligibility request
      gs01 = 'HS';
      gs08 = '005010X279A1';
      return isa
        + `GS*${gs01}*${sub.SubGs02}*${sub.SubGs03}*20${yrmody}*${hrmi}*1*X*${gs08}~`
    }
  }

  logErr(em: string) {
    if (!this.errors.includes(em)) {
      this.errors.push(em);
    }
  }

  nm1Error(eic: string, line: string): boolean { // EIC=Entity Identifier Code
    switch (eic) {
      case '85':
        return !/NM1\*85\*(1|2)\*[a-zA-Z0-9 ]+\*[a-zA-Z0-9 ]*\*\*\*\*XX\*\d{10}~/gi.test(line);
      case '82':
        return !/NM1\*82\*(1|2)\*[a-zA-Z0-9 ]+\*[a-zA-Z0-9 ]*\*\*\*\*XX\*\d{10}~/gi.test(line);
      case '77':
        return !/NM1\*77\*2\*[a-zA-Z0-9 ]+\*\*\*\*\*XX\*\d{10}~/gi.test(line);
      case 'IL':
        return !/NM1\*IL\*1\*[-a-zA-Z ]{1,60}\*[a-zA-Z ]{1,35}\*[a-zA-Z]*\*\*\*MI\*[-a-zA-Z0-9 ]{1,80}~/gi.test(line);
      case 'PR':
        return !/NM1\*PR\*2\*[!"&'()*+,-.\/:;?=a-zA-Z0-9 ]{1,60}\*\*\*\*\*PI\*[-a-zA-Z0-9 ]+~/gi.test(line);
      case 'DN':
        return !/NM1\*DN\*(1|2)\*[a-zA-Z ]+\*[a-zA-Z ]*\*\*\*\*\*XX\*\d{10}~/gi.test(line);
      default:
        this.logErr('########## PROGRAM ERROR: Could not match parameter eic with value: ' + eic + ' in line: ' + line + ' ##########');
        return false;
    }
  }

  n3Error(line): boolean {
    return !/N3\*[-.a-zA-Z0-9# ]+\*[.a-zA-Z0-9# ]*~/gi.test(line);
  }

  n4Error(line): boolean {
    return !/N4\*[a-zA-Z ]+\*[a-zA-Z]{2}\*\d{5,9}~/gi.test(line);
  }

  save2q837(sn: string, s837: string, uID: string, casID: string, patID: string, ps: string, casDt: string, provID: string, insID: string): void { // Updates [Q837] table
    this.errors = this.x837errors(s837, provID, insID);
    const sErrs = this.errors.map(s => s).reduce((acc, s) => acc + s + this.delm, '');
    const cDt = moment(casDt, 'MM/DD/YYYY').format('YYYY-MM-DD');
    const q = "Exec spMB_Sio_Save2Q837 @sn = '" + sn
      + (s837 ? "', @s837 = '" + this._help.escApos(s837) : "")
      + "', @sErrs = '" + this._help.escApos(sErrs)
      + "', @uID = '" + uID
      + "', @patID = '" + patID
      + "', @casID = '" + casID
      + "', @ps = '" + ps
      + "', @casDt = '" + cDt
      + "', @provID = '" + provID
      + "', @insID = '" + insID
      + "';";

    console.log('%c' + 'query @ save2q837:' + q, 'color: black; background: #90EE90; font-size: 12px');
    this._ws.sendChat('query', sn, q);
  }

  // Sample xml envelope from Fiddler
  // <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/"><s:Header><h:AuthenticationHeader xmlns:h="https://www.inmediata.com/ws/EdiRealTime/" xmlns="https://www.inmediata.com/ws/EdiRealTime/" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema"><Username>sobrinoc</Username><Password>Sc0190</Password></h:AuthenticationHeader></s:Header><s:Body xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema"><GetEligibility xmlns="https://www.inmediata.com/ws/EdiRealTime/"><X12Data>ISA*00*          *00*          *30*584384448      *30*660610220      *240923*2105*^*00501*000000026*1*P*:~GS*HS*660610220*660610220*20240923*2105*1*X*005010X279A1~ST*270*000000026*005010X279A1~BHT*0022*13*100000000*20240923*2105~HL*1**20*1~NM1*PR*2*MEDICARE*****PI*00973~HL*2*1*21*1~NM1*1P*1*SOBRINO CATONI*JOSE****XX*1174602064~HL*3*2*22*0~TRN*1*1000000000000017372*1174602064~NM1*IL*1*AYUSO BENITEZ*ROSALIA****MI*7U63G89ER78~DMG*D8*19380118~DTP*291*D8*20240923~EQ*30~SE*13*000000026~GE*1*1~IEA*1*000000026~</X12Data></GetEligibility></s:Body></s:Envelope>
  getInmeEligib(sn: string, usr: string, pw: string, x12Data: string): Observable<string> {
    return this._http.get<string>(this._help.urlDB + '/api/get-inmeEligib/' + sn + '/' + encodeURIComponent(usr) + '/' + encodeURIComponent(pw) + '/' + encodeURIComponent(x12Data))
      .pipe(
        catchError(err => this.handlrHttpError(err)));
  }

  sendInmeX837(sn: string, usr: string, pw: string, fil: string, x12Data: string): Observable<string> {
    const momDt = moment(new Date());
    const dt = momDt.format('MM/DD/YYYY HH:mm:ss');
    const data = x12Data.replace(/\n/g, '');
    return this._http.get<string>(this._help.urlDB + '/api/get-inmeSendX12File/' + sn + '/' + encodeURIComponent(usr) + '/' + encodeURIComponent(pw) + '/' + encodeURIComponent(fil) + '/' + encodeURIComponent(dt) + '/' + encodeURIComponent(data))
      .pipe(
        catchError(err => this.handlrHttpError(err)));
  }

  private handlrHttpError(error: HttpErrorResponse): Observable<any> {
    const errObj = {
      displayMsg: error.status.toString() + ' ' + error.statusText,
      msg: error.message
    }

    this.postApiErrorLog(this.sn, errObj, this.proc)
      .subscribe({
        next: (data: any) => console.log(data),
        error: (err: any) => console.error(err)
      });
    return throwError(() => errObj);
  }

  postApiErrorLog(SN: string, errObj: any, proc): Observable<any> {
    let errStr = JSON.stringify(errObj);
    let body = JSON.stringify({ SN, errStr, proc });

    return this._http.post<any>(this._help.urlDB + '/api/post-apiErrorLog', body, this.postHeaders);
  }

  delete837(sn: string, ps: string, casID: string, userID: string) {
    const q = "Exec spMB_Sio_DeleteFromQ837 @sn = '" + this.sn + "', @casID = '" + casID + "', @ps = '" + ps + "', @userID = '" + userID + "';";
    console.log('%c' + 'q @ delete837():' + q, 'color: black; background: #90EE90; font-size: 12px');
    this._ws.sendChat('query', this.sn, q);
  }

  chkUnchk837(sn: string, pKey: string) {
    const q = "Exec spMB_Sio_Q837ChkUnchk @sn = '" + this.sn + "', @pKey = '" + pKey + "';";
    console.log('%c' + 'q @ chkUnchk837():' + q, 'color: black; background: #90EE90; font-size: 12px');
    this._ws.sendChat('query', this.sn, q);
  }

  saveSubmitter(evnt: any) {
    const q = "Exec spMB_Sio_SaveSubmitter @sn = '" + this.sn
      + "', @subID = '" + (+evnt.form.SubID ? evnt.form.SubID : 0)
      + "', @subLastNm = '" + this._help.escApos(evnt.form.SubLastNm.toUpperCase())
      + "', @subFstNm = '" + this._help.escApos(evnt.form.SubFstNm ? evnt.form.SubFstNm.toUpperCase() : '')
      + "', @subIDrem = '" + this._help.escApos(evnt.form.SubIDrem)
      + "', @subContact = '" + this._help.escApos(evnt.form.SubContact.toUpperCase())
      + "', @subContTel = '" + this._help.escApos(evnt.form.SubContTel)
      + "', @subContFax = '" + this._help.escApos(evnt.form.SubContFax ? evnt.form.SubContFax : '')
      + "', @subContEmail = '" + this._help.escApos(evnt.form.SubContEmail ? evnt.form.SubContEmail : '')
      + "', @subIsa05 = '" + this._help.escApos(evnt.form.SubIsa05.toUpperCase())
      + "', @subIsa07 = '" + this._help.escApos(evnt.form.SubIsa07.toUpperCase())
      + "', @subIsa08 = '" + this._help.escApos(evnt.form.SubIsa08)
      + "', @subGs02 = '" + this._help.escApos(evnt.form.SubGs02)
      + "', @subGs03 = '" + this._help.escApos(evnt.form.SubGs03)
      + "', @subVia = '" + this._help.escApos(evnt.form.SubVia.toUpperCase())
      + "', @subUser = '" + this._help.escApos(evnt.form.SubUser ? evnt.form.SubUser : '')
      + "', @subPw = '" + this._help.escApos(evnt.form.SubPw ? evnt.form.SubPw : '')
      + "', @provID = '" + evnt.form.provSelVal
      + "', @insID = '" + evnt.form.insSelVal
      + "';"
    console.log('%c' + 'q @ saveSubmitter():' + q, 'color: black; background: #90EE90; font-size: 12px');
    this._ws.sendChat('query', this.sn, q);
  }

  deleteSubmitter(subID: string) {
    const q = "Exec spMB_Sio_DeleteSubmitter @sn = '" + this.sn
      + "', @subID = '" + subID
      + "';"
    console.log('%c' + 'q @ deleteSubmitter():' + q, 'color: black; background: #90EE90; font-size: 12px');
    this._ws.sendChat('query', this.sn, q);
  }

  create837file(sn: string, uID: string, params: any) {
    const provIDs: string = params.provsSelectCtrl.join(',');
    const insIDs: string = params.insesSelectCtrl.join(',');
    let q = "Exec spMB_Sio_DeleteFromQ837 @sn = '" + this.sn + "', @casID = '-1', @ps = '', @userID = '" + uID + "';";

    q += "Exec spMB_Sio_CreateQ837 @sn = '" + this.sn
      + "', @uID = '" + uID
      + "', @fromDt = '" + moment(params.fromDt).format('YYYYMMDD')
      + "', @toDt = '" + moment(params.toDt).format('YYYYMMDD') + "'"
      + (provIDs ? ", @provIDs = '" + provIDs + "'" : "")
      + (insIDs ? ", @insIDs = '" + insIDs + "'" : "")
      + ", @ckSubDt = " + (params.ckSubDt ? '1' : '0')
      + (params.subDt ? ", @subDt = '" + moment(params.subDt).format('YYYYMMDD') + "'" : "")
      + ";"
    console.log('%c' + 'q @ create837file():' + q, 'color: black; background: #90EE90; font-size: 12px');
    this._ws.sendChat('query', this.sn, q);
  }

  x837errors(s837: string, provID: string, insID: string): string[] {
    this.errors = [];
    const sbr = s837.match(/SBR\*\w\*/gm);
    let cpts: { cpt: string, mods: string, usual: string, qty: string, dxptrs: string, pos: string }[] = [];
    let dts: { from: string, to: string }[] = [];
    let ps: string; // Primary (P) or Secondary (S)
    let cpos: string;  // Claim POS

    if (sbr) {
      ps = sbr[0].replace('SBR*', '').replace('*', '');
      console.log('ps', ps);
    } else {
      this.logErr(this._recordService.engLang ? 'No SBR*... segment found' : 'No se encontró segmento SBR*... alguno');
      return this.errors;
    }

    const cfis = /SBR\*(?:P|S)\*18\*\*\*\*\*\*\*(\w{2}~)/gm.exec(s837);
    if (cfis) {
      if (!cfis[1]) {
        this.logErr('Claim Filing Indicator insurance/seguro (' + (ps === 'P' ? 'primary/primario' : 'secondary/secundario') + ' (Loop 2000B - SBR09)');
      } else {
        const g = /SBR\*(P|S)\*/gm.exec(s837);
        if (!cfis || g.length !== cfis.length) {  // No cfi entered or two insurances in claim but not two cfis
          this.logErr('Claim Filing Indicator insurance/seguro (primary/primario o secondary/secundario (Loop 2000B - SBR09)');
        }
      }
    } else {
      this.logErr('Claim Filing Indicator insurance/seguro (primary/primario o secondary/secundario (Loop 2000B - SBR09)');
    }

    if (!/NM1\*PR\*2\*[!"&'()*+,-.\/:;?=a-zA-Z0-9 ]{1,60}\*\*\*\*\*PI\*[-a-zA-Z0-9 ]{1,80}~/gi.test(s837)) {
      this.logErr(this._recordService.engLang ? 'Plan/payer: name, payer ID' : 'Plan/pagador: nombre, payer ID (Loop 2010BB - NM109)');
    }

    if (!/SV1\*\w{2}:\w{5}/gm.test(s837)) {
      this.logErr(this._recordService.engLang ? 'No procedure codes found' : 'No se encontró código de procedimiento alguno');
    }

    if (!/SV1\*\w{2}:\w{5}(:\w{0,2})?(:\w{0,2})?(:\w{0,2})?(:\w{0,2})?(:[!"&'()*+,-.\/:;?=a-zA-Z0-9 ]*)?\*\d*(\.\d{2})?\*\w{2}\*\d*(\.\d{0,2})?\*\w{0,2}\*\*\d(:\d)?(:\d)?(:\d)?~/gm.test(s837)) {
      this.logErr((this._recordService.engLang ? 'Error in procedure code line' : 'Error línea de procedimento') + ' (Loop 2310B)');
    } else {
      for (const m of s837.matchAll(/SV1\*\w{2}:(\w{5})?(:\w{0,2})?(:\w{0,2})?(:\w{0,2})?(:\w{0,2})?(:[!"&'()*+,-.\/:;?=a-zA-Z0-9 ]*)?\*(\d*(\.\d{2})?)?\*\w{2}\*(\d*(\.\d{0,2})?)?\*\w{0,2}\*\*(\d)?(:\d)?(:\d)?(:\d)?~/gm)) {
        if (+m[7] === 0 || +m[9] === 0) {
          this.logErr('CPT: ' + m[1] + (m[2] ? m[2] : '') + (m[3] ? m[3] : '') + (m[4] ? m[4] : '') + (m[5] ? m[5] : '') + ', Charge/cargo: ' + m[7] + ', Qty/cant: ' + m[9] + ' => ' + (this._recordService.engLang ? 'Billed amount nor Quantity can be zero' : 'Cargo Usual ni Cantidad puede ser zero'));
        } else {  // Get from dates
          for (const d of s837.matchAll(/DTP\*472\*RD8\*(\d{8})?-(\d{8})?~/gm)) {
            // console.log(`Full match: ${d[0]}`);
            // console.log(`1: ${d[1]}`);
            // console.log(`2: ${d[2]}`);
            dts.push({ from: d[1], to: d[2] });
          }
        }
        // console.log(`Full match: ${m[0]}`);
        // console.log(`cpt: ${m[1]}`);
        // console.log(`mod 1: ${m[2]}`);
        // console.log(`mod 2: ${m[3]}`);
        // console.log(`mod 3: ${m[4]}`);
        // console.log(`mod 4: ${m[5]}`);
        // console.log(`desc: ${m[6]}`);
        // console.log(`usual: ${m[7]}`);
        // console.log(`cents : ${m[8]}`);
        // console.log(`qty: ${m[9]}`);
        // console.log(`pos: ${m[10]}`);
        // console.log(`dxp1: ${m[11]}`);
        // console.log(`dxp2: ${m[12]}`);
        // console.log(`dxp3: ${m[13]}`);
        // console.log(`dxp4: ${m[14]}`);
        cpts.push({ cpt: m[1], mods: (m[2] ? m[2] : '') + (m[3] ? m[3] : '') + (m[4] ? m[4] : '') + (m[5] ? m[5] : ''), usual: m[7], qty: m[9], dxptrs: (m[11] ? m[11] : '') + (m[12] ? m[12] : '') + (m[13] ? m[13] : '') + (m[14] ? m[14] : ''), pos: (m[10] ? m[10] : '') });
      }
    }

    if (!/PRV\*PE\*PXC\*\w+~/gm.test(s837)) {
      this.logErr(this._recordService.engLang ? 'Claim Rendering MD Taxonomy Code (Loop 2310B)' : 'Código Taxonomy MD Productor (Loop 2310B)');
    }

    if (!/NM1\*IL\*1\*[-a-zA-Z ]{1,60}\*[a-zA-Z ]{1,35}\*[a-zA-Z]*\*\*\*MI\*[-a-zA-Z0-9 ]{1,80}~[\r\n]N3\*[-.a-zA-Z0-9# ]+\*[.a-zA-Z0-9# ]*~[\r\n]N4\*[a-zA-Z ]+\*[a-zA-Z]{2}\*\d{5,9}~/gm.test(s837)) {
      if (this.nm1Error('IL', s837)) {
        this.logErr((this._recordService.engLang ? "Patient-Insured: last/first, plan contract no." : "Paciente-Asegurado: nombre/apellidos, no. contrato plan") + ' (Loop 2010BA)');
      } else {
        this.logErr((this._recordService.engLang ? "Patient-Insured: street address, city, state, zip" : "Paciente-Asegurado: dirección calle, ciudad, estado, zip") + ' (Loop 2010BA)');
      }
    }

    if (!/DMG\*D8\*\d{8}\*[MF]{1}~/gm.test(s837)) {
      this.logErr(this._recordService.engLang ? 'Patient-Insured: Gender (M/F) (Loop 2310B)' : 'Paciente-Asegurado: Género (M/F) (Loop 2310B)');
    } else {
      for (const m of s837.matchAll(/DMG\*D8\*(\d{8})?\*[MF]{1}~/gm)) {
        const bdt = moment(m[1], 'YYYYMMDD');
        if (!bdt.isValid()) {
          this.logErr((this._recordService.engLang ? 'Patient-Insured: birthdate' : 'Paciente-Asegurado: nacimiento') + ' (Loop 2010BA)');
        }
        for (const dt of dts) {
          if (bdt.isAfter(moment(dt.from, 'YYYYMMDD'))) {
            this.logErr((this._recordService.engLang ? 'Patient-Insured: birthdate after FROM date' : 'Paciente-Asegurado: fecha fecha nacimiento después de fecha DESDE') + ' ' + moment(dt.from, 'YYYYMMDD').format('MM/DD/YY'));
          }
        }
      }
    }

    if (!/CLM\*\d+\*\d*(\.\d{2})?\*\*\*(\w{2})?:(B:\w)?\*Y\*A\*Y\*Y\*P~/gm.test(s837)) {
      this.logErr('Loop 2300 CLM Segment');
    } else {
      for (const m of s837.matchAll(/CLM\*\d+\*\d*(\.\d{2})?\*\*\*(\w{2})?:(B:\w)?\*Y\*A\*Y\*Y\*P~/gm)) {
        cpos = m[2];  // Claim POS
        if (!m[2] || m[2].length !== 2) {
          this.logErr((this._recordService.engLang ? 'Claim Place of Service' : 'Lugar de servicio de la reclamación ') + '(Loop 2300)');
        }
        const freq = m[3].replace('B:', '');
        if (freq === '7' && !/REF\*F8\*\w+/gm.test(s837)) {
          this.logErr(this._recordService.engLang ? 'Claim with plan payment without ICN needed for correction/dispute' : 'Caso con pago del plan sin ICN para corrección/disputa');
        }
        // console.log('0', m[0]);
        // console.log('1', m[1]);
        // console.log('2', m[2]);
        // console.log('freq', m[3]);
      }
    }

    let ad: any;  // Admit date
    if (/DTP\*435\*D8\*(\d{8})?~/gm.test(s837)) {
      for (const adm of s837.matchAll(/DTP\*435\*D8\*(\d{9})?~/gm)) {
        ad = moment(adm[1], 'YYYYMMDD');
        if (!ad.isValid()) {
          this.logErr((this._recordService.engLang ? 'Invalid Admit date' : 'Fecha admitido invalida') + ' (Loop 2300)');
          break;
        }
        for (const dt of dts) {
          if (ad.isAfter(moment(dt.from, 'YYYYMMDD'))) {
            this.logErr((this._recordService.engLang ? 'Admitted date after service date FROM' : 'Fecha admisión después de fecha de servicios DESDE') + ' (Loop 2300)');
            break;
          }
        }
      }
    } else {
      if (cpos === '21' || cpts.some(cp => cp.pos === '21')) {
        this.logErr((this._recordService.engLang ? 'No Admitted date for Place of Service 21' : 'No hay fecha de Admisión para Lugar de Servicio 21') + ' (Loop 2300)');
      }
    }

    let di: any;  // Discharge date
    if (/DTP\*096\*D8\*(\d{8})?~/gm.test(s837)) {
      for (const dis of s837.matchAll(/DTP\*096\*D8\*(\d{9})?~/gm)) {
        di = moment(dis[1], 'YYYYMMDD');
        if (!di.isValid()) {
          this.logErr((this._recordService.engLang ? 'Invalid Discharge date' : 'Fecha de alta invalida') + ' (Loop 2300)');
          break;
        } for (const dt of dts) {
          if (di.isBefore(moment(dt.to, 'YYYYMMDD'))) {
            this.logErr((this._recordService.engLang ? 'Discharge date before service date TO' : 'Fecha de alta antes de fecha de servicios DESDE') + ' (Loop 2300)');
            break;
          }
        }
      }

      if (ad && di) {
        if (ad.isAfter(di)) {
          this.logErr((this._recordService.engLang ? 'Admit date before discharge date' : 'Fecha de admisión después de fecha de alta') + ' (Loop 2300)');
        }
      }
    }

    if (!/HI\*ABK:\w{1,7}(\*ABF:\w{1,7})?(\*ABF:\w{1,7})?(\*ABF:\w{1,7})?(\*ABF:\w{1,7})?(\*ABF:\w{1,7})?(\*ABF:\w{1,7})?(\*ABF:\w{1,7})?(\*ABF:\w{1,7})?(\*ABF:\w{1,7})?(\*ABF:\w{1,7})?(\*ABF:\w{1,7})?~/gm.test(s837)) {
      this.logErr((this._recordService.engLang ? 'No ICD10 diagnostic code found' : 'No se encontró código de diagnósticos ICD10 alguno'));
    } else {
      let em: string;
      for (const dx of s837.matchAll(/HI\*ABK:(\w{1,7})?(\*ABF:\w{1,7})?(\*ABF:\w{1,7})?(\*ABF:\w{1,7})?(\*ABF:\w{1,7})?(\*ABF:\w{1,7})?(\*ABF:\w{1,7})?(\*ABF:\w{1,7})?(\*ABF:\w{1,7})?(\*ABF:\w{1,7})?(\*ABF:\w{1,7})?(\*ABF:\w{1,7})?~/gm)) {
        for (const cpt of cpts) {
          for (const dp of cpt.dxptrs.matchAll(/(\d)?/gm)) {
            if (+dp[0]) {
              if (!dx[+dp[0]].replace('ABF:', ''))
                em += this._recordService.engLang ? 'Dx pointer ' + dp + ' has no ICD10 code' : 'Puntero Dx ' + dp + ' no tiene código ICD10';
            }
          }
        }
      }

      if (em) {
        this.logErr(em);
      }
    }

    if (/REF\*9F\*[-\w ]~/gm.test(s837)) {
      // No need to ck if referral code is needed when it exist
    } else {
      for (const cp of cpts) {
        if (this._recordService.localPcodes.some(p => p.ref === 'True' && cp.cpt === p.code && provID === p.provId && insID === p.insId)) {
          this.logErr(`El procedimiento ${cp.cpt} requiere Número de Referido`);
          break;
        }
      }
    }

    return this.errors;
  }

}
