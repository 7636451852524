import { ChangeDetectionStrategy, Component, EventEmitter, Inject, inject, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { AbstractControl, FormBuilder, ReactiveFormsModule, ValidationErrors, Validators } from '@angular/forms';
import { Json2Pipe } from '../json2.pipe';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { X12UtilsService } from '../x12-utils.service';
import { RecordService } from '../../record/record.service'; // Ensure this path is correct
import moment from 'moment';

@Component({
  selector: 'app-qparams',
  imports: [
    CommonModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QparamsComponent {
  @Input() engLang: boolean;
  @Input() userID: string;
  @Input() fromDt: Date;
  @Input() toDt: Date;
  @Input() provs: IProvs[];
  @Input() lastUsedProvs: string[];
  @Input() inses: IInses[];
  @Input() lastUsedInses: string[];
  @Input() ckSubDt: boolean;
  @Input() subDt: Date;

  @Output() closeQparamsDlg: EventEmitter<any> = new EventEmitter();

  readonly dialog = inject(MatDialog);
  _recordService = inject(RecordService);

  constructor(private _x12Service: X12UtilsService
  ) { }

  ngOnInit() {

    const dialogRef = this.dialog.open(QparamsDialog, {
      data: {
        engLang: this.engLang,
        provs: this.provs,  // All provs
        lastUsedProvs: this.lastUsedProvs,
        inses: this.inses,  // All inses
        lastUsedInses: this.lastUsedInses,
        fromDt: this.fromDt,
        toDt: this.toDt,
        ckSubDt: this.ckSubDt ? true : false,
        subDt: this.subDt
      },
      disableClose: true,
      autoFocus: true
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log(`Qparams Dialog result: ${result}`);
      if (result) {
        this._x12Service.create837file(this._recordService.sn, this.userID, result.form);
      }
      this.closeQparamsDlg.emit(result);
    });
  }
}

@Component({
  selector: 'submitter-dialog',
  templateUrl: './qparams.dialog.html',
  styleUrls: ['./qparams.dialog.css'],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatTooltipModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTooltipModule,
    Json2Pipe
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QparamsDialog {

  @ViewChildren(MatSelect) matSelects!: QueryList<MatSelect>;

  form = this._fb.group({
    fromDt: [this.prepDt(this.data.fromDt), Validators.required],
    toDt: [this.prepDt(this.data.toDt), Validators.required],

    provIDs: [],
    provsSelectCtrl: [this.data.lastUsedProvs.split(','), Validators.required],

    insIDs: [],
    insesSelectCtrl: [this.data.lastUsedInses.split(','), Validators.required],

    ckSubDt: [this.data.ckSubDt],
    subDt: [this.prepDt(this.data.subDt)]
  }, {
    validators: [this.dateRangeValidator],
    updateOn: 'blur'
  });

  prepDt(dt): any {  // This needed of matpicker will display date - 1 day
    const dtMom: any = moment(dt, 'YYYY-MM-DD');
    if (dtMom.isValid()) {
      return new Date(dtMom.year(), dtMom.month(), dtMom.date());
    }
    return null;
  }

  get provIDs() {
    return this.form.get('provIDs');
  }

  get insIDs() {
    return this.form.get('insIDs');
  }

  get fromDt() {
    return this.form.get('fromDt');
  }

  get toDt() {
    return this.form.get('toDt');
  }

  get ckSubDt() {
    return this.form.get('ckSubDt');
  }

  pIDsToolTip: string;
  iIDsToolTip: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data:
      {
        engLang: boolean,
        prov: string,
        ins: string,
        provs: IProvs[],
        lastUsedProvs: string,
        inses: IInses[],
        lastUsedInses: string,
        fromDt: Date,
        toDt: Date,
        ckSubDt: boolean,
        subDt: string
      },
    private _fb: FormBuilder,
    private _dlgRef: MatDialogRef<QparamsDialog>
  ) {
    this.form.markAllAsTouched();  // Marks all controls as touched to show validation messages
    this.form.updateValueAndValidity(); // Ensures the form updates its status and validators
    this.pIDsToolTip = data.lastUsedProvs;
    this.iIDsToolTip = data.lastUsedInses;
  }

  onChange_params(event) {
    // console.log('event', event);
    let id = '';
    const selectedMatSelect = this.matSelects.toArray().find(m => m.id === event.source._id);

    if (selectedMatSelect) {
      if (event.value.includes('0')) {
        selectedMatSelect.options.forEach(option => {
          // console.log(`Value: ${option.value}, Selected: ${option.selected}`);
          if (+option.value > 0) {
            option.select();
            if (id) {
              id += ',';
            }
            id += option.value;
          } else {
            option.deselect();
          }
        });
      } else if (event.value.includes('-1')) {
        selectedMatSelect.options.forEach(option => {
          option.deselect();
          id = '';
        });
      } else {
        id = event.value.join(',');
      }
    }

    if (event.source._id === 'provs') {
      this.provIDs?.setValue(id);
      this.pIDsToolTip = id;
    } else if (event.source._id === 'inses') {
      this.insIDs?.setValue(id);
      this.iIDsToolTip = id;
    }
  }

  onChange_ckSubDt(event) {
    this.ckSubDt.setValue(event.checked);
  }

  onClick_continue() {
    const result = { form: this.form.value, action: 'continue' };
    this._dlgRef.close(result);
  }

  // Helper to check if the date range validation failed
  get isDateRangeInvalid() {
    return this.form.hasError('dateRangeInvalid');
  }

  // Custom validator to check that fromDt <= toDt
  dateRangeValidator(formGroup: AbstractControl): ValidationErrors | null {
    const fromDtMom = moment(formGroup.get('fromDt')?.value);
    const toDtMom = moment(formGroup.get('toDt')?.value);

    if (fromDtMom.isValid() && toDtMom.isValid() && fromDtMom.isAfter(toDtMom)) {
      return { dateRangeInvalid: true }; // Validation fails
    }
    return null; // Validation passes
  }

}


