<div class="mat-typography mat-app-background">

    <h2 mat-dialog-title>{{engLang ? 'Submitter Information (Clearing House or Insurance)' : 'Información del
        Remitente
        (Clearing House o Seguro)'}}</h2>

    <div [formGroup]="form">
        <div class="d-flex justify-content-between" style="margin: 0 45px 0 25px;">
            <div class="p-0 m-0">
                <select type="text" class="p-1 m-0 font12" style="width: 205px;" formControlName="provSelVal"
                    matTooltip="{{ +form.value.provSelVal ? form.value.provSelVal: '' }}" matTooltipPosition="above">
                    <option class="p-0 m-0 font12" [ngValue]="'0'" style="font-style: bold; background-color: #0078D7;">
                        {{engLang ?
                        'Providers':'Proveedores'}}</option>
                    <option class="p-0 m-0 font10" *ngFor="let prov of provSelArr; let e = even;" [ngValue]="prov.pKey"
                        [ngStyle]="{ 'color': '#000000', 'background-color': (e ? evenBackgColrP : oddBackgColrP) }">
                        {{prov.alias}}{{' (' + prov.pKey + ')'}}
                    </option>
                </select>
            </div>
            <div *ngIf="showLink()" class="align-content-center">
                <span class="material-symbols-outlined new pt-1" (click)="onClick_unlink($event)"
                    matTooltip="{{ engLang ? 'Unlink provider/plan/submitter' : 'Desligar proveedor/seguro/submitter' }}"
                    matTooltipPosition="above">
                    link_off
                </span>
            </div>
            <div class="p-0 m-0">
                <select type="text" class="p-1 m-0 font12" style="width: 205px;" formControlName="insSelVal"
                    matTooltip="{{ +form.value.insSelVal ? form.value.insSelVal: '' }}" matTooltipPosition="above">
                    <option class="p-0 m-0 font12" [ngValue]="'0'" style="font-style: bold; background-color: #0078D7;">
                        {{engLang ?
                        'Plans':'Planes'}}</option>
                    <option class="p-0 m-0 font10" *ngFor="let plan of insSelArr; let e = even;" [ngValue]="plan.pKey"
                        [ngStyle]="{ 'color': '#000000', 'background-color': (e ? evenBackgColrI : oddBackgColrI) }">
                        {{plan.alias}}{{' (' + plan.pKey + ')'}}
                    </option>
                </select>
            </div>
        </div>

        <div class="text-center">
            <mat-form-field class="select-width">
                <mat-label>{{engLang ? 'By way of and/or account name' : 'Vía y/o nombre de la
                    cuenta'}}</mat-label>
                <mat-select class="font15" placeholder="{{engLang ? 'Select Account' : 'Seleccione Cuenta'}}"
                    (selectionChange)="onChange_subID($event.value)" formControlName="SubID">

                    <mat-option *ngFor="let sub of subsUniq" [value]="sub.SubID">
                        {{sub.SubVia}}<span style="font-size: 8px;">{{' (' + sub.SubID + ')'}}</span>
                    </mat-option>

                </mat-select>
            </mat-form-field>
        </div>

        <mat-dialog-content class="pt-1" style="margin-top: 0">
            <div class="d-flex justify-content-between">
                <div class="label-div">
                    <mat-label>{{engLang ? 'Account name:' : 'Nombre de la cuenta:'}}</mat-label>
                </div>
                <div>
                    <mat-form-field>
                        <input matInput placeholder="Account name" formControlName="SubVia"
                            (input)="toUpperCase($event)">
                    </mat-form-field>
                    <mat-error *ngIf="SubVia.invalid && SubVia.touched">
                        <span *ngIf="SubVia.errors?.required">
                            {{engLang ? 'Ex': 'Ej'}}: Inmediata - Dr John Doe
                        </span>
                    </mat-error>
                </div>
            </div>

            <div class="d-flex justify-content-between">
                <div class="label-div">
                    <mat-label>{{engLang ? 'Last or practice name:' : 'Apellidos o nombre oficina:'}}</mat-label>
                </div>
                <div>
                    <mat-form-field>
                        <input matInput placeholder="Last/practice name" formControlName="SubLastNm"
                            (input)="toUpperCase($event)">
                    </mat-form-field>
                </div>
            </div>

            <div class="d-flex justify-content-between">
                <div class="label-div">
                    <mat-label>{{engLang ? 'First name if personal acct.:' : 'Nombre si cuenta
                        personal:'}}</mat-label>
                </div>
                <div class="align-items-end">
                    <mat-form-field>
                        <input matInput placeholder="First name if person" formControlName="SubFstNm"
                            (input)="toUpperCase($event)">
                    </mat-form-field>
                </div>
            </div>

            <div class="d-flex justify-content-between">
                <div class="label-div">
                    <mat-label>ISA06 - Interchange Sender ID (accnt. no.):</mat-label>
                </div>
                <div class="align-items-end">
                    <mat-form-field>
                        <input matInput placeholder="ISA06" formControlName="SubIDrem">
                    </mat-form-field>
                </div>
            </div>

            <div class="d-flex justify-content-between">
                <div class="label-div">
                    <mat-label>ISA05 - Interchange Sender ID Qualifier:</mat-label>
                    <button type="button" class="btn btn-link btn-sm" style="font-style: italic;"
                        (click)="onClick_inmediata()"
                        matTooltip="{{ engLang ? 'Input Inmediata values' : 'Entrar valores para Inmediata' }}"
                        matTooltipPosition="right">Inmediata</button>
                </div>
                <div>
                    <mat-form-field>
                        <input matInput placeholder="ISA05" formControlName="SubIsa05" maxlength="2"
                            (input)="toUpperCase($event)">
                    </mat-form-field>
                    <mat-error *ngIf="SubIsa05.invalid && SubIsa05.touched">
                        <span *ngIf="SubIsa05.errors?.invalidIsaQualifier">
                            {{SubIsa05.errors?.validValues}}
                        </span>
                    </mat-error>
                </div>
            </div>

            <div class="d-flex justify-content-between">
                <div class="label-div">
                    <mat-label>ISA08 - Interchange Receiver ID (CH no.):</mat-label>
                </div>
                <div>
                    <mat-form-field>
                        <input matInput placeholder="ISA08" formControlName="SubIsa08">
                    </mat-form-field>
                    <mat-error *ngIf="SubIsa08.invalid && SubIsa08.touched">
                        <span *ngIf="SubIsa08.errors?.required">
                            Inmediata = 660610220
                        </span>
                    </mat-error>
                </div>
            </div>

            <div class="d-flex justify-content-between">
                <div class="label-div">
                    <mat-label>ISA07 - Interchange Receiver ID Qualifier:</mat-label>
                </div>
                <div>
                    <mat-form-field>
                        <input matInput placeholder="ISA07" formControlName="SubIsa07" maxlength="2"
                            (input)="toUpperCase($event)">
                    </mat-form-field>
                    <mat-error *ngIf="SubIsa07.invalid && SubIsa07.touched">
                        <span *ngIf="SubIsa07.errors?.invalidIsaQualifier">
                            {{SubIsa07.errors?.validValues}}
                        </span>
                    </mat-error>
                </div>
            </div>

            <div class="d-flex justify-content-between">
                <div class="label-div">
                    <mat-label>GS02 - Application Sender's Code:</mat-label>
                </div>
                <div>
                    <mat-form-field>
                        <input matInput placeholder="GS02" formControlName="SubGs02">
                    </mat-form-field>
                    <mat-error *ngIf="SubGs02.invalid && SubGs02.touched">
                        <span *ngIf="SubGs02.errors?.required">
                            Inmediata = 660610220
                        </span>
                    </mat-error>
                </div>
            </div>

            <div class="d-flex justify-content-between">
                <div class="label-div">
                    <mat-label>GS03 - Application Receiver's Code:</mat-label>
                </div>
                <div>
                    <mat-form-field>
                        <input matInput placeholder="GS03" formControlName="SubGs03">
                    </mat-form-field>
                    <mat-error *ngIf="SubGs03.invalid && SubGs03.touched">
                        <span *ngIf="SubGs03.errors?.required">
                            Inmediata = 660610220
                        </span>
                    </mat-error>
                </div>
            </div>

            <div class="d-flex justify-content-between">
                <div class="label-div">
                    <mat-label>{{engLang ? 'Office contact (first & last names):' : 'Contacto en la oficina (nombre
                        y
                        apellidos):'}}</mat-label>
                </div>
                <div>
                    <mat-form-field>
                        <input matInput placeholder="Contact" formControlName="SubContact"
                            (input)="toUpperCase($event)">
                    </mat-form-field>
                </div>
            </div>

            <div class="d-flex justify-content-between">
                <div class="label-div">
                    <mat-label>{{engLang ? 'Office contact tel:' : 'Teléfono en la oficina:'}}</mat-label>
                </div>
                <div>
                    <mat-form-field>
                        <input matInput placeholder="Tel" formControlName="SubContTel" maxlength="10">
                    </mat-form-field>
                    <mat-error *ngIf="SubContTel.invalid && SubContTel.touched">
                        <span *ngIf="SubContTel.errors?.invalidPhone">
                            {{engLang ? '10 digits, ex: 8097938833': '10 dígitos, ej: 8097938833'}}
                        </span>
                        <span *ngIf="SubContTel.errors?.required">
                            {{engLang ? 'Required': 'Requerido'}}
                        </span>
                    </mat-error>
                </div>
            </div>

            <div class="d-flex justify-content-between">
                <div class="label-div">
                    <mat-label>{{engLang ? 'Office contact fax:' : 'Fax en la oficina:'}}</mat-label>
                </div>
                <div>
                    <mat-form-field>
                        <input matInput placeholder="Fax" formControlName="SubContFax">
                    </mat-form-field>
                    <mat-error *ngIf="SubContFax.invalid && SubContFax.touched">
                        <span *ngIf="SubContFax.errors?.invalidPhone">
                            {{engLang ? '10 digits, ex: 8097938833': '10 dígitos, ej: 8097938833'}}
                        </span>
                    </mat-error>
                </div>
            </div>

            <div class="d-flex justify-content-between">
                <div class="label-div">
                    <mat-label>{{engLang ? 'Office contact email:' : 'Email en la oficina:'}}</mat-label>
                </div>
                <div>
                    <mat-form-field>
                        <input matInput placeholder="Email" formControlName="SubContEmail">
                    </mat-form-field>
                </div>
            </div>

            <div class="d-flex justify-content-between">
                <div class="label-div">
                    <mat-label>{{engLang ? 'Acct. user name:' : 'Nombre usuario cuenta:'}}</mat-label>
                </div>
                <div>
                    <mat-form-field>
                        <input matInput placeholder="User" formControlName="SubUser">
                    </mat-form-field>
                    <mat-error *ngIf="SubUser.invalid && SubUser.touched">
                        <span *ngIf="SubUser.errors?.required">
                            {{engLang ? 'NONE if not needed': 'NINGUNO si no es requerido'}}
                        </span>
                    </mat-error>
                </div>
            </div>

            <div class="d-flex justify-content-between">
                <div class="label-div">
                    <mat-label>{{engLang ? 'Password:' : 'Contraseña:'}}</mat-label>
                </div>
                <div>
                    <mat-form-field>
                        <input matInput placeholder="Password" formControlName="SubPw">
                    </mat-form-field>
                    <mat-error *ngIf="SubPw.invalid && SubPw.touched">
                        <span *ngIf="SubPw.errors?.required">
                            {{engLang ? 'NONE if not needed': 'NINGUNO si no es requerido'}}
                        </span>
                    </mat-error>
                </div>
            </div>

            <!-- {{ form.valid }}
            {{ formEdited }}
            <br>
            {{ form.value | json2 }}
            <br>
            {{ formCopy | json2 }}
            <br>
            {{ provID }}
            {{ insID }}
            <br>
            {{ subs | json2 }} -->

        </mat-dialog-content>
    </div>

    <div class="d-flex justify-content-between">
        <div class="d-flex justify-content-start">
            <div *ngIf="+form.value.SubID" class="align-content-center">
                &nbsp;&nbsp;&nbsp;
                <i *ngIf="subIndx > -1" class="fa fa-trash" (click)="onClick_delete()"
                    matTooltip="{{ engLang ? 'Delete account' : 'Eliminar cuenta' }}" matTooltipPosition="right"></i>
            </div>
            <div class="align-content-center">
                &nbsp;&nbsp;&nbsp;
            </div>
            <div class="align-content-center">
                &nbsp;&nbsp;&nbsp;
                <span class="material-symbols-outlined new pt-1" (click)="onClick_new()"
                    matTooltip="{{ engLang ? 'Add new account' : 'Añadir cuenta nueva' }}" matTooltipPosition="right">
                    draft
                </span>
            </div>
        </div>
        <div>
            <mat-dialog-actions align="end">
                <button mat-raised-button [mat-dialog-close]="true">Cancel</button>
                <button mat-raised-button color="primary" (click)="onClick_save()"
                    [disabled]="!form.valid || !formEdited">{{engLang ?
                    'Save' : 'Grabar'}}</button>
            </mat-dialog-actions>
        </div>

        <div *ngIf="debugData">
            <button mat-raised-button color="accent" (click)="onClick_debug()">Debug</button>
            <app-data-view-debug [label]="debugLabel" [data]="debugData" [comp]="debugComp"></app-data-view-debug>
        </div>
    </div>
</div>