import { Component, ViewChild, OnInit, AfterViewInit, TemplateRef, ViewContainerRef, model } from '@angular/core';
import { ActivatedRoute } from '@angular/router'

import { LoginComponent } from './login/login.component';
import { PaymentFormComponent } from './shared/payment-form.component';
import { ICustomer } from './shared/interfaces/customer'
import { IServiceItemPrice } from './shared/interfaces/service-item-price';
import { PaymentService } from './shared/payment.service';
import { DataMessageService } from "./shared/data-message.service";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { WebsocketService } from './shared/websocket.service';
import { RecordService } from './record/record.service';
import { HttpErr } from './ck-register/ck-register835-detail/ck-register835-detail.service';
import { style } from '@angular/animations';

@Component({
    selector: 'mb-app',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.css'],
    standalone: false
})
export class AppComponent implements OnInit, AfterViewInit {
    @ViewChild(LoginComponent, { static: true }) login: any;
    @ViewChild(PaymentFormComponent, { static: true }) payment: any;
    @ViewChild('paymentDlgShowModal', { read: TemplateRef, static: true }) paymentDlgShowModal: TemplateRef<any>;

    app_userValidLogin: boolean = false;   // true when user is logged in
    // string data shared between modules through injectable DataMessageService
    app_sn: string;
    userLastNmStr: string;
    userFirstNmStr: string;
    userIdStr: string;

    ccDisabled: boolean = false;
    achDisabled: boolean = false;

    sioSubscr: Subscription;

    dxCategories: any = [{
        pKey: undefined,
        categName: undefined
    }];

    dxCodes: any = [{
        pKey: undefined,
        categPKey: undefined,
        code: undefined,
        desc: undefined,
        selected: undefined,
        order: undefined
    }];

    localICD10: any = [{
        dxCode: undefined,
        dxDescr: undefined
    }];

    stylesCfg = {   // Universal styles for consistency between components
        provNpi_DDn_backgEvenColr: '#c9def0',
        provNpi_DDn_backgOddColr: '#FFFFFF',
        insPayerId_DDn_backgEvenColr: '#d3ebd9',
        insPayerId_DDn_backgOddColr: '#FFFFFF',
        refNpi_DDn_backgEvenColr: '#d8c9f0',
        refNpi_DDn_backgOddColr: '#FFFFFF',
        facId_DDn_backgEvenColr: '#BDB76B',
        facId_DDn_backgOddColr: '#FFFFFF',
        prodNpi_DDn_backgEvenColr: '#4682B4',
        prodNpi_DDn_backgOddColr: '#FFFFFF',
        payMode_DDn_backgEvenColr: '#FFD700',
        payMode_DDn_backgOddColr: '#FFFFFF',
    }
    stylesCfgJson: string = JSON.stringify(this.stylesCfg); // Route parameter cannot be an object

    constructor(
        private _paymentService: PaymentService,
        private _modalService: NgbModal,
        private _dataMessageService: DataMessageService,
        private _route: ActivatedRoute,
        private _vref: ViewContainerRef,
        private _recordService: RecordService,
        private _websocketService: WebsocketService
    ) { }

    // testJSON: {} = {"intent":"sale","orderID":"EC-23B23845R5799982U","payerID":"5DSZDNE47QKBC","paymentID":"PAYID-LRJCYWY1P01314072027692K","paymentToken":"EC-23B23845R5799982U","currency":"USD","billingAddr":{"name":"Hector Rodriguez David","addrLine1":"PO Box 1437","addrLine2":"","city":"AIBONITO","state":"PR","zipCode":"00705-    ","country":"USA","email":"irgranada76@gmail.com","phoneNumber":"7877357818"},"lineItems":[{"type":"product","name":"MB Servicio remoto 1 hr o menos","quantity":"1","price":249.99,"tangible":"N","productId":"8","recurrence":"1 Month","duration":"Forever"}]}

    engLang: boolean = false;
    cust: ICustomer = {
        pKey: undefined,
        sn: undefined,
        email: undefined,
        userNm: undefined,
        password: undefined,
        companyNm: undefined,
        lastNm: undefined,
        firstNm: undefined,
        cellPhone: undefined,
        otherPhone: undefined,
        title: undefined,
        typedSignature: undefined,
        signedDate: undefined
    }
    serviceItemPrices: [IServiceItemPrice];
    serviceBackgColrOdd: string = '#879ab7';
    serviceBackgColrEven: string = '#c3ccdb';
    total: number = 0;
    totalItems: number = 0;
    type: string = 'cc';
    moduleNm: string = undefined;   // Predefined option to buy from outside of website defined in query string as ...?module=xxx

    ngOnInit(): void {
        this._dataMessageService.currentUserLastNmStr.subscribe(userLastNmStr => this.userLastNmChange(userLastNmStr)); //  Subscription
        this._dataMessageService.currentUserFirstNmStr.subscribe(userFirstNmStr => this.userFirstNmChange(userFirstNmStr)); //  Subscription
        this._dataMessageService.currentUserIdStr.subscribe(userIdStr => this.userIdChange(userIdStr)); //  Subscription
        this._recordService.stylesCfg = this.stylesCfg;

        this._route.queryParamMap.subscribe(params => {
            console.log('%c' + 'app.component query str params', 'color: black; background: orange; font-size: 15px');
            console.dir(params);
            this.moduleNm = params['params'].module;
            console.log('%c' + 'this.moduleNm = ' + this.moduleNm, 'color: black; background: ornage; font-size: 10px');
            console.log('%c' + '^^^^^^^^^^^^^^^^^app_params^^^^^^^^^^^^^^^^^^', 'color: black; background: orange; font-size: 12px');
        });
    }

    ngAfterViewInit(): void {
        this._vref.createEmbeddedView(this.paymentDlgShowModal);
        this._vref.remove();  // Removes the initially appended template

        // this.cust = this.login.cust;

        this._paymentService.getServiceItemPrices(undefined, this.login.cust.sn)
            .subscribe({
                next: (serviceItemPrices: any) => this.backFromGetServiceItemPrices(serviceItemPrices),
                error: (err: HttpErr) => console.log('%c' + 'ERR loading (backFromGetServiceItemPrices)=' + err, 'color: red; background: yellow; font-size: 20px')
            });  // Possible timing glitch here
    }

    userLastNmChange(userLastNmStr: string) {   // Runs everytime userLastNmStr changes in LoginComponnet because it was subscribed in ngOnInit()
        this.userLastNmStr = userLastNmStr;
    }

    userFirstNmChange(userFirstNmStr: string) {   // Runs everytime userFirstNmStr changes in LoginComponnet because it was subscribed in ngOnInit()
        this.userFirstNmStr = userFirstNmStr;
    }

    userIdChange(userIdStr: string) {   // Runs everytime userIdStr changes in LoginComponnet because it was subscribed in ngOnInit()
        this.userIdStr = userIdStr;
    }

    toggleLanguage(): void {
        this.engLang = !this.engLang;
        if (this.engLang) {
            this._dataMessageService.changeEngLangStr('true');
            this._recordService.engLang = true;
        } else {
            this._dataMessageService.changeEngLangStr('false');
            this._recordService.engLang = false;
        }
    }

    buySomething(event: any): void {
        if (!this.serviceItemPrices) {  // In case this.serviceItemPrices didn't load
            this._paymentService.getServiceItemPrices(undefined, this.login.cust.sn)
                .subscribe({
                    next: (serviceItemPrices: any) => this.backFromGetServiceItemPrices(serviceItemPrices),
                    error: (err: HttpErr) => this.backFromGetServiceItemPricesError(err)
                }
                );
        }

        if (event || this.moduleNm) {
            let moduleNm: string = (event ? event.target.dataset.modulenm : this.moduleNm); // Prioritize event
            if (moduleNm) { // undefined when openning the dropdown menu

                if (moduleNm != 'checkout') {
                    this.serviceItemPrices.filter(item => item.module == moduleNm)[0].selected = true;
                }
                this.getTotalItems();
                var ckoutItem = this.serviceItemPrices.filter(item => item.mnuEng.match(/\(CHECKOUT\)/g))[0];
                this.totalItems ? ckoutItem.mnuShow = true : ckoutItem.mnuShow = false;

                this.open(this.paymentDlgShowModal, { backdrop: 'static', size: 'md', scrollable: false });
            }
        }
    }

    backFromGetServiceItemPrices(serviceItemPrices: any) {
        this.serviceItemPrices = serviceItemPrices;
        this.total = 0;
        this.totalItems = 0;
        this.serviceItemPrices.forEach(item => {
            item.selected = false;
            item.mnuShow = true;
        });
        this.serviceItemPrices.filter(item => item.mnuEng.match(/\(CHECKOUT\)/g))[0].mnuShow = false;   // Initial setting is (CHECKOUT) not visible until items are selected

        this.buySomething(undefined);
    }

    backFromGetServiceItemPricesError(error: any) {
        var msg: string = (this.engLang ? 'Try one at a time:' : 'Trate uno a la vez:') + '<br />';
        msg += (this.engLang ? '1 - Check Internet service' : '1 - Verifique Internet') + '<br />';
        msg += (this.engLang ? '2 - Switch browser' : '2 - Cambie el navegador') + '<br />';
        msg += (this.engLang ? '3 - Try again' : '3 - Trate otra vez') + '<br /><br />';
        msg += (error._body ? '_body = ' + error.ok + '<br />' : '')
        msg += (error.ok ? 'ok = ' + error.ok + '<br />' : '')
        msg += (error.status ? 'status = ' + error.status + '<br />' : '')
        msg += (error.statusText ? 'statusText = ' + error.statusText + '<br />' : '')
        msg += (error.type ? 'type = ' + error.type + '<br />' : '')

        // TODO: unfinished!

        // this._modalServiceX.message(msg,
        //     this.engLang ? 'Error reading Service(s).' : 'Error leyendo Servicio(s).',
        //     'app/assets/images/dataReceiveError.png',
        //     'Error', '40', '40', this.engLang);
    }

    setMenuServiceItemsStyle(item: any, idx: number, totalItems: number): any {
        let styles = {
            'visible': (item.mnuShow || totalItems) ? true : false,
            'hidden': (!item.mnuShow) ? true : false,
            'color': (item.module == 'checkout') ? 'black' : 'black',
            'background-color': (idx % 2) == 1 ? ((item.module == 'checkout') ? '#9fff9f' : this.serviceBackgColrOdd) : ((item.module == 'checkout') ? '#9fff9f' : this.serviceBackgColrEven),
            'text-align': 'center',
            'cursor': 'pointer'
        }
        return styles;
    }

    open(content, opts) {

        this._modalService.open(content, opts).result.then((result) => {
            console.log('result=' + result);
            if (result == 'oK_paymentDlgShowModal') {
                return;
            }
        }, (reason) => {
            console.log(reason);
            this.getTotalItems();
        }
        )
    }

    onCcAchClick(eventTarget: any): void {
        if (!this.ccDisabled && !this.achDisabled) {
            if ((eventTarget.id == 'credCard' && this.type != 'cc') || (eventTarget.id == 'achTxfer' && this.type != 'ach')) {
                if (this.type == 'cc') {
                    this.type = 'ach';
                } else {
                    this.type = 'cc';
                }
            }
        }
    }

    getTotalItems(): void {
        this.total = 0;
        this.totalItems = 0;
        this.serviceItemPrices.forEach(item => {
            if (item.selected) {
                this.total += +item.charge * parseInt(item.qty);
                this.totalItems += 1;
            }
        });
    }

}
