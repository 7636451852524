import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-data-view-debug',
  imports: [CommonModule],
  templateUrl: './data-view-debug.component.html',
  styleUrls: ['./data-view-debug.component.css']
})
export class DataViewDebugComponent {
  @Input() label: string;
  @Input() data: any;
  @Input() comp: boolean = false;

  obj1: any;
  obj2: any;
  diff: any = {};

  ngOnInit() {
    if (this.comp) {
      for (const key in this.data) {
        for (const k in this.data[key]) {
          if (typeof this.data[key][k] === 'object') {
            if (!this.obj1) {
              this.obj1 = this.data[key][k];
              continue;
            } else {
              this.obj2 = this.data[key][k];
              this.compareObjects(this.obj1, this.obj2);
              continue
            }
          }
        }
      }
    }
  }

  compareObjects(obj1, obj2) {  // Function to compare two objects and find differences
    for (const key in obj1) {
      if (obj1[key] !== obj2[key] || obj2[key] == undefined) {
        this.diff[key] = { obj1: obj1[key], obj2: obj2[key] };
      }
    }
    for (const key in obj2) {
      if (obj1[key] == undefined) {
        this.diff[key] = { obj1: obj1[key], obj2: obj2[key] };
      }
    }
  }

}
